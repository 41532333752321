import styled from "styled-components";
import { CardLayout } from "../../styles/index";

export const OnlineActivitiesWrapper = styled(CardLayout)`
  ${({ isLoading }) => isLoading && "width: 100%;"}
  flex-direction: column;
`;

export const OnlineActivitiesHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding-bottom: 26px;
  align-items: center;
`;

export const OnlineActivitiesTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const OnlineActivitiesDate = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${props => props.theme.colors.gray};
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  background-color: rgba(24, 89, 169, 0.08);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const InfoBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 18px;
`;

export const OnlineActivitiesSubtitle = styled.div`
  color: ${props => props.theme.colors.grayDark};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;
`;

export const OnlineActivitiesTime = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;
export const IndicatorTitle = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.grayDark};
`;

export const Indicator = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 24px;
`;

export const IndicatorBlock = styled.div`
  flex-grow: 1;
  height: 6px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.lochmara : theme.colors.grayLight};
  &:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
  &:not(:last-of-type) {
    margin-right: 4px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.royalBlue};
  font-size: 14px;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grayLight};
  margin-bottom: 24px;
`;
