export const DatePickerDayType = {
  Previous: 0,
  Current: 1,
  Next: 2,
};

export const DatePickerView = {
  Months: "Months",
  Days: "Days",
};
export const jsLastDayOfWeek = 6;
export const numberOfDaysInGrid = 42;
export const jsToRealDateDisplacement = 1;
export const localFirstDayOfWeek = 1;
export const numberOfMonthsInYear = 12;
export const lastHour = 23;
export const lastMinute = 59;
export const lastSecond = 59;
export const lastMillisecond = 999;
export const monthsAbbreviated = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const monthsFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
export const datePickerViewsOrder = [
  DatePickerView.Months,
  DatePickerView.Days,
];
export const calendarHeightPx = 300;

export const DATE_PICKER_STRINGS = {
  goToToday: "Go to today",
  inputPlaceholder: "dd/mm/yyyy",
  clear: "Clear",
};
