import React from "react";

export const Calendar = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50098 5C1.50098 4.17157 2.17255 3.5 3.00098 3.5H15.001C15.8294 3.5 16.501 4.17157 16.501 5V15C16.501 15.8284 15.8294 16.5 15.001 16.5H3.00098C2.17255 16.5 1.50098 15.8284 1.50098 15V5Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.50098 7.5H16.501"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.50098 5V1.5"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.501 5V1.5"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
