import React, { Component } from "react";

export default WrappedComponent =>
  class extends Component {
    constructor() {
      super();
      this.state = {
        isClickedOutside: false,
        isListening: false,
        isOpen: false,
      };
      this.node = null;
    }

    componentWillUnmount() {
      document.removeEventListener("click", this.handleClickOutside, true);
      this.state.isListening = false;
    }

    setNode = node => {
      this.node = node;
    };

    updateIsOpenValue = bool => {
      if (bool && !this.isListening) {
        document.addEventListener("click", this.handleClickOutside, false);
      } else if (!bool) {
        document.removeEventListener("click", this.handleClickOutside, false);
      }
      this.setState({ isClickedOutside: false, isListening: bool });
    };

    checkIsClickedOutside = event => {
      const domNode = this.node;
      return (
        !domNode ||
        !event.target ||
        (domNode !== event.target && !domNode.contains(event.target))
      );
    };

    handleClickOutside = event => {
      this.setState({ isClickedOutside: this.checkIsClickedOutside(event) });
    };

    render() {
      const { isClickedOutside } = this.state;
      return (
        <WrappedComponent
          setupRef={this.setNode}
          updateIsOpenValue={this.updateIsOpenValue}
          isClickedOutside={isClickedOutside}
          {...this.props}
        />
      );
    }
  };
