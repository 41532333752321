import React from "react";

export const PNGIcon = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="9" y="2" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M9 29h30v7c0 1.1046-.8954 2-2 2H11c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path d="M30 29V2h7c1.1046 0 2 .89543 2 2v25h-9Z" fill="#DDD" />
    <rect
      x=".832031"
      y="11"
      width="27.3377"
      height="19.5"
      rx="2"
      fill="#089BD7"
    />
    <path
      d="M4.83203 17.18h2.47c.54 0 .98333.06 1.33.18.35333.12.63.28.83.48.2.2.34.4333.42.7.08.26.11997.5333.11997.82 0 .3067-.03997.6-.11997.88-.08.28-.22333.5267-.43.74-.2.2067-.47333.3733-.82.5-.34667.12-.78333.18-1.31.18h-1.3v2.66h-1.19v-7.14Zm2.46 3.47c.30667 0 .55667-.03.75-.09.2-.06.35667-.1433.47-.25.11334-.1133.19-.2467.23-.4.04667-.1533.07-.3267.07-.52 0-.2-.02333-.3733-.07-.52-.04667-.1533-.13-.28-.25-.38-.11333-.1-.27-.1767-.47-.23-.19333-.0533-.44-.08-.74-.08h-1.26v2.47h1.27ZM11.1602 17.18h1.12l3.28 5.16v-5.16h1.18v7.14h-1.12l-3.29-5.14v5.14h-1.17v-7.14ZM24.1698 20.53v2.94c-.3667.34-.8.5967-1.3.77s-1.0034.26-1.51.26c-.5534 0-1.04-.0867-1.46-.26-.4134-.18-.76-.43-1.04-.75-.28-.3267-.49-.72-.63-1.18-.14-.46-.21-.9767-.21-1.55 0-.58.0766-1.1.23-1.56.1533-.4667.37-.8633.65-1.19s.62-.5767 1.02-.75.8466-.26 1.34-.26c.4333 0 .81.0567 1.13.17.3266.1067.6.2567.82.45.2266.1933.4066.42.54.68.14.2533.24.5267.3.82l-1.19.17c-.0534-.2-.12-.3767-.2-.53-.0734-.16-.1734-.2933-.3-.4-.12-.1067-.27-.1867-.45-.24-.18-.06-.3967-.09-.65-.09-.3334 0-.6267.0667-.88.2-.2467.1267-.4534.31-.62.55-.1667.24-.29.5267-.37.86-.08.3333-.12.7033-.12 1.11 0 .42.04.7967.12 1.13.08.3333.2066.62.38.86.1733.2333.39.4133.65.54.2666.1267.5866.19.96.19.3333 0 .6333-.0333.9-.1.2666-.0733.5233-.1967.77-.37v-1.45h-1.58v-1.02h2.7Z"
      fill="#fff"
    />
  </svg>
);
