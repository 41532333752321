import { useEffect, useState } from "react";
import moment from "moment";
import {
  DATE_FORMAT_TYPE,
  FULL_DATE_FORMAT,
  NUMBER_OF_SECONDS_IN_HOUR,
  NUMBER_OF_SECONDS_IN_MINUTE,
  OUTPUT_GET_PERIOD_DATE_FORMAT,
  PARSE_DATE_FORMAT,
} from "../constants";

export const noop = () => null;

const calculateCount = (bp, arr) =>
  arr.find((_, index) => {
    const bpValue = bp[Object.keys(bp)[index]];
    return window.matchMedia(`(max-width: ${bpValue})`).matches;
  }) || arr[arr.length - 1];

export const useMediaForCardsCount = (bp, arr) => {
  const [count, setCardCount] = useState(calculateCount(bp, arr));

  useEffect(() => {
    const handleWindowResize = () => {
      const key = calculateCount(bp, arr);
      setCardCount(key);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { count };
};

export const getRandomElementOfArray = array =>
  array[Math.floor(Math.random() * array.length)];

export const convertReportingTo = ({
  managerFullName,
  managerDesignation,
  managerEmailId,
  managerNumber,
  managerPos,
  managerUserId,
}) => ({
  fullName: managerFullName,
  designation: managerDesignation,
  email: managerEmailId,
  number: managerNumber,
  position: managerPos,
  userId: managerUserId,
});

const getTimeValue = (time, singularTitle) => {
  if (!time) return "";

  return time === 1 ? `1 ${singularTitle}` : `${time} ${singularTitle}s`;
};

export const getMonthsReadableValue = months => getTimeValue(months, "month");
export const getYearsReadableValue = years => getTimeValue(years, "year");

export const getYearsMonthsDurationText = (durationYears, durationMonths) => {
  const years = parseInt(durationYears);
  const months = parseInt(durationMonths);
  const result = [];

  if (!isNaN(years) && years !== 0) {
    result.push(getYearsReadableValue(years));
  }

  if (!isNaN(months) && months !== 0) {
    result.push(getMonthsReadableValue(months));
  }

  return result.length === 0 ? "-" : result.join(" ");
};

export const getTimeFromNow = date => {
  const now = moment();
  const initialMoment = moment(date);
  const years = now.diff(initialMoment, "years");
  const months = now.diff(initialMoment, "months") - years * 12;

  return getYearsMonthsDurationText(years, months);
};

export const pluralize = (count, text, suffix = "s") =>
  `${count} ${text}${count === 1 ? "" : suffix}`;

export const getInitials = (name, surname) => {
  return name.slice(0, 1) + surname.slice(0, 1);
};

export const getDateRangeString = (startDate, endDate) => {
  const start = moment(startDate).format(DATE_FORMAT_TYPE);
  let end = moment(endDate);
  end = end.isValid() ? end.format(DATE_FORMAT_TYPE) : "Present";

  return `${start} - ${end}`;
};

export const isIOS = () => {
  return (
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      navigator.platform === "MacIntel") &&
    !window.MSStream
  );
};

export const getFormattedSpendTime = seconds => {
  const hours = Math.floor(seconds / NUMBER_OF_SECONDS_IN_HOUR);
  const minutes = Math.floor(
    (seconds - hours * NUMBER_OF_SECONDS_IN_HOUR) / NUMBER_OF_SECONDS_IN_MINUTE
  );

  return `${hours}h ${minutes}m`;
};

export const getPeriod = (fromDate, toDate) =>
  `${moment(fromDate, PARSE_DATE_FORMAT).format(
    OUTPUT_GET_PERIOD_DATE_FORMAT
  )} - ${moment(toDate, PARSE_DATE_FORMAT).format(
    OUTPUT_GET_PERIOD_DATE_FORMAT
  )}`;

export const getFullDate = date => `${moment(date).format(FULL_DATE_FORMAT)}`;

export const salaryFormatter = amount => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AED",
  });
  return formatter.format(amount).replace(/[a-z]/gi, "").trim();
};
