import { useContext, useEffect, useState } from "react";
import { PROFILE_ERROR_TYPES } from "../constants";
import { ProfileContext } from "../context/ProfileProvider";

export const useUserPresenceStatus = emailList => {
  const [isLoading, setLoading] = useState(true);
  const [userPresenceStatus, setUserPresenceStatus] = useState(null);
  const { getUserPresenceStatus, onError } = useContext(ProfileContext);
  const normalizeData = response => {
    if (!response) return null;
    return response.reduce((acc, { email, availability }) => {
      return { ...acc, [email]: availability };
    }, {});
  };
  useEffect(() => {
    if (!emailList?.length) return;
    setLoading(true);
    getUserPresenceStatus(emailList)
      .then(res => setUserPresenceStatus(normalizeData(res)))
      .catch(error => {
        setUserPresenceStatus(null);
        onError(PROFILE_ERROR_TYPES.UserPresenceError, error);
      })
      .finally(() => setLoading(false));
  }, [setUserPresenceStatus, setLoading, emailList, onError]);
  return [userPresenceStatus, isLoading];
};
