import React from "react";

const EmployeeInfo = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.125 4.375H2.875C2.18464 4.375 1.625 4.93464 1.625 5.625V16.875C1.625 17.5654 2.18464 18.125 2.875 18.125H19.125C19.8154 18.125 20.375 17.5654 20.375 16.875V5.625C20.375 4.93464 19.8154 4.375 19.125 4.375H12.875"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.875 3.75C12.875 2.71447 12.0355 1.875 11 1.875C9.96447 1.875 9.125 2.71447 9.125 3.75V6.25C9.125 6.41576 9.19085 6.57473 9.30806 6.69194C9.42527 6.80915 9.58424 6.875 9.75 6.875H12.25C12.5952 6.875 12.875 6.59518 12.875 6.25V3.75Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 13.75C9.08312 13.75 10.0625 12.7706 10.0625 11.5625C10.0625 10.3544 9.08312 9.375 7.875 9.375C6.66688 9.375 5.6875 10.3544 5.6875 11.5625C5.6875 12.7706 6.66688 13.75 7.875 13.75Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.125 18.125C4.125 16.0539 5.80393 14.375 7.875 14.375C9.94607 14.375 11.625 16.0539 11.625 18.125H4.125Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 10.625H16.625"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 13.125H17.875"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmployeeInfo;
