import React from "react";

const MSTeams = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m.5 13.37 8.521 1.494V1.2L.5 2.695V13.37Zm9.135-.77a1.856 1.856 0 0 0 3.02-1.444V6.695h-3.02V12.6Zm2.64-8.073a1.474 1.474 0 0 1-2.64.902V3.624a1.474 1.474 0 0 1 2.64.903ZM16.47 7.65h-3.204v3.94a1.831 1.831 0 0 0 2.704.104c.344-.348.535-.82.53-1.31l-.03-2.734Zm-.377-2.145a1.458 1.458 0 1 1-2.916 0 1.458 1.458 0 0 1 2.916 0ZM5.17 6.504h1.271v-.763h-3.31v.763h1.272v3.714h.767V6.504Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default MSTeams;
