export const MY_PROFILE_PAGE_SECTION_PROFILE_CARD = "my-profile-page-section profile-card";
export const EMPLOYEEPROFILE_PAGE_PROFILEDOC = "employeeProfile-page-profiledoc";
export const MY_PROFILE_PAGE_SECTION_REPORTINGTO = "my-profile-page-section reportingTo";
export const MY_PROFILE_PAGE_TEAM_BALACE = "my-profile-page-team-balace";
export const REPORTINGTO_ORG_CHART_LINK = "reportingTo-org-chart-link";
export const MY_PROFILE_PAGE_SECTION_PERFORMANCERATINGHISTORY = "my-profile-page-section performanceRatingHistory";
export const MY_PROFILE_PAGE_SECTION_ACTIVITYREPORT = "my-profile-page-section activityReport";
export const MY_PROFILE_PAGE_SECTION = "my-profile-page-section";
export const MY_PROFILE_PAGE_SECTION_PROFILECOMPLETENESS = "my-profile-page-section profileCompleteness";
export const MY_PROFILE_PAGE_SECTION_POSITIONHISTORY = "my-profile-page-section positionHistory";
export const MY_PROFILE_PAGE_SECTION_GRADEHISTORY = "my-profile-page-section gradeHistory";
export const MY_PROFILE_PAGE_SECTION_JOBHISTORY = "my-profile-page-section jobHistory";
export const MY_PROFILE_PAGE_SECTION_EDUCATIONANDQUALIFICATION = "my-profile-page-section educationAndQualification";
export const MY_PROFILE_PAGE_SECTION_TRAININGHISTORY = "my-profile-page-section trainingHistory";
