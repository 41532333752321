import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import ArrowIcon from "../icons/Arrow";
import DotsIcon from "../icons/Dots";

import { noop } from "../../utils/utils";

import ProfileCompletenessTooltip from "./ProfileCompletenessTooltip";

import {
  ProfileCompletenessSectionList,
  ProfileCompletenessSectionListItem,
  ProfileCompletenessSectionListSubsection,
  ProfileCompletenessSectionListSubsectionItem,
} from "../styles/profileCompleteness";

import { COMPLETENESS_PERCENTAGE_STATUS } from "./index";

const SELECTED_DEFAULT = -1;

const COMPLETENESS_PERCENTAGE_COLOR = {
  missingData: "#E32726",
  partialData: "#FAA22C",
};

const COMPLETENESS_PERCENTAGE_TOOLTIP_TEXT = {
  missingData: "The section is empty",
  partialData: "Some info was lost or entered incorrectly",
};

const ProfileCompletenessAccordion = ({
  sections,
  isShowAllSection,
  onGoToEServiceClick,
  isMobile,
}) => {
  const [selected, setSelected] = useState(SELECTED_DEFAULT);
  const selectHandler = select => {
    if (selected === select) {
      setSelected(SELECTED_DEFAULT);
      return null;
    }
    setSelected(select);
  };

  const sectionClickHandler = (
    event,
    section,
    isDisabled,
    sectionIdx = SELECTED_DEFAULT
  ) => {
    event.preventDefault();
    if (isDisabled) {
      return false;
    }
    if (section?.subsections?.length) {
      selectHandler(sectionIdx);
      return false;
    }
    if (onGoToEServiceClick) {
      setSelected(SELECTED_DEFAULT);
      onGoToEServiceClick(section);
    }
  };

  const statusColorDefinition = status => {
    switch (status) {
      case COMPLETENESS_PERCENTAGE_STATUS.missingData:
        return COMPLETENESS_PERCENTAGE_COLOR.missingData;
      case COMPLETENESS_PERCENTAGE_STATUS.partialData:
        return COMPLETENESS_PERCENTAGE_COLOR.partialData;
      default:
        return "";
    }
  };

  const drawTooltipText = status => {
    switch (status) {
      case COMPLETENESS_PERCENTAGE_STATUS.missingData:
        return COMPLETENESS_PERCENTAGE_TOOLTIP_TEXT.missingData;
      case COMPLETENESS_PERCENTAGE_STATUS.partialData:
        return COMPLETENESS_PERCENTAGE_TOOLTIP_TEXT.partialData;
      default:
        return "";
    }
  };

  useEffect(() => {
    setSelected(SELECTED_DEFAULT);
  }, [isShowAllSection]);

  return (
    <ProfileCompletenessSectionList>
      <ul>
        {sections.map((section, sectionIdx) => {
          const isActive = selected === sectionIdx;
          const isDisabled =
            !section?.requestUrl && !section?.subsections?.length;

          if (
            section?.completenessText ===
            COMPLETENESS_PERCENTAGE_STATUS.сompleted
          ) {
            return null;
          }

          return (
            <ProfileCompletenessSectionListItem
              key={`${section?.name}${sectionIdx}`}
              isActive={isActive}
              blur={isDisabled}
              completenessPercentageColor={statusColorDefinition(
                section?.completenessText
              )}
            >
              <a
                className="tooltip"
                href={section.requestUrl}
                onClick={event =>
                  sectionClickHandler(event, section, isDisabled, sectionIdx)
                }
              >
                {!isMobile && (
                  <ProfileCompletenessTooltip
                    text={drawTooltipText(section.completenessText)}
                  />
                )}
                {section.sectionName}{" "}
                {Boolean(section?.subsections?.length) && (
                  <span>{ArrowIcon()}</span>
                )}
              </a>
              {Boolean(isActive && section?.subsections?.length) && (
                <ProfileCompletenessSectionListSubsection>
                  <ul>
                    {section.subsections.map((subsection, subsectionIdx) => {
                      const isDisabled = !subsection.requestUrl;
                      return (
                        <ProfileCompletenessSectionListSubsectionItem
                          key={`${subsection?.name}${subsectionIdx}`}
                          blur={isDisabled}
                        >
                          <a
                            href={subsection.requestUrl}
                            onClick={event =>
                              sectionClickHandler(
                                event,
                                subsection,
                                isDisabled,
                                sectionIdx
                              )
                            }
                          >
                            <span>{DotsIcon()}</span>
                            {subsection.sectionName}
                          </a>
                        </ProfileCompletenessSectionListSubsectionItem>
                      );
                    })}
                  </ul>
                </ProfileCompletenessSectionListSubsection>
              )}
            </ProfileCompletenessSectionListItem>
          );
        })}
      </ul>
    </ProfileCompletenessSectionList>
  );
};

ProfileCompletenessAccordion.propTypes = {
  sections: PropTypes.array,
  onGoToEServiceClick: PropTypes.func,
  isShowAllSection: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

ProfileCompletenessAccordion.defaultProps = {
  sections: [],
  onGoToEServiceClick: noop,
};

export default ProfileCompletenessAccordion;
