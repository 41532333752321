import styled, { css } from "styled-components";

export const ButtonWrapper = styled.div`
  padding-top: 24px;
`;

export const ShowMoreButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    cursor: pointer;
  }
  ${({
    theme: {
      colors: { royalBlue },
      fontWeight: { medium },
      fontFamily: { regularAdnocFontFamily },
    },
  }) =>
    css`
      color: ${royalBlue};
      font-weight: ${medium};
      font-family: ${regularAdnocFontFamily};
    `};
`;
