import React from "react";
import PropTypes from "prop-types";
import { CollapsibleList } from "./collapsibleList/CollapsibleList";
import { ListItem } from "./historyListItem/HistoryListItem";
import { HistoryListCard } from "./historyListCard/HistoryListCard";
import { TITLES } from "../constants";
import { useScrollIntoView } from "../hooks";
import { MY_PROFILE_PAGE_SECTION_TRAININGHISTORY } from "../auto-test-constants";
import { getFullDate } from "../utils/utils";

export default function TrainingHistory({ items, isLoading }) {
  const isEducationListAvailable =
    Array.isArray(items) && Boolean(items.length);
  const { nodeRef, onScroll } = useScrollIntoView();

  if (!isLoading && !isEducationListAvailable) return null;

  return (
    <HistoryListCard
      ref={nodeRef}
      isLoading={isLoading}
      title={TITLES.TRAINING_HISTORY}
      attrValue={MY_PROFILE_PAGE_SECTION_TRAININGHISTORY}
    >
      <CollapsibleList onCollapse={onScroll} items={items}>
        {(listItems, totalLength) =>
          listItems.map(
            ({ completionDate, courseTitle, deliveryMode }, index) => (
              <ListItem
                key={courseTitle}
                isLast={index === totalLength - 1}
                isActive={index === 0}
                date={getFullDate(completionDate)}
                title={courseTitle}
                details={deliveryMode}
              />
            )
          )
        }
      </CollapsibleList>
    </HistoryListCard>
  );
}

TrainingHistory.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      trainingCategory: PropTypes.string,
      courseTitle: PropTypes.string.isRequired,
      completionDate: PropTypes.string.isRequired,
      providerName: PropTypes.string,
      deliveryMode: PropTypes.string.isRequired,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
};

TrainingHistory.defaultProps = {
  items: [],
};
