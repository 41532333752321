import React from "react";
import PropTypes from "prop-types";
import InformationCard from "./InformationCard";
import moment from "moment";
import {
  PERSONAL_INFORMATION_TITLES,
  TITLES,
  DATE_FORMAT_TYPE,
} from "../constants";
import { PersonalInfo } from "./icons";
import { getYearsMonthsDurationText } from "../utils/utils";

export function PersonalInformation({ isLoading, ...props }) {
  const {
    birthDate,
    nationality,
    gender,
    maritalStatus,
    ageYears,
    ageMonths,
    fullName,
    bloodGroup,
  } = props;
  const birth = birthDate ? moment(birthDate).format(DATE_FORMAT_TYPE) : "";

  const personalInfoList = [
    {
      title: PERSONAL_INFORMATION_TITLES.fullName,
      value: fullName,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.birth,
      value: birth,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.nationality,
      value: nationality,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.gender,
      value: gender,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.maritalStatus,
      value: maritalStatus,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.age,
      value: getYearsMonthsDurationText(ageYears, ageMonths),
    },
    {
      title: PERSONAL_INFORMATION_TITLES.bloodGroup,
      value: bloodGroup,
    },
  ];

  return (
    <InformationCard
      info={personalInfoList}
      title={TITLES.PERSONAL_INFORMATION}
      icon={PersonalInfo}
      isLoading={isLoading}
    />
  );
}

PersonalInformation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default PersonalInformation;
