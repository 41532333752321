import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { TITLES } from "../../constants";
import {
  getDateRangeString,
  getYearsMonthsDurationText,
} from "../../utils/utils";
import { ListItem } from "../historyListItem/HistoryListItem";
import { CollapsibleList } from "../collapsibleList/CollapsibleList";
import { HistoryListCard } from "../historyListCard/HistoryListCard";
import { Period } from "../period/Period";
import { useScrollIntoView } from "../../hooks";
import { MY_PROFILE_PAGE_SECTION_JOBHISTORY } from "../../auto-test-constants";

export default function EmploymentCard({ employments, isLoading }) {
  const isEmploymentsListAvailable =
    Array.isArray(employments) && Boolean(employments.length);
  const { nodeRef, onScroll } = useScrollIntoView();

  if (!isLoading && !isEmploymentsListAvailable) return null;

  return (
    <HistoryListCard
      ref={nodeRef}
      isLoading={isLoading}
      title={TITLES.JOB_HISTORY}
      attrValue={MY_PROFILE_PAGE_SECTION_JOBHISTORY}
    >
      <CollapsibleList
        onCollapse={onScroll}
        items={employments}
        defaultAmountOfEntries={3}
      >
        {(items, totalLength) =>
          items.map(
            (
              {
                durationYears,
                durationMonths,
                startDate,
                endDate,
                position,
                employerName,
              },
              index
            ) => {
              const duration = getYearsMonthsDurationText(
                durationYears,
                durationMonths
              );

              return (
                <ListItem
                  key={startDate}
                  isActive={index === 0}
                  isLast={index === totalLength - 1}
                  title={position}
                  details={employerName}
                  date={
                    <>
                      {getDateRangeString(startDate, endDate)}
                      {duration !== "-" && <Period>{duration}</Period>}
                    </>
                  }
                />
              );
            }
          )
        }
      </CollapsibleList>
    </HistoryListCard>
  );
}

EmploymentCard.propTypes = {
  employments: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
};

EmploymentCard.defaultProps = {
  employments: [],
};
