import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { CollapsibleList } from "../collapsibleList/CollapsibleList";
import { ListItem } from "../historyListItem/HistoryListItem";
import { HistoryListCard } from "../historyListCard/HistoryListCard";
import { getDateRangeString } from "../../utils/utils";
import { Period } from "../period/Period";
import { TITLES } from "../../constants";
import { useScrollIntoView } from "../../hooks";
import { MY_PROFILE_PAGE_SECTION_POSITIONHISTORY } from "../../auto-test-constants";

export default function PositionHistoryCard({ items, isLoading }) {
  const isPositionListAvailable = Array.isArray(items) && Boolean(items.length);
  const { nodeRef, onScroll } = useScrollIntoView();

  if (!isLoading && !isPositionListAvailable) return null;

  return (
    <HistoryListCard
      ref={nodeRef}
      isLoading={isLoading}
      title={TITLES.POSITION_HISTORY}
      attrValue={MY_PROFILE_PAGE_SECTION_POSITIONHISTORY}
    >
      <CollapsibleList onCollapse={onScroll} items={items}>
        {(listItems, totalLength) =>
          listItems.map(
            (
              { startDate, endDate, period, position, organizationUnit },
              index
            ) => (
              <ListItem
                key={`${startDate}_${index}`}
                isActive={index === 0}
                isLast={index === totalLength - 1}
                date={
                  <>
                    {getDateRangeString(startDate, endDate)}
                    <Period>{period}</Period>
                  </>
                }
                title={position}
                details={organizationUnit}
              />
            )
          )
        }
      </CollapsibleList>
    </HistoryListCard>
  );
}

PositionHistoryCard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string,
      organizationUnit: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      period: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
};

PositionHistoryCard.defaultProps = {
  items: [],
};
