import React from "react";

const PersonalInfo = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.625 15.3747C1.62629 13.4858 2.57509 11.7236 4.15124 10.6827C5.72739 9.64172 7.72063 9.46095 9.45833 10.2013"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 8.5C9.14848 8.5 10.6875 6.96098 10.6875 5.0625C10.6875 3.16402 9.14848 1.625 7.25 1.625C5.35152 1.625 3.8125 3.16402 3.8125 5.0625C3.8125 6.96098 5.35152 8.5 7.25 8.5Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.375 20.375C18.1364 20.375 20.375 18.1364 20.375 15.375C20.375 12.6136 18.1364 10.375 15.375 10.375C12.6136 10.375 10.375 12.6136 10.375 15.375C10.375 18.1364 12.6136 20.375 15.375 20.375Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.375 17.875V15.375"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.375 12.875C15.2024 12.875 15.0625 13.0149 15.0625 13.1875C15.0625 13.3601 15.2024 13.5 15.375 13.5C15.5476 13.5 15.6875 13.3601 15.6875 13.1875C15.6877 13.1046 15.6549 13.0249 15.5962 12.9663C15.5376 12.9076 15.4579 12.8748 15.375 12.875"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PersonalInfo;
