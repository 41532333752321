import styled from "styled-components";

export const Period = styled.span`
  &:before {
    content: " (";
  }
  &:after {
    content: ")";
  }
`;
