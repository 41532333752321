import styled from "styled-components";
import { CardLayout } from "../../styles/index";
import ProfileBg from "../profileBg";
import {
  absoluteCentered,
  flexboxMixin,
  respondTo,
  multiLineEllipsis,
} from "../../utils/mixins";

export const Card = styled(CardLayout)`
  position: relative;
  padding: 96px 0 0;
  margin-top: 0;
  flex-direction: column;
  overflow: visible;
  width: 312px;
  min-height: 390px;

  ${respondTo.standart`
     max-width: 760px;
     width: 100%;
     justify-self: center;
  `}
`;

export const BgImage = styled(ProfileBg)`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 130px;

  border-radius: 8px;
  overflow: hidden;
  z-index: ${({ theme }) => theme.variables.userBgImageZIndex};
`;

export const AvatarWrapper = styled.div`
  position: absolute;
  top: -36px;
  ${absoluteCentered};

  height: 72px;
`;

export const UserInfoWrapper = styled.div`
  position: relative;

  ${flexboxMixin};
  flex-direction: column;

  padding: 51px 24px 24px;

  text-align: center;
  background-color: ${props => props.theme.colors.white};

  z-index: ${({ theme }) => theme.variables.userInfoWrapperZIndex};

  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`;

export const UserTitleWrapper = styled.div`
  padding-bottom: 16px;
`;

export const UserName = styled.div`
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 20px;
  line-height: 24px;
`;

export const EmployeeNumber = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grayDark};
  margin: 4px 0 0;
`;

export const JobTitleWrapper = styled.div`
  position: relative;
`;

export const JobTitleTooltip = styled.div`
  ${absoluteCentered};
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.13), 0 2px 20px rgba(0, 0, 0, 0.1);
  top: 50px;
  width: 100%;
  z-index: 1;
  visibility: hidden;
`;

export const JobTitleFullText = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const UserJobTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grayDark};
  cursor: ${({ visible }) => (visible ? "default" : "auto")};
  margin-bottom: 8px;
  ${multiLineEllipsis({ lineClamp: 2 })}

  &:hover {
    & + ${JobTitleTooltip} {
      padding: 8px;
      visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

      ${JobTitleFullText} {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;

export const UserCompany = styled.div`
  font-size: 14px;
  line-height: 20px;

  padding-bottom: 20px;
`;

export const ContactActions = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: ${props => props.theme.fontWeight.medium};

  margin-bottom: 20px;
`;

export const ContactActionButton = styled.div`
  cursor: pointer;
  padding: 0 10px;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 20px;

  background: ${props => props.theme.colors.grayLight};
`;

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  line-height: 10px;

  & button {
    margin-right: 16px;
  }

  & button:last-child {
    margin-right: 0;
  }
`;

export const ButtonLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: ${props => props.theme.colors.white};

  border: none;
  border-radius: 5px;
  padding: 6px 16px;
  min-height: 32px;
  outline: none;
  box-shadow: none;
  background-color: transparent;

  font-family: ${({ theme: { fontFamily } }) =>
    fontFamily.regularAdnocFontFamily};
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;

  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
`;

export const OneErpButtonLink = styled(ButtonLink)`
  background: ${props => props.theme.colors.royalBlue};
`;

export const TeamsButtonLink = styled(ButtonLink)`
  background: ${props => props.theme.colors.purpleLight};
  padding: 8px;
`;
export const ExportPdfButton = styled(ButtonLink)`
  padding: 0;
`;
