import styled from "styled-components";
import { respondTo } from "../utils/mixins";
import { PAGE_LAYOUT_GAP } from "../constants";

export const PageWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grayLightest};
  * {
    word-break: break-word;
    box-sizing: border-box;
    -webkit-tap-highlight-color: ${({ theme }) => theme.colors.grayLightest};
  }
`;

export const PageLayout = styled.div`
  display: grid;
  grid-template-columns: 312px 1fr;
  column-gap: ${PAGE_LAYOUT_GAP}px;
  row-gap: ${PAGE_LAYOUT_GAP}px;
  padding: 32px;
  max-width: 1160px;
  margin: auto;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grayLightest};

  ${respondTo.standart`
     grid-template-columns: 100%;
  `}

  ${respondTo.medium`
     padding: 16px;
  `}
`;

export const CardLayout = styled.div`
  display: flex;
  background: ${props => props.theme.colors.white};
  overflow: hidden;
  max-width: 760px;
  padding: 24px;
  margin: 0;

  border-radius: 8px;
  box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02),
    0 4px 44px rgba(45, 45, 45, 0.06);

  ${respondTo.standart`
     width: 100%;
     justify-self: center;
  `}
`;

export const CardTitle = styled.div`
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 16px;
  line-height: 24px;

  margin-bottom: 12px;
`;

export const Column = styled.div`
  grid-row: 1/12;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div:not(:last-of-type) {
    margin-bottom: ${PAGE_LAYOUT_GAP}px;
  }
`;
