import React, { useMemo } from "react";
import HTTPClient from "../../api/httpClient";

export const ProfileContext = React.createContext(null);

export const ProfileProvider = ({
  children,
  getAccessTokenCallback,
  proxyUrl,
  lightAppsUrl,
  user,
  onError,
}) => {
  const HTTP = useMemo(
    () => new HTTPClient(getAccessTokenCallback, proxyUrl, lightAppsUrl),
    [getAccessTokenCallback, proxyUrl]
  );

  return (
    <ProfileContext.Provider
      value={{
        ...HTTP,
        onError,
        user,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
