import React from "react";
import PropTypes from "prop-types";

import {
  ProfileCompletenessTooltip,
  ProfileCompletenessTooltipBox,
} from "../styles/profileCompleteness";

const Tooltip = ({ text }) => {
  if (!text) {
    return null;
  }
  return (
    <ProfileCompletenessTooltip title="tooltip">
      <ProfileCompletenessTooltipBox>{text}</ProfileCompletenessTooltipBox>
    </ProfileCompletenessTooltip>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string,
};

Tooltip.defaultProps = {
  text: "",
};

export default Tooltip;
