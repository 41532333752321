import styled, { css } from "styled-components";

export const Table = styled.table`
  margin-bottom: ${({ theme: { spacing } }) => spacing.xm};
  width: 100%;
  border-collapse: collapse;
  border: 0;
`;

export const TableHeader = styled.thead``;

export const TableBody = styled.tbody``;

const rowStyles = css`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.grayLight};
  padding: 0 ${({ theme: { spacing } }) => spacing.m};
`;

export const TableHeaderRow = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.grayLightest};
  ${rowStyles}
`;

export const TableRow = styled.tr`
  ${rowStyles}
  &.hide-bottom-border {
    border-bottom: 0;
  }
`;

const cellStyles = css`
  text-align: left;
`;

export const TableHeaderCell = styled.th`
  padding: ${({ theme: { spacing } }) => spacing.s};
  font-size: 12px;
  line-height: 16px;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
  ${cellStyles};

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;

export const TableCell = styled.td`
  font-size: 14px;
  line-height: 20px;
  padding: 18px ${({ theme: { spacing } }) => spacing.s};
  ${cellStyles};

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    font-weight: 400;
    padding-right: 24px;
  }
`;

export const RatingTableCell = styled(TableCell)`
  font-weight: ${({
    theme: {
      fontWeight: { medium },
    },
  }) => medium};
`;
