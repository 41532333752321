import styled, { css } from "styled-components";
import React from "react";

export const SkeletonPulse = styled.div`
  display: inline-block;

  background: linear-gradient(
    -90deg,
    ${({ theme }) => theme.colors.grayLight} 0%,
    ${({ theme }) => theme.colors.grayLightest} 50%,
    ${({ theme }) => theme.colors.grayLight} 100%
  );
  background-size: 400% 400%;

  animation: pulse 1.2s ease-in-out infinite;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SkeletonRound = styled(SkeletonPulse)`
  ${({ size }) =>
    css`
      width: ${size};
      height: ${size};
    `}

  border-radius: 50%;
`;

export const SkeletonRect = styled(SkeletonPulse)`
  display: block;

  width: ${({ $width }) => ($width ? `${$width}px` : "100%")};
  height: ${({ $height }) => $height || 16}px;

  margin-bottom: ${({ $margin = 8 }) => $margin}px;

  ${({ $css }) => $css && $css.marginTop && `margin-top: ${$css.marginTop};`}

  ${({ $css }) => $css && $css.marginLeft && `margin-left: ${$css.marginLeft};`}
`;
const getRandomArray = length => Array.from(Array(length).keys());

export const SkeletonTextElement = ({ rows = 1, css, width, height, margin }) =>
  getRandomArray(rows).map(index => (
    <SkeletonRect
      key={index}
      $width={width}
      $height={height}
      $margin={margin}
      $css={css}
    />
  ));
