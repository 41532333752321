import React, { useState } from "react";
import {
  LanguageCell,
  LanguageHeaderCell,
  LanguageTableWrapper,
} from "./LanguageProficiencyLevelsStyles";
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "../styles/table";
import { FIELDS_TO_DISPLAY, LABELS, LANGUAGE_FIELD } from "./constants";
import { ShowMoreButton } from "../styles/performanceHistoryCard";

export function LanguageProficiencyTableView({
  languageProficiencyItems = [],
  defaultAmountOfEntries,
  onScroll,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleShowMoreClick = () => {
    setIsCollapsed(prev => !prev);
    if (!isCollapsed) {
      onScroll();
    }
  };

  const itemsToShow = isCollapsed
    ? languageProficiencyItems.slice(0, defaultAmountOfEntries)
    : languageProficiencyItems;

  const isShowMoreVisible =
    languageProficiencyItems.length > defaultAmountOfEntries;

  return (
    <LanguageTableWrapper>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <LanguageHeaderCell>{LABELS[LANGUAGE_FIELD]}</LanguageHeaderCell>
            {FIELDS_TO_DISPLAY.map(field => {
              return (
                <TableHeaderCell key={field}>{LABELS[field]}</TableHeaderCell>
              );
            })}
          </TableHeaderRow>
        </TableHeader>
        <tbody>
          {itemsToShow.map((languageInfo, index) => (
            <TableRow
              key={languageInfo[LANGUAGE_FIELD]}
              className={
                !isShowMoreVisible && index === itemsToShow.length - 1
                  ? "hide-bottom-border"
                  : null
              }
            >
              <LanguageCell>{languageInfo[LANGUAGE_FIELD]}</LanguageCell>
              {FIELDS_TO_DISPLAY.map(field => (
                <TableCell key={field}>{languageInfo[field] || "-"}</TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </Table>
      {isShowMoreVisible && (
        <ShowMoreButton type="button" onClick={handleShowMoreClick}>
          {isCollapsed ? "Show more" : "Show less"}
        </ShowMoreButton>
      )}
    </LanguageTableWrapper>
  );
}
