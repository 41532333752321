import { useCallback, useEffect, useRef } from "react";

export const useScrollIntoView = (isNeedToScroll = true) => {
  const isMounted = useRef(false);
  const nodeRef = useRef(null);

  const onScroll = useCallback(() => {
    if (isNeedToScroll && nodeRef?.current?.scrollIntoView) {
      nodeRef.current.scrollIntoView();
    }
  }, [isNeedToScroll, nodeRef]);

  useEffect(() => {
    if (isMounted.current) {
      onScroll();
    } else {
      isMounted.current = true;
    }
  }, [isNeedToScroll, nodeRef, isMounted, isNeedToScroll]);

  return { nodeRef, onScroll };
};
