import colors from "./colors";
import spacing from "./spacing";
import breakpoints from "./breakpoints";
import fontFamily from "./fontFamily";
import variables from "./variables";
import fontWeight from "./fontWeight";

const theme = {
  colors,
  spacing,
  variables,
  fontFamily,
  breakpoints,
  fontWeight,
};

export default theme;
