import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as pdfjs from "pdfjs-dist/legacy/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { CenteredSpinner } from "../../Spinner";
import styles from "./CorrespondencePreviewView.module.css";
import PDFViewer from "../../PDFViewer/PDFViewer";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const CorrespondencePreviewView = React.memo(
  ({
    correspondence,
    attachmentID,
    getCorrespondenceDocument,
    isFullscreen,
    tabsContainerWidth,
  }) => {
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const documentId = correspondence?.id;
    const [file, setFile] = useState(null);

    useEffect(() => {
      const getPDFDocument = async () => {
        try {
          const { arrayBuffer } = await getCorrespondenceDocument(
            documentId,
            false
          );

          if (!arrayBuffer) return false;
          setIsLoadingPdf(true);

          const pdfDoc = await pdfjs.getDocument({ data: arrayBuffer }).promise;

          setFile(pdfDoc);
          setIsLoadingPdf(false);
        } catch (error) {
          console.log(error);
        }
      };

      getPDFDocument();
    }, [attachmentID, getCorrespondenceDocument]);

    const previewStyles = classNames(
      {
        [styles.correspondencePreviewWidget]: !isFullscreen,
      },
      styles.correspondencePreview
    );

    return (
      <div className={previewStyles}>
        <div className={styles.preview}>
          {!isLoadingPdf && file ? (
            <PDFViewer
              file={file}
              className={styles.canvasContainer}
              isFullscreen={isFullscreen}
              tabsContainerWidth={tabsContainerWidth}
            />
          ) : (
            <CenteredSpinner />
          )}
        </div>
      </div>
    );
  }
);

CorrespondencePreviewView.propTypes = {
  correspondence: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  attachmentID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getCorrespondenceDocument: PropTypes.func.isRequired,
  isFullscreen: PropTypes.bool,
  tabsContainerWidth: PropTypes.number,
};

CorrespondencePreviewView.defaultProps = {
  attachmentID: null,
  tabsContainerWidth: 0,
};
