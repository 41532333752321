import React, { Fragment } from "react";
import { SkeletonTextElement } from "../../utils/Loaders/SkeletonPulse";

const SKELETON_CSS_SMALL = { marginTop: "16px" };
const SKELETON_CSS_SMALLEST = { marginTop: "8px" };

const ProfileCompletenessSkeleton = () => {
  return (
    <Fragment>
      <SkeletonTextElement width={110} height={24} />
      <SkeletonTextElement height={38} css={SKELETON_CSS_SMALL} />
      <SkeletonTextElement
        width={198}
        height={20}
        css={SKELETON_CSS_SMALLEST}
      />
      <SkeletonTextElement height={44} margin={3} />
      <SkeletonTextElement height={44} margin={3} />
      <SkeletonTextElement height={44} margin={2} />
      <SkeletonTextElement width={93} height={20} css={SKELETON_CSS_SMALLEST} />
    </Fragment>
  );
};

export default ProfileCompletenessSkeleton;
