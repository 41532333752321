import React from "react";

const Available = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8" fill="#109647" stroke="white" strokeWidth="2" />
  </svg>
);

export default Available;
