import React from "react";

export const MSGIcon = props => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8 27h30v7c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29 27V0l7-3e-7C37.1046-3.6e-7 38 .895429 38 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="31" height="19.5" rx="2" fill="#00754A" />
    <path
      d="M4.76 15.61h1.47l2.03 5.68 2.09-5.68h1.42v7.14h-1.11v-5.19L8.7 22.75h-.96l-1.89-5.18v5.18H4.76v-7.14Zm9.3154 4.96c.0533.4667.22.8067.5 1.02.2867.2133.71.32 1.27.32.2733 0 .5033-.03.69-.09.1867-.06.3367-.14.45-.24.1133-.1.1933-.2167.24-.35.0467-.14.07-.2867.07-.44 0-.1133-.0167-.2233-.05-.33-.0333-.1133-.0933-.2167-.18-.31s-.2033-.1733-.35-.24c-.1467-.0733-.3333-.13-.56-.17l-.91-.17c-.36-.0667-.68-.15-.96-.25-.28-.1-.5167-.23-.71-.39-.1867-.1667-.33-.3667-.43-.6-.0933-.24-.14-.53-.14-.87 0-.34.0667-.6367.2-.89.14-.2533.33-.4633.57-.63.24-.1733.5233-.3.85-.38.3333-.0867.6933-.13 1.08-.13.4933 0 .9067.0567 1.24.17.3333.1133.6033.2633.81.45.2067.18.3633.39.47.63.1067.24.18.49.22.75l-1.14.18c-.0933-.4-.2567-.6967-.49-.89-.2267-.1933-.59-.29-1.09-.29-.2867 0-.5267.0267-.72.08-.1867.0467-.34.1167-.46.21-.1133.0867-.1967.19-.25.31-.0467.1133-.07.2333-.07.36 0 .3133.0833.5467.25.7.1733.1533.4733.2733.9.36l.99.19c.7533.14 1.3067.38 1.66.72.3533.3333.53.8167.53 1.45 0 .3-.0567.58-.17.84-.1067.2533-.2733.4767-.5.67-.2267.1867-.51.3333-.85.44-.3333.1133-.7233.17-1.17.17-.9133 0-1.63-.19-2.15-.57-.52-.3867-.8067-.9833-.86-1.79h1.22Zm11.4579-1.61v2.94c-.3667.34-.8.5967-1.3.77s-1.0034.26-1.51.26c-.5534 0-1.04-.0867-1.46-.26-.4134-.18-.76-.43-1.04-.75-.28-.3267-.49-.72-.63-1.18-.14-.46-.21-.9767-.21-1.55 0-.58.0766-1.1.23-1.56.1533-.4667.37-.8633.65-1.19s.62-.5767 1.02-.75.8466-.26 1.34-.26c.4333 0 .81.0567 1.13.17.3266.1067.6.2567.82.45.2266.1933.4066.42.54.68.14.2533.24.5267.3.82l-1.19.17c-.0534-.2-.12-.3767-.2-.53-.0734-.16-.1734-.2933-.3-.4-.12-.1067-.27-.1867-.45-.24-.18-.06-.3967-.09-.65-.09-.3334 0-.6267.0667-.88.2-.2467.1267-.4534.31-.62.55-.1667.24-.29.5267-.37.86-.08.3333-.12.7033-.12 1.11 0 .42.04.7967.12 1.13.08.3333.2066.62.38.86.1733.2333.39.4133.65.54.2666.1267.5866.19.96.19.3333 0 .6333-.0333.9-.1.2666-.0733.5233-.1967.77-.37v-1.45h-1.58v-1.02h2.7Z"
      fill="#fff"
    />
  </svg>
);
