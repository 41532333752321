import styled from "styled-components";
import { CardLayout, CardTitle } from "../../styles";
import { TableCell, TableHeaderCell } from "../styles/table";
import { respondTo, respondFrom } from "../../utils/mixins";

export const LanguageProficiencyCardTitle = styled(CardTitle)`
  ${respondFrom.medium`
    margin-left: 24px;
    margin-bottom: 24px;
  `}
`;

export const LanguageTableWrapper = styled.div`
  ${respondTo.medium`
    display: none;
  `}
`;

export const LanguageProficiencyLevelsWrapper = styled.div`
  ${respondFrom.medium`
    display: none;
  `}
  margin-top: 16px;
`;

export const Card = styled(CardLayout)`
  flex-direction: column;
  ${respondFrom.medium`
    padding: ${({ theme: { spacing } }) => spacing.xm} 0;
  `}
`;

export const LanguageHeaderCell = styled(TableHeaderCell)`
  min-width: 100px;
`;

export const LanguageCell = styled(TableCell)`
  min-width: 100px;
  font-weight: ${({
    theme: {
      fontWeight: { medium },
    },
  }) => medium};
`;

export const LanguageProficiencyCardWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 26px;
  }
`;
