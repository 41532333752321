import React, { Component, createRef } from "react";

import styles from "../Correspondence/CorrespondencePreviewView/CorrespondencePreviewView.module.css";

class Page extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
  }

  async componentDidMount() {
    this.renderPDF();
  }

  renderPDF = async () => {
    const { pdf, pageNumber, scale } = this.props;
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({ scale: scale });

    const canvas = this.pageRef.current;
    if (canvas) {
      const outputScale = window.devicePixelRatio || 1;
      const context = canvas.getContext("2d");
      canvas.height = Math.floor(viewport.height * outputScale);
      canvas.width = Math.floor(viewport.width * outputScale);
      canvas.className = styles.canvas;

      const transform =
        outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

      page.render({
        canvasContext: context,
        transform,
        viewport,
      });
    }
  };

  componentWillUnmount() {
    const canvas = this.pageRef.current;
    canvas.height = 0;
    canvas.width = 0;
    const context = canvas.getContext("2d");

    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
    }
  }

  render() {
    const { index, style } = this.props;
    return (
      <div key={index} style={{ ...style, height: "auto" }}>
        <canvas ref={this.pageRef} />
      </div>
    );
  }
}

export default Page;
