import React from "react";

export default function ArrowIcon() {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m8.001 9.19 3.78-3.97a.69.69 0 0 1 1.01 0 .777.777 0 0 1 0 1.06l-4.285 4.5a.69.69 0 0 1-1.01 0L3.21 6.28a.777.777 0 0 1 0-1.06.69.69 0 0 1 1.01 0l3.781 3.97Z"
        fill="#4D5156"
      />
    </svg>
  );
}
