import styled from "styled-components";
import { CardLayout, CardTitle } from "../../styles";

export const Card = styled(CardLayout)`
  flex-direction: column;
  padding: 24px;
`;

export const Title = styled(CardTitle)`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  margin: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
