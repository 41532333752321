import { useContext, useEffect, useState } from "react";
import { PROFILE_ERROR_TYPES } from "../constants";
import { ProfileContext } from "../context/ProfileProvider";

export const useUserAvatar = email => {
  const [isLoading, setLoading] = useState(true);
  const [userAvatar, setUserAvatar] = useState("");
  const { getUserAvatar, onError } = useContext(ProfileContext);
  useEffect(() => {
    if (!email) return;
    setLoading(true);
    getUserAvatar(email)
      .then(res => setUserAvatar(res))
      .catch(error => {
        setUserAvatar("");
        onError(PROFILE_ERROR_TYPES.UserAvatarError, error);
      })
      .finally(() => setLoading(false));
  }, [setUserAvatar, setLoading, email, onError]);
  return [userAvatar, isLoading];
};
