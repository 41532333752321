import React from "react";
import PropTypes from "prop-types";
import { CollapsibleList } from "../collapsibleList/CollapsibleList";
import { ListItem } from "../historyListItem/HistoryListItem";
import { HistoryListCard } from "../historyListCard/HistoryListCard";
import { TITLES } from "../../constants";
import { useScrollIntoView } from "../../hooks";
import { MY_PROFILE_PAGE_SECTION_EDUCATIONANDQUALIFICATION } from "../../auto-test-constants";

export default function EducationAndQualificationCard({ items, isLoading }) {
  const isEducationListAvailable =
    Array.isArray(items) && Boolean(items.length);
  const { nodeRef, onScroll } = useScrollIntoView();

  if (!isLoading && !isEducationListAvailable) return null;

  return (
    <HistoryListCard
      ref={nodeRef}
      isLoading={isLoading}
      title={TITLES.EDUCATION_AND_QUALIFICATION}
      attrValue={MY_PROFILE_PAGE_SECTION_EDUCATIONANDQUALIFICATION}
    >
      <CollapsibleList onCollapse={onScroll} items={items}>
        {(listItems, totalLength) =>
          listItems.map(({ year, institute, degree }, index) => (
            <ListItem
              key={year}
              isLast={index === totalLength - 1}
              isActive={index === 0}
              date={year}
              title={institute}
              details={degree}
            />
          ))
        }
      </CollapsibleList>
    </HistoryListCard>
  );
}

EducationAndQualificationCard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string.isRequired,
      institute: PropTypes.string.isRequired,
      degree: PropTypes.string.isRequired,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
};

EducationAndQualificationCard.defaultProps = {
  items: [],
};
