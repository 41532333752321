import React, { Fragment } from "react";
import PropTypes from "prop-types";

import {
  Card,
  Title,
  InfoItem,
  InfoList,
  InfoTitle,
  InfoValue,
  TitleText,
  InfoListWrapper,
} from "./styles/informationCard";
import { SkeletonTextElement } from "../utils/Loaders/SkeletonPulse";
import { MY_PROFILE_PAGE_SECTION } from "../auto-test-constants";

function InformationCard({
  title,
  icon: Icon,
  info,
  summaryInfo,
  isEmployeeInfo,
  isLoading,
}) {
  const infoList = items =>
    items
      ? items.map(item => (
          <InfoItem key={item.title}>
            <InfoTitle>{item.title}</InfoTitle>
            <InfoValue>{item.value || "-"}</InfoValue>
          </InfoItem>
        ))
      : null;
  const skeleton = [185, 216, 185, 143, 216].map((width, i) => {
    const margin = i === 4 ? 0 : 16;
    return (
      <SkeletonTextElement
        key={`${width}_${i}`}
        height={16}
        width={width}
        margin={margin}
      />
    );
  });
  const extraLine = isEmployeeInfo && (
    <SkeletonTextElement height={16} width={216} margin={20} />
  );

  if (extraLine) skeleton.unshift(extraLine);

  const dataAttrValue = `${MY_PROFILE_PAGE_SECTION} ${title
    .toLowerCase()
    .replace(/ /g, "-")}`;

  return (
    <Card data-attributes={dataAttrValue}>
      {isLoading ? (
        <SkeletonTextElement height={16} width={143} margin={24} />
      ) : (
        <Title>
          {Icon && <Icon />}
          <TitleText withIcon={Icon}>{title}</TitleText>
        </Title>
      )}
      {isLoading ? (
        <>{skeleton}</>
      ) : (
        <InfoListWrapper>
          {info?.length ? <InfoList>{infoList(info)}</InfoList> : null}
          {summaryInfo?.length ? (
            <InfoList>{infoList(summaryInfo)}</InfoList>
          ) : null}
        </InfoListWrapper>
      )}
    </Card>
  );
}

InformationCard.defaultProps = {
  icon: null,
  info: [],
  isLoading: false,
};
InformationCard.propTypes = {
  icon: PropTypes.func,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isEmployeeInfo: PropTypes.bool,
  info: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
      ]),
    })
  ),
};

export default InformationCard;
