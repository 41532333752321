import { css } from "styled-components";
import breakpoints from "../theme/breakpoints";

export const respondFrom = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);

export const respondTo = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);

export const multiLineEllipsis = ({ maxWidth = "100%", lineClamp = 2 }) => `
  display: block;
  display: -webkit-box;
  max-width: ${maxWidth};
  -webkit-line-clamp: ${lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const flexboxMixin = ({
  alignItems = "center",
  justifyContent = "center",
}) => `
  display: flex;
  align-items: ${alignItems};
  justify-content: ${justifyContent};
`;
export const absoluteCentered = () => `
  left: 50%;
  transform: translate(-50%, 0);
`;

export const macOSVerticalScroll = props => `
/* Customize scrollbar like Mac OS */

  /* total width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* background of the scrollbar except button */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* scrollbar button */
  &::-webkit-scrollbar-thumb {
    background-color: ${props.theme.colors.coolGray};
    border-radius: 100px;
    border: 2px solid
      ${
        props.isPageBgGray
          ? props.theme.colors.grayLighter
          : props.theme.colors.white
      };
  }
`;
