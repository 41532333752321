import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  calendarHeightPx,
  datePickerViewsOrder,
  jsToRealDateDisplacement,
  lastHour,
  lastMillisecond,
  lastMinute,
  lastSecond,
  localFirstDayOfWeek,
  monthsFull,
  numberOfMonthsInYear,
  DATE_PICKER_STRINGS,
  DatePickerView,
} from "./DatePicker.constants";

import { MonthsView } from "./datePickerViews/MonthsView";
import { DaysView } from "./datePickerViews/DaysView";
import { Modal } from "../Modal";
import { getFormattedDate, getMonth, getYear } from "./DatePicker.helpers";
import styles from "./DatePicker.module.css";
import { Calendar } from "./icons/Calendar";
import { Arrow } from "./icons/Arrow";

export const DatePicker = ({
  onSelect,
  getDateValue,
  shouldSelectEndOfDay,
  maxDate,
  minDate,
  hideClearBtn,
  isFullscreen,
}) => {
  const currentDate = Date.now();
  const dateValue = getDateValue();
  const [month, setMonth] = useState(getMonth(currentDate));
  const [year, setYear] = useState(getYear(currentDate));
  const [selectedDate, setSelectedDate] = useState(dateValue || null);
  const [selectedView, setSelectedView] = useState(DatePickerView.Days);
  const [inputValue, setInputValue] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState({});
  const inputRef = useRef(null);
  const calendarRef = useRef(null);

  if (dateValue !== selectedDate) {
    setSelectedDate(dateValue);
    if (dateValue) {
      setMonth(getMonth(dateValue));
      setYear(getYear(dateValue));
    }
  }

  useEffect(() => {
    setInputValue(getFormattedDate(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (isDatePickerOpen) {
      setIsDatePickerOpen(false);
    }
  }, [isFullscreen]);

  const selectToPreviousView = useCallback(() => {
    const currentIndex = datePickerViewsOrder.indexOf(selectedView);
    const isFirstView = currentIndex === 0;

    if (!isFirstView) {
      setSelectedView(datePickerViewsOrder[currentIndex - 1]);
    }
  }, [selectedView]);

  const goToNextView = useCallback(() => {
    const isLastView = datePickerViewsOrder.slice(-1)[0] === selectedView;

    if (!isLastView) {
      const currentIndex = datePickerViewsOrder.indexOf(selectedView);
      setSelectedView(datePickerViewsOrder[currentIndex + 1]);
    }
  }, [selectedView]);

  const goToToday = useCallback(() => {
    setMonth(getMonth(currentDate));
    setYear(getYear(currentDate));
    setSelectedDate(currentDate);
    setSelectedView(datePickerViewsOrder.slice(-1)[0]);
    onSelect(currentDate.valueOf());
    calendarOpenClose();
  }, [currentDate]);

  const goToPreviousMonth = useCallback(() => {
    if (month > 0) {
      setMonth(month - 1);
    } else {
      setYear(year - 1);
      setMonth(numberOfMonthsInYear - jsToRealDateDisplacement);
    }
  }, [month, year]);

  const goToNextMonth = useCallback(() => {
    if (month < numberOfMonthsInYear - jsToRealDateDisplacement) {
      setMonth(month + 1);
    } else {
      setYear(year + 1);
      setMonth(0);
    }
  }, [month, year]);

  const goToPreviousYear = useCallback(() => {
    setYear(year - 1);
  }, [year]);

  const goToNextYear = useCallback(() => {
    setYear(year + 1);
  }, [year]);

  const onMonthSelect = useCallback(
    month => {
      setMonth(month);
      goToNextView();
    },
    [goToNextView]
  );

  const calendarOpenClose = useCallback(() => {
    if (isDatePickerOpen) {
      setSelectedView(datePickerViewsOrder.slice(-1)[0]);
    } else {
      const { bottom, top, left } = inputRef.current.getBoundingClientRect();
      const shouldOpenToTop =
        document.documentElement.clientHeight - bottom < calendarHeightPx;
      const topHeight =
        top + window.pageYOffset < calendarHeightPx
          ? calendarHeightPx - 16
          : top + window.pageYOffset - 16;
      const calendarPosition = shouldOpenToTop
        ? {
            top: `${topHeight}px`,
            left: `${left + window.pageXOffset - 12}px`,
            transform: "translate(0, -100%)",
          }
        : {
            top: `${bottom + window.pageYOffset + 8}px`,
            left: `${left + window.pageXOffset - 12}px`,
          };

      setCalendarPosition(calendarPosition);
    }
    setIsDatePickerOpen(!isDatePickerOpen);
  }, [isDatePickerOpen]);

  const onDateSelect = useCallback(
    date => {
      const newDate = shouldSelectEndOfDay
        ? new Date(
            year,
            month,
            date,
            lastHour,
            lastMinute,
            lastSecond,
            lastMillisecond
          )
        : new Date(year, month, date);

      onSelect(newDate.valueOf());
      calendarOpenClose();
    },
    [calendarOpenClose, month, onSelect, shouldSelectEndOfDay, year]
  );

  const onWrapperClick = ({ nativeEvent: { target } }) => {
    if (!calendarRef.current.contains(target)) {
      calendarOpenClose();
    }
  };

  const onClearButtonClick = useCallback(() => {
    onSelect(null);
    calendarOpenClose();
  }, [calendarOpenClose, onSelect]);

  const getViewComponent = useCallback(() => {
    switch (selectedView) {
      case DatePickerView.Months:
        return <MonthsView onSelect={onMonthSelect} />;
      case DatePickerView.Days:
      default:
        return (
          <DaysView
            year={year}
            month={month}
            selectedDate={selectedDate || currentDate}
            onSelect={onDateSelect}
            firstDayOfWeek={localFirstDayOfWeek}
            maxDate={maxDate}
            minDate={minDate}
          />
        );
    }
  }, [
    currentDate,
    maxDate,
    minDate,
    month,
    onDateSelect,
    onMonthSelect,
    selectedDate,
    selectedView,
    year,
  ]);

  const getSelectedPeriodDescription = useCallback(() => {
    switch (selectedView) {
      case DatePickerView.Months:
        return year;
      case DatePickerView.Days:
      default:
        return `${monthsFull[month]} ${year}`;
    }
  }, [month, selectedView, year]);

  const selectPreviousPeriod = useCallback(() => {
    switch (selectedView) {
      case DatePickerView.Months:
        goToPreviousYear();
        break;
      case DatePickerView.Days:
      default:
        goToPreviousMonth();
        break;
    }
  }, [goToPreviousMonth, goToPreviousYear, selectedView]);

  const selectNextPeriod = useCallback(() => {
    switch (selectedView) {
      case DatePickerView.Months:
        goToNextYear();
        break;
      case DatePickerView.Days:
      default:
        goToNextMonth();
        break;
    }
  }, [goToNextMonth, goToNextYear, selectedView]);

  return (
    <div className={styles.datePicker}>
      <div className={styles.inputDetails}>
        <input
          type="text"
          placeholder={DATE_PICKER_STRINGS.inputPlaceholder}
          className={styles.dateInput}
          value={inputValue}
          onChange={() => {}}
          ref={inputRef}
          onClick={calendarOpenClose}
        />
        <button
          className={styles.openCalendarButton}
          onClick={calendarOpenClose}
        >
          <Calendar />
        </button>
      </div>
      {isDatePickerOpen && (
        <Modal className={styles.modal}>
          <div className={styles.calendarModal} onClick={onWrapperClick}>
            <div
              className={styles.calendar}
              style={calendarPosition}
              ref={calendarRef}
            >
              <div className={styles.navigation}>
                <button className={styles.back} onClick={selectPreviousPeriod}>
                  <Arrow />
                </button>
                <button
                  className={styles.currentViewData}
                  onClick={selectToPreviousView}
                >
                  {getSelectedPeriodDescription()}
                </button>
                <button className={styles.forward} onClick={selectNextPeriod}>
                  <Arrow />
                </button>
              </div>
              <div>{getViewComponent()}</div>
              <div className={styles.clear}>
                {!hideClearBtn && (
                  <button
                    className={styles.clearButton}
                    onClick={onClearButtonClick}
                  >
                    {DATE_PICKER_STRINGS.clear}
                  </button>
                )}
              </div>
              <div className={styles.goToToday}>
                <button className={styles.goToTodayText} onClick={goToToday}>
                  {DATE_PICKER_STRINGS.goToToday}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
