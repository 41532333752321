import React from "react";

const Offline = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9"
      r="6.75"
      fill="white"
      stroke="#9D9FA0"
      strokeWidth="2.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3539 6.6183C10.2581 6.65513 10.1715 6.71221 10.0942 6.78955L8.99902 7.88474L7.90107 6.78679C7.82373 6.70945 7.73627 6.65144 7.63867 6.61277C7.54292 6.57594 7.44532 6.55753 7.34589 6.55753C7.24645 6.55753 7.14793 6.57687 7.05034 6.61554C6.95642 6.65421 6.8708 6.71221 6.79346 6.78955C6.71612 6.86689 6.65719 6.95344 6.61668 7.04919C6.57985 7.14494 6.56144 7.24254 6.56144 7.34198C6.56144 7.44141 6.57985 7.53901 6.61668 7.63476C6.65535 7.73236 6.71336 7.81983 6.7907 7.89717L7.88864 8.99512L6.7907 10.0931C6.71336 10.1704 6.65535 10.2579 6.61668 10.3555C6.57985 10.4512 6.56144 10.5488 6.56144 10.6483C6.56144 10.7477 6.58077 10.8462 6.61944 10.9438C6.65811 11.0377 6.71612 11.1233 6.79346 11.2007C6.8708 11.278 6.95734 11.3369 7.0531 11.3775C7.14885 11.4143 7.24645 11.4327 7.34589 11.4327C7.44532 11.4327 7.54292 11.4143 7.63867 11.3775C7.73627 11.3388 7.82373 11.2808 7.90107 11.2034L8.99902 10.1055L10.0942 11.2007C10.1716 11.278 10.2581 11.3351 10.3539 11.3719C10.4514 11.4106 10.55 11.4299 10.6494 11.4299C10.7488 11.4299 10.8464 11.4115 10.9422 11.3747C11.0398 11.336 11.1272 11.278 11.2046 11.2007C11.2819 11.1233 11.339 11.0368 11.3758 10.941C11.4145 10.8434 11.4338 10.7449 11.4338 10.6455C11.4338 10.5461 11.4145 10.4475 11.3758 10.3499C11.339 10.2542 11.2819 10.1676 11.2046 10.0903L10.1094 8.99512L11.2046 7.89993C11.2819 7.82259 11.339 7.73604 11.3758 7.64029C11.4145 7.54269 11.4338 7.44418 11.4338 7.34474C11.4338 7.2453 11.4154 7.14771 11.3786 7.05195C11.3399 6.95436 11.2819 6.86689 11.2046 6.78955C11.1272 6.71221 11.0407 6.65513 10.9449 6.6183C10.8474 6.57963 10.7488 6.56029 10.6494 6.56029C10.55 6.56029 10.4514 6.57963 10.3539 6.6183Z"
      fill="#9D9FA0"
    />
    <circle cx="9" cy="9" r="8" stroke="white" strokeWidth="2" />
  </svg>
);

export default Offline;
