import {
  DatePickerDayType,
  jsLastDayOfWeek,
  jsToRealDateDisplacement,
  numberOfDaysInGrid,
} from "./DatePicker.constants";
import classNames from "classnames";

import styles from "./DatePicker.module.css";

export const getDate = d => new Date(d).getDate();
export const getDay = d => new Date(d).getDay();
export const getMonth = d => new Date(d).getMonth();
export const getYear = d => new Date(d).getFullYear();
export const getDaysInMonth = d =>
  new Date(getYear(d), getMonth(d) + 1, 0).getDate();

export const getDayNumbersForGrid = (
  year,
  month,
  selectedDate,
  firstDayOfWeek
) => {
  const dateForSelectedMonth = new Date(year, month);
  const dateForPreviousMonth = new Date(year, month - 1);
  const firstWeekDayOfMonth = getDay(dateForSelectedMonth) - firstDayOfWeek;
  const localFirstWeekDayOfMonth =
    firstWeekDayOfMonth >= 0 ? firstWeekDayOfMonth : jsLastDayOfWeek;
  const numberOfDaysInSelectedMonth = getDaysInMonth(dateForSelectedMonth);
  const numberOfDaysInPreviousMonth = getDaysInMonth(dateForPreviousMonth);
  const selectedYear = getYear(selectedDate);
  const selectedMonth = getMonth(selectedDate);
  const selectedDay = getDate(selectedDate);

  return Array.from(new Array(numberOfDaysInGrid)).map((element, i) => {
    const isPreviousMonth = i < localFirstWeekDayOfMonth;
    const isCurrentMonth =
      i >= firstWeekDayOfMonth &&
      i < localFirstWeekDayOfMonth + numberOfDaysInSelectedMonth;

    let date;

    if (isPreviousMonth) {
      const day =
        numberOfDaysInPreviousMonth -
        localFirstWeekDayOfMonth +
        jsToRealDateDisplacement +
        i;
      date = {
        day,
        classes: classNames(styles.dateSelector),
        type: DatePickerDayType.Previous,
        date: new Date(year, month - 1, day).valueOf(),
      };
    } else if (isCurrentMonth) {
      const day = i - localFirstWeekDayOfMonth + jsToRealDateDisplacement;

      date = {
        day,
        classes: classNames(styles.dateSelector, {
          [styles.todayDate]:
            selectedYear === year &&
            selectedMonth === month &&
            selectedDay === day,
        }),
        type: DatePickerDayType.Current,
        date: new Date(year, month, day).valueOf(),
      };
    } else {
      const day =
        i -
        localFirstWeekDayOfMonth -
        numberOfDaysInSelectedMonth +
        jsToRealDateDisplacement;

      date = {
        day,
        classes: classNames(styles.dateSelector),
        type: DatePickerDayType.Next,
        date: new Date(year, month + 1, day).valueOf(),
      };
    }

    return date;
  });
};

export const getFormattedDate = value => {
  if (!value) return "";
  return new Intl.DateTimeFormat("en-GB").format(new Date(value));
};
