import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import iInfoIcon from "../icons/iInfo";
import ProfileCompletenessSkeleton from "./ProfileCompletenessSkeleton";
import ProfileCompletenessAccordion from "./ProfileCompletenessAccordion";

import { noop } from "../../utils/utils";

import {
  Link,
  ProfileCompletenessIndicator,
  ProfileCompletenessIndicatorBox,
  ProfileCompletenessIndicatorTitle,
  ProfileCompletenessIndicatorLoading,
  ProfileCompletenessHeader,
  ProfileCompletenessTitle,
  ProfileCompletenessTitleTwo,
  ProfileCompletenessWrapper,
  ProfileCompletenessSectionBox,
} from "../styles/profileCompleteness";
import { MY_PROFILE_PAGE_SECTION_PROFILECOMPLETENESS } from "../../auto-test-constants";

const DEFAULT_VISIBLE_SECTIONS = 3;

export const COMPLETENESS_PERCENTAGE_STATUS = {
  missingData: "Missing Data",
  partialData: "Partial Data",
  сompleted: "Completed",
};

export const ProfileCompleteness = ({
  completeness,
  isLoading,
  onGoToEServiceClick,
  isMobile,
}) => {
  const [visibleSections, setVisibleSections] = useState([]);
  const [isShowAllSection, setIshowAllSection] = useState(false);

  const initSection = () => {
    if (!completeness?.sections?.length) {
      return;
    }
    if (isShowAllSection) {
      setVisibleSections([...completeness?.sections]);
      return;
    }

    const sortedSections = completeness?.sections?.filter(
      s => s.completenessText !== COMPLETENESS_PERCENTAGE_STATUS.сompleted
    );

    setVisibleSections(sortedSections.slice(0, DEFAULT_VISIBLE_SECTIONS));
  };

  const toogleAllSections = e => {
    e.preventDefault();
    setIshowAllSection(!isShowAllSection);
  };

  useEffect(() => {
    initSection();
  }, [completeness, isShowAllSection]);

  if (isLoading) {
    return (
      <ProfileCompletenessWrapper isLoading>
        <ProfileCompletenessSkeleton />
      </ProfileCompletenessWrapper>
    );
  }

  if (!completeness || Number(completeness?.total) === 100) {
    return null;
  }

  const renderLink = () => {
    const sectionСandidates = completeness?.sections.filter(
      s => s.completenessText !== COMPLETENESS_PERCENTAGE_STATUS.сompleted
    );
    const sectionLendth = sectionСandidates?.length;
    if (!sectionLendth || sectionLendth <= DEFAULT_VISIBLE_SECTIONS) {
      return null;
    }
    return (
      <Link onClick={toogleAllSections}>
        {isShowAllSection
          ? "Show less"
          : `Show more (${sectionLendth - DEFAULT_VISIBLE_SECTIONS})`}
      </Link>
    );
  };

  if (completeness?.isManagerView) {
    return (
      <ProfileCompletenessWrapper isManagerView data-attributes={MY_PROFILE_PAGE_SECTION_PROFILECOMPLETENESS}>
        <ProfileCompletenessIndicatorTitle isManagerView>
          {iInfoIcon()}
          <p>
            Profile is <span>{completeness.totalText}</span> complete
          </p>
        </ProfileCompletenessIndicatorTitle>
      </ProfileCompletenessWrapper>
    );
  }

  return (
    <ProfileCompletenessWrapper data-attributes={MY_PROFILE_PAGE_SECTION_PROFILECOMPLETENESS}>
      <ProfileCompletenessHeader>
        <ProfileCompletenessTitle>
          Profile completeness
        </ProfileCompletenessTitle>
      </ProfileCompletenessHeader>
      <ProfileCompletenessIndicatorBox>
        <ProfileCompletenessIndicatorTitle>
          Your profile is <span>{completeness.totalText}</span> complete
        </ProfileCompletenessIndicatorTitle>
        <ProfileCompletenessIndicator>
          <ProfileCompletenessIndicatorLoading
            loadingPercentage={completeness.total}
          />
        </ProfileCompletenessIndicator>
      </ProfileCompletenessIndicatorBox>
      <ProfileCompletenessTitleTwo>
        You have gaps in these sections:
      </ProfileCompletenessTitleTwo>
      <ProfileCompletenessSectionBox>
        <ProfileCompletenessAccordion
          sections={visibleSections}
          isShowAllSection={isShowAllSection}
          onGoToEServiceClick={onGoToEServiceClick}
          isMobile={isMobile}
        />
        {renderLink()}
      </ProfileCompletenessSectionBox>
    </ProfileCompletenessWrapper>
  );
};

ProfileCompleteness.propTypes = {
  completeness: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onGoToEServiceClick: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
};

ProfileCompleteness.defaultProps = {
  isLoading: false,
  onGoToEServiceClick: noop,
};
