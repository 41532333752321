import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import AvatarGreen from "./../../components/icons/AvatarGreen";
import AvatarOrange from "./../../components/icons/AvatarOrange";
import AvatarBlue from "./../../components/icons/AvatarBlue";
import NoManager from "./../../components/icons/NoManager";
import { getRandomElementOfArray, isIOS } from "../utils";
import { flexboxMixin } from "../mixins";
import InitialsAvatar from "../InitialsAvatar/index";
import {
  Available,
  AvailableIdle,
  Away,
  Busy,
  BusyIdle,
  DoNotDisturb,
  Offline,
  Unknown,
} from "../../components/icons/userStatus";
import { USER_PRESENCE_STATUSES } from "../../constants";

export const getUserStatusIcon = ({ statusType }) => {
  switch (statusType) {
    case USER_PRESENCE_STATUSES.Available:
      return <Available />;
    case USER_PRESENCE_STATUSES.Away:
    case USER_PRESENCE_STATUSES.BeRightBack:
      return <Away />;
    case USER_PRESENCE_STATUSES.Busy:
      return <Busy />;
    case USER_PRESENCE_STATUSES.DoNotDisturb:
      return <DoNotDisturb />;
    case USER_PRESENCE_STATUSES.BusyIdle:
      return <BusyIdle />;
    case USER_PRESENCE_STATUSES.AvailableIdle:
      return <AvailableIdle />;
    case USER_PRESENCE_STATUSES.Offline:
      return <Offline />;
    case USER_PRESENCE_STATUSES.PresenceUnknown:
      return <Unknown />;
    default:
      return <Unknown />;
  }
};

const StyledLink = styled.div`
  display: block;
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

export const StyledAvatar = styled.img`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  ${() =>
    !isIOS() &&
    css`
      image-rendering: -webkit-optimize-contrast;
    `};
  object-fit: contain;
  object-position: top;
  -webkit-tap-highlight-color: transparent;
`;

export const Status = styled.div`
  position: absolute;
  bottom: -2px;
  right: -2px;

  ${flexboxMixin};

  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

function Avatar({
  size,
  src,
  className,
  status,
  statusSize,
  isClickable,
  name,
  surname,
  noData,
  ...props
}) {
  const [showFallbackImg, setShowFallbackImg] = useState(false);
  const catchImgError = () => setShowFallbackImg(true);

  useEffect(() => {
    if (src || !isClickable) {
      setShowFallbackImg(false);
    }
  }, [src]);

  const getDefaultAvatar = ({ name, surname, size }) => {
    const avatars = [AvatarGreen, AvatarBlue, AvatarOrange];
    const DefaultAvatar = getRandomElementOfArray(avatars);

    if (name) {
      return (
        <InitialsAvatar
          width={size}
          height={size}
          name={name}
          surname={surname}
        />
      );
    }

    if (noData || !isClickable) {
      return <NoManager size={size} />;
    }

    return <DefaultAvatar size={size} />;
  };

  return (
    <StyledLink size={size} {...props}>
      {noData || !src || showFallbackImg ? (
        getDefaultAvatar({ name, surname, size })
      ) : (
        <StyledAvatar
          src={src}
          width={size}
          height={size}
          alt=""
          isClickable={isClickable}
          className={className}
          onError={catchImgError}
        />
      )}
      {!!status && name && (
        <Status statusType={status} size={statusSize}>
          {getUserStatusIcon({ statusType: status })}
        </Status>
      )}
    </StyledLink>
  );
}

Avatar.propTypes = {
  size: PropTypes.number,
  statusSize: PropTypes.number,
  src: PropTypes.string.isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
  surname: PropTypes.string,
  status: PropTypes.oneOf([
    "active",
    "inactive",
    "busy",
    "Available",
    "Offline",
    "PresenceUnknown",
    undefined,
  ]),
  noData: PropTypes.bool,
};

Avatar.defaultProps = {
  className: "",
  status: undefined,
  statusSize: undefined,
  to: "",
  onClick: () => undefined,
  name: "",
  surname: "",
  size: 40,
  noData: false,
};
const arePropsEqual = ({ src, status }, nextProps) =>
  src === nextProps.src && status === nextProps.status;
export default memo(Avatar, arePropsEqual);
