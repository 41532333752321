import styled, { css } from "styled-components";
import { flexboxMixin } from "../../utils/mixins";

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;

  min-width: 244px;

  padding: 0 0 ${({ $isLast }) => ($isLast ? 0 : 24)}px 28px;
`;

export const Marker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;

  ${flexboxMixin};

  border-radius: 50%;
  background: ${({
    $isActive,
    theme: {
      colors: { alertGreenRGB, grayDarkRGB },
    },
  }) => ($isActive ? alertGreenRGB : grayDarkRGB)};

  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;

    border-radius: 50%;
    background: ${({
      $isActive,
      theme: {
        colors: { green, coolGray },
      },
    }) => ($isActive ? green : coolGray)};
  }
`;

export const Line = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  height: calc(100% - 10px);
  width: 1px;
  transform: translateX(-50%);
  background: ${({
    theme: {
      colors: { grayLight },
    },
  }) => grayLight};
`;

export const Info = styled.div`
  color: ${({
    theme: {
      colors: { grayDark },
    },
  }) => grayDark};
`;

export const Title = styled.div`
  ${({
    theme: {
      fontWeight: { medium },
      colors: { black },
    },
  }) => css`
    font-weight: ${medium};
    color: ${black};
  `};
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
`;

export const Date = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

export const Details = styled.p`
  margin: 4px 0 0;
  font-size: 14px;
  line-height: 20px;
`;
