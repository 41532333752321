import React from "react";
import { SkeletonTextElement } from "../../utils/Loaders/SkeletonPulse";

export function LanguageProficiencyLevelsSkeleton() {
  return (
    <React.Fragment>
      <SkeletonTextElement height={16} width={143} margin={24} />
      {[185, 216, 185, 143, 216].map((width, i) => {
        return (
          <SkeletonTextElement
            key={`${width}_${i}`}
            height={16}
            width={width}
            margin={16}
          />
        );
      })}
    </React.Fragment>
  );
}
