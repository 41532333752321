import React from "react";

export const URLIcon = props => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8 27h30v7c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29 27V0l7-3e-7C37.1046-3.6e-7 38 .895429 38 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="28" height="19.5" rx="2" fill="#291446" />
    <path
      d="M5.85 15.61v4.28c0 .6733.12 1.1767.36 1.51.24667.3267.66.49 1.24.49.57333 0 .97667-.1633 1.21-.49.24-.3333.36-.8367.36-1.51v-4.28h1.21v4.28c0 .5533-.0667 1.0233-.2 1.41-.13333.38-.32333.6933-.57.94-.24667.24-.54.4133-.88.52-.34.1133-.72.17-1.14.17-.91333 0-1.60667-.2433-2.08-.73-.47333-.4933-.71-1.2733-.71-2.34v-4.25h1.2Zm8.3248 3.3c.2733 0 .5-.0267.68-.08.18-.06.32-.14.42-.24.1066-.1067.18-.2333.22-.38.0466-.1467.07-.31.07-.49 0-.1667-.0267-.3167-.08-.45-.0467-.14-.13-.2567-.25-.35-.1134-.1-.2667-.1767-.46-.23-.1934-.0533-.4334-.08-.72-.08h-1.22v2.3h1.34Zm-.09-3.3c.54 0 .9833.0633 1.33.19.3533.1267.63.2933.83.5.2066.2.35.4233.43.67.08.2467.12.49.12.73 0 .4667-.0934.8567-.28 1.17-.18.3133-.4734.5667-.88.76l1.48 3.12h-1.32l-1.28-2.84c-.06.0067-.1234.0133-.19.02h-1.49v2.82h-1.19v-7.14h2.44Zm5.1957 0v6.09h3.54l-.15 1.05h-4.6v-7.14h1.21Z"
      fill="#fff"
    />
  </svg>
);
