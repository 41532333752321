import React from "react";

const ProfileBg = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 298 211"
    fill="none"
    className={className}
  >
    <g strokeWidth=".35">
      <path
        fill="#0090D0"
        stroke="#0090D0"
        d="M242.46 171.83c-5.5 4.98-11.67 9.31-16.47 14.96-2.23 2.63-4.14 5.53-6.6 7.93-3.12 3.03-6.98 5.02-10.36 7.7-3.17 2.52-6.11 5.18-9.52 7.4l-1.06.7h10.05c1.56-1.26 3.09-2.55 4.59-3.89 2.28-2.03 3.78-3.49 6.51-4.65a15.81 15.81 0 006.56-5.43c2.2-3.03 3.72-6.5 5.78-9.64 4.93-7.57 12.67-12.78 20.02-18.05 7.34-5.27 14.77-11.12 18.59-19.31 2.17-4.65 3.96-8.51 8.62-10.66 4.35-2 8.94-1.7 13.5-.16 1.64.55 3.32 1 5.03 1.35v-27.63a38.53 38.53 0 00-10.05 6.53c-17.2 15.6-27.93 37.31-45.19 52.85z"
      />
      <path
        fill="#0097D6"
        stroke="#0097D6"
        d="M279.15 138.92c-4.66 2.15-6.45 6-8.61 10.65-3.83 8.2-11.25 14.05-18.6 19.32-7.34 5.27-15.08 10.48-20.02 18.05-2.05 3.14-3.57 6.61-5.78 9.63a16.03 16.03 0 01-6.55 5.44c-2.67 1.14-4.23 2.61-6.52 4.65-1.5 1.33-3.02 2.62-4.58 3.88h8.46c9.79-8.53 18.06-18.84 27.37-27.97a162.4 162.4 0 0124.76-19.93c5.67-3.71 11.9-7.17 18.68-7.37 3.31-.1 6.6.58 9.83 1.27h.09V140.1c-1.7-.34-3.39-.79-5.04-1.34-4.54-1.53-9.13-1.85-13.49.16z"
      />
      <path
        fill="#0090D0"
        d="M296.08 53.8c.49.82 1.02 1.6 1.6 2.37V10.09a89.73 89.73 0 00-3.71 10.36c-3.18 11-3.48 23.37 2.11 33.34z"
      />
      <path
        fill="#0097D6"
        stroke="#0097D6"
        d="M61.04 196.92c-3.57-3.8-7.3-9.63-11.29-13.4-4.4-4.15-8.85-8.26-13.27-12.4-7.1-6.6-14.24-13.26-19.88-21.16-5.5-7.67-10.74-14.97-14.54-23.6a23.68 23.68 0 01-1.37-4.23V134c7.95 14.82 14.92 27.56 26.4 35.55 3.81 2.66 7.99 4.84 11.4 8 3.91 3.58 6.62 8.26 9.6 12.66a110.04 110.04 0 0017.75 20.31h12.18c-7-3.53-11.22-7.47-16.98-13.6z"
      />
      <path
        fill="#00AEEC"
        stroke="#00AEEC"
        d="M16.18 25.23c-.8-3.41-3.3-6.13-5.47-8.87A62.18 62.18 0 011.43.16H.69v55.96c4.23-4.45 6.67-8.76 9.7-15.23 2.44-5.2 7.08-10.03 5.79-15.66z"
      />
      <path
        fill="#009EDE"
        d="M7.59 88.97c2.47-5.1 2.67-10.55 3.55-16.14.8-5.19 1.67-10.52 4.38-15.01 3.18-5.3 8.55-8.8 13.25-12.8 4.7-4 9.12-9.24 9.17-15.42.06-5.18-3-9.93-3.52-15.09-.53-4.95 1.27-9.75 3.36-14.34H12.76c2 5.4 6.21 10.25 9.25 14.92 3.53 5.41 6.35 12.04 4.41 18.2-1.56 5-5.87 8.53-9.45 12.35A53.31 53.31 0 003.98 69.85c-1.05 4.62-1.62 9.6-3.3 13.96v14.01c2.68-2.67 5.19-5.28 6.91-8.85z"
      />
      <path
        fill="#00A8E7"
        stroke="#00A8E7"
        d="M37.06 184.53c-7.68-10.28-20.02-16.15-28.23-26.02-3.26-3.92-5.78-8.3-8.14-12.78v64.8h53.09a47.68 47.68 0 01-5.69-7.05c-4.02-6.1-6.65-13.09-11.03-18.95z"
      />
      <path
        fill="#009EDE"
        stroke="#009EDE"
        d="M48.08 190.22c-2.98-4.4-5.68-9.07-9.59-12.68-3.41-3.15-7.6-5.34-11.4-8C15.6 161.55 8.63 148.82.68 134v11.72c2.36 4.49 4.88 8.87 8.14 12.8 8.2 9.87 20.55 15.73 28.22 26.01 4.4 5.86 7.02 12.84 11.03 18.96a47.72 47.72 0 005.69 7.05h12.06a110.06 110.06 0 01-17.75-20.32z"
      />
      <path
        fill="#00A8E7"
        stroke="#00A8E7"
        d="M284.83 168.56c-7.86 1.36-13.82 4.47-21.38 7.03-8.68 2.94-17.74 12-24.1 21.84-3.14 4.84-7.13 9.12-11.47 13.1h69.8v-41.66c-4.22-.7-8.48-1.05-12.85-.3z"
      />
      <path
        fill="#009EDE"
        stroke="#009EDE"
        d="M297.6 156.53c-3.24-.68-6.52-1.37-9.84-1.27-6.77.2-13 3.66-18.67 7.38a162.36 162.36 0 00-24.76 19.92c-9.3 9.13-17.59 19.43-27.37 27.97h10.94c4.33-3.99 8.32-8.26 11.46-13.1 6.35-9.83 15.42-18.9 24.1-21.84 7.56-2.56 13.52-5.68 21.38-7.03 4.37-.75 8.63-.4 12.86.3v-12.31l-.1-.02z"
      />
      <path
        fill="#0090D0"
        stroke="#0090D0"
        d="M59.63 7.69c-2.32.15-3.8-.85-6.24.25a11.78 11.78 0 00-5.7 5.89c-1.88 4.19-1.76 8.97-1.73 13.56.03 4.6-.07 9.42-2.33 13.4-2.72 4.83-7.97 7.53-12.54 10.67-8.21 5.64-15.07 13.85-17.14 23.6-1.26 5.93-.7 12.2-2.62 17.97-2.2 6.7-8.71 10.94-10.62 17.58 0 .03 0 .07-.02.1v11.39c.32 1.45.78 2.87 1.37 4.23 3.8 8.63 9.05 15.93 14.54 23.6 5.64 7.89 12.78 14.57 19.88 21.17 4.42 4.13 8.85 8.25 13.26 12.39 4 3.76 7.73 9.58 11.3 13.4 5.75 6.14 9.95 10.08 16.97 13.62h9.8a77.39 77.39 0 01-4.04-1.86c-14.15-6.99-23.24-21.36-34.85-32.04-12.38-11.34-24.9-22.88-34.26-36.85-5.93-8.84-10.62-20-6.77-29.94 1.86-4.8 5.54-8.73 7.32-13.56 2.44-6.6 1.06-14.06 3.1-20.79 2.47-8.2 9.56-14.04 16.54-19.02 6.97-4.98 15.7-9.1 19.75-16.64 4.51-8.42 9.2-16.84 15.45-24.06C76.31 8.53 84.31 2.53 93.56.17H69.98c-3.05 3.2-6.43 7.25-10.35 7.52z"
      />
      <path
        fill="#0097D6"
        stroke="#0097D6"
        d="M256.67 10.85c6.66 2.47 11.17 3.68 17.85 1.26 6.04-2.18 9.32-6.65 12.66-11.94h-50.56l1.26.7c6.2 3.44 12.13 7.51 18.79 9.98zM34.41 14.51c.55 5.16 3.6 9.9 3.53 15.1-.07 6.17-4.49 11.4-9.17 15.4-4.69 4.01-10.06 7.52-13.25 12.81-2.72 4.5-3.57 9.82-4.38 15.02-.88 5.59-1.06 11.03-3.53 16.14-1.73 3.57-4.24 6.19-6.9 8.85v12.9c0-.03 0-.06.02-.1 1.9-6.63 8.42-10.88 10.62-17.57 1.9-5.77 1.36-12.04 2.62-17.98 2.07-9.75 8.93-17.96 17.14-23.6 4.59-3.14 9.82-5.83 12.54-10.66 2.26-4 2.37-8.82 2.33-13.4-.04-4.6-.15-9.38 1.73-13.57a11.84 11.84 0 015.7-5.88c2.44-1.1 3.91-.1 6.24-.26 3.92-.27 7.3-4.32 10.35-7.5H37.78c-2.1 4.55-3.9 9.35-3.37 14.3z"
      />
      <path
        fill="#0090D0"
        stroke="#0090D0"
        d="M222.77 1.27c8.4 2.55 15.1 9.2 21.22 15.2a467.7 467.7 0 0022.56 20.66c2.49 2.11 5.43 4.37 8.67 3.93 2.89-.38 5.1-2.87 6.2-5.57 1.08-2.7 1.3-5.65 1.85-8.51 2.06-10.87 7.17-18.07 12.76-26.81h-8.85c-3.34 5.3-6.62 9.76-12.66 11.94-6.68 2.42-11.19 1.2-17.85-1.26C250 8.39 244.08 4.3 237.87.87L236.63.2h-18.45c1.56.27 3.09.64 4.6 1.08z"
      />
      <path
        fill="#008CCB"
        stroke="#008CCB"
        d="M281.41 35.49c-1.09 2.7-3.3 5.19-6.19 5.57-3.24.44-6.18-1.8-8.67-3.93a472 472 0 01-22.56-20.67c-6.11-5.98-12.82-12.64-21.22-15.19-1.5-.45-3.04-.82-4.59-1.1H93.56c-9.25 2.36-17.26 8.37-23.5 15.58C63.8 22.96 59.11 31.4 54.6 39.81c-4.04 7.55-12.79 11.66-19.75 16.64-6.97 4.97-14.06 10.83-16.54 19.02-2.03 6.73-.66 14.2-3.1 20.79-1.76 4.82-5.46 8.76-7.32 13.56-3.85 9.94.84 21.1 6.77 29.94 9.37 13.97 21.88 25.5 34.26 36.88 11.62 10.68 20.7 25.05 34.86 32.04 1.41.7 2.74 1.3 4.03 1.85h27.84c-2.47-.72-4.9-1.52-7.34-2.3-8.6-2.8-16.7-4.81-24-10.15-7.7-5.64-14.77-12.1-21.82-18.55-13.88-12.67-26.95-25.5-38.79-40.1a31.44 31.44 0 01-6.57-24.14c.84-5.62 3.2-10.88 5.54-16.06l8.08-17.92c1.94-4.3 3.92-8.67 6.92-12.3 5.8-7 14.56-10.52 20.85-16.98 3.42-3.5 6.14-7.56 9.25-11.35 3.3-4.02 6.8-7.88 10.48-11.58 4.26-4.24 8.8-8.3 14.01-11.3 5.22-2.99 10.03-3.43 15.95-4.5a805.15 805.15 0 0148.06-7.18c6.7-.8 13.45-1.52 20.21-1.62 12.97-.19 26.8 0 39.33 3.29 6.22 1.63 11.2 6.14 16.1 10.32 8.17 7 16.84 13.55 23.69 21.84 13.2 15.99 18.57 37.46 17.85 58.18a75.46 75.46 0 01-10.17 35.58c-6.35 11.1-14.82 20.86-23.92 29.85-7.18 7.08-14.84 13.71-21.88 20.95-5.56 5.73-11.24 11.55-18.24 15.42-5.35 2.97-11.29 4.7-16.96 7.02-2.76 1.13-5.47 2.4-8.2 3.58h24.35l1.06-.68c3.4-2.24 6.35-4.9 9.53-7.4 3.38-2.69 7.24-4.68 10.35-7.71 2.47-2.4 4.38-5.3 6.6-7.93 4.82-5.67 10.99-10 16.5-14.98 17.23-15.54 27.97-37.25 45.15-52.85a38.54 38.54 0 0110.04-6.53v-56.3c-.57-.75-1.1-1.54-1.58-2.36-5.6-9.97-5.29-22.35-2.11-33.34a89.79 89.79 0 013.73-10.36V.17h-1.65c-5.61 8.74-10.72 15.94-12.78 26.81-.55 2.86-.77 5.81-1.86 8.5z"
      />
      <path
        fill="#00A8E7"
        stroke="#00A8E7"
        d="M16.97 45.64c3.58-3.81 7.89-7.35 9.45-12.34 1.93-6.16-.89-12.8-4.4-18.2-3.05-4.68-7.26-9.52-9.26-14.93H1.43a62.18 62.18 0 009.28 16.18c2.18 2.75 4.68 5.46 5.47 8.88 1.3 5.6-3.35 10.45-5.79 15.66C7.36 47.37 4.91 51.67.7 56.13V83.8c1.67-4.37 2.24-9.34 3.3-13.96a53.31 53.31 0 0112.98-24.2z"
      />
      <path
        fill="#0084C3"
        stroke="#0084C3"
        d="M182.29 206.95c5.67-2.32 11.6-4.05 16.96-7.02 7-3.88 12.68-9.7 18.25-15.42 7.02-7.24 14.69-13.87 21.87-20.95 9.09-8.99 17.56-18.75 23.9-29.85a75.46 75.46 0 0010.17-35.58c.7-20.72-4.64-42.19-17.85-58.18-6.85-8.3-15.52-14.84-23.7-21.84-4.88-4.18-9.87-8.69-16.08-10.32-12.54-3.3-26.36-3.48-39.33-3.3-6.76.1-13.5.83-20.22 1.63a805.03 805.03 0 00-48.05 7.19c-5.92 1.06-10.73 1.5-15.95 4.5-5.21 3-9.75 7.06-14 11.3a159.33 159.33 0 00-10.5 11.57c-3.1 3.8-5.82 7.85-9.24 11.36C52.23 58.49 43.46 62 37.67 69c-3 3.64-4.98 8-6.92 12.3l-8.07 17.92c-2.33 5.18-4.7 10.44-5.54 16.06a31.43 31.43 0 006.56 24.14c11.84 14.6 24.91 27.43 38.78 40.1 7.05 6.45 14.11 12.91 21.83 18.55 7.3 5.34 15.4 7.35 23.99 10.14 2.44.8 4.88 1.59 7.34 2.31h58.45c2.73-1.16 5.44-2.46 8.2-3.58zm-33.1-1.92c-5 .23-10.05.68-15.06.68-4.1.19-8.27.35-12.25-.65-6.96-1.76-12.83-5.38-19.5-8.07-5.14-2.07-10.35-4.18-14.79-7.5-5.2-3.87-9.1-9.24-13.83-13.71-5.23-4.96-11.42-8.77-17.2-13.09-9.17-6.87-18.56-14.82-24.34-24.87-3.42-5.97-5.53-12.8-5.24-19.66.38-9.12 4.87-17.52 9.65-25.3a264.76 264.76 0 0135.23-45.42c3.4-3.53 7.09-8.28 11.26-10.85 1.62-1 3.53-1.5 5.2-2.5 2.38-1.4 4.79-2.79 7.22-4.11 4.86-2.68 9.9-5.03 15.06-7.04 13.25-5.03 27.09-5.24 40.97-6.54a566.07 566.07 0 0026.33-3.13c2.59-.35 5.6-1.3 8.2-1.1 10.33.87 21.01.28 30.82 4.33 4.97 2.04 9.13 5.64 13.12 9.22 8.82 7.93 17.39 16.32 23.78 26.3a70.47 70.47 0 013.61 6.33c2.4 4.7 4.15 9.7 5.22 14.88 1 5.06.7 10.16 1.46 15.2a68.72 68.72 0 01-3.01 32.28c-6.08 17.58-18 32.5-31.46 44.96l-5.58 5.16c-3.76 3.47-7.58 7-12.17 9.26-2.11 1.06-4.36 1.77-6.6 2.51-1.84.62-3.37 1.88-4.94 3.01-3.67 2.63-7.11 5.55-10.9 7.99-7.57 4.86-16.77 8.33-25.45 10.58-4.54 1.19-10.12.64-14.81.85z"
      />
      <path
        fill="#007DBD"
        stroke="#007DBD"
        d="M189.43 193.58c3.8-2.44 7.23-5.36 10.9-7.98 1.59-1.14 3.12-2.4 4.94-3.02 2.27-.73 4.5-1.44 6.62-2.5 4.59-2.27 8.41-5.79 12.17-9.26l5.58-5.16c13.48-12.45 25.4-27.38 31.46-44.96a68.74 68.74 0 003.01-32.28c-.74-5.04-.46-10.14-1.46-15.2a58.95 58.95 0 00-5.22-14.87 70.62 70.62 0 00-3.6-6.32c-6.4-9.99-14.97-18.38-23.8-26.31-3.98-3.62-8.14-7.2-13.1-9.24-9.82-4.05-20.5-3.46-30.83-4.32-2.6-.22-5.61.7-8.2 1.09a575.65 575.65 0 01-26.33 3.15c-13.88 1.3-27.72 1.51-40.97 6.55-5.17 2-10.2 4.36-15.06 7.04-2.43 1.32-4.84 2.7-7.23 4.11-1.67 1-3.57 1.5-5.19 2.5-4.17 2.57-7.87 7.34-11.26 10.84a264.77 264.77 0 00-35.24 45.42c-4.78 7.76-9.26 16.17-9.65 25.29-.29 6.87 1.83 13.7 5.25 19.66 5.78 10.06 15.17 17.99 24.34 24.87 5.77 4.32 11.96 8.13 17.2 13.1 4.72 4.46 8.62 9.82 13.83 13.71 4.44 3.32 9.64 5.43 14.79 7.5 6.66 2.68 12.53 6.29 19.49 8.06 3.98 1.02 8.15.84 12.25.65 5.01 0 10.06-.45 15.06-.67 4.7-.22 10.28.33 14.8-.85 8.68-2.27 17.88-5.75 25.45-10.6zm-43.75 3.7c-10.36 1.35-20.78-1.26-30.9-3.85-3.81-.97-7.67-1.96-11.18-3.74a53.55 53.55 0 01-6.9-4.41c-2.83-2.02-5.73-3.97-8.5-6.06-2.36-1.76-4.36-4.16-6.54-6.19-4.65-4.3-9.6-8.26-14.82-11.84-8.18-5.6-17.2-10.46-23.32-18.26a36.13 36.13 0 01-6.73-29.78c2.19-9.75 8.32-18.07 14.3-26.08 7.85-10.52 15.74-21.08 24.98-30.4C83 49.64 90.74 43.32 99.4 38.56c4.3-2.38 8.84-4.07 13.24-6.2 4.56-2.2 9.34-4.05 14.35-4.93 6.16-1.09 12.47-.71 18.73-.77 14.66-.18 29.82-3.08 44.37-4.9 10.03-1.26 20.11 2.47 28.69 7.78a79.56 79.56 0 0129.95 33.7 79.68 79.68 0 017.4 31.22c.15 5 .81 9.62-.05 14.65a58.9 58.9 0 01-4.96 15.23c-3.65 7.69-8.53 14.71-13.39 21.7-2.62 3.77-5.25 7.55-8.4 10.9a55.51 55.51 0 01-15.78 11.5c-2.67 1.34-4.93 3.03-7.56 4.4-3.11 1.64-6.29 3.18-9.3 5-4.74 2.9-9 6.54-13.73 9.45-10.94 6.75-24.54 8.3-37.28 9.98h0z"
      />
      <path
        fill="#0075B6"
        stroke="#0075B6"
        d="M196.7 177.87c3-1.84 6.18-3.37 9.3-5 2.62-1.38 4.88-3.06 7.56-4.41a56.21 56.21 0 0015.78-11.52c3.13-3.34 5.77-7.12 8.4-10.89 4.85-6.99 9.73-14.01 13.38-21.7a58.86 58.86 0 004.98-15.22c.86-5.03.2-9.64.05-14.65a79.69 79.69 0 00-9.13-34.7 79.57 79.57 0 00-28.23-30.22c-8.58-5.33-18.66-9.04-28.68-7.79-14.55 1.82-29.72 4.73-44.38 4.9-6.25.08-12.57-.31-18.73.77-5 .88-9.78 2.72-14.35 4.94-4.4 2.12-8.94 3.82-13.23 6.19-8.66 4.77-16.4 11.1-23.34 18.1C66.85 66 58.96 76.56 51.1 87.07c-6 8.01-12.1 16.34-14.3 26.09a36.14 36.14 0 006.75 29.78c6.13 7.8 15.14 12.65 23.32 18.26a129.77 129.77 0 0114.81 11.84c2.2 2.03 4.2 4.41 6.55 6.2 2.77 2.08 5.66 4.03 8.49 6.04a55.1 55.1 0 006.91 4.42c3.53 1.76 7.37 2.77 11.18 3.74 10.12 2.6 20.54 5.2 30.9 3.85 12.74-1.67 26.34-3.23 37.28-9.97 4.7-2.92 8.96-6.56 13.7-9.45zm-58.34 11.26c-5.02.2-10.1.07-14.94-1.24-9.05-2.44-17.64-8.6-24.34-14.95-7.5-7.11-15.23-14.03-23.73-19.93-6.72-4.67-14.04-8.8-19.3-15.1-7.02-8.4-9.49-20.72-5.13-30.76C56.08 95.27 67.7 88.6 74.26 77.44 80.51 66.8 87.95 56.2 98.84 50.42c4.93-2.62 10.37-4.14 15.52-6.3 4.8-2.02 9.38-4.62 14.42-5.9 5.03-1.29 10.32-1.2 15.52-1.1 6.19.12 11.08 1.46 17.16.3 8.34-1.59 16.72-3.18 25.2-3.48 8.48-.3 17.15.78 24.87 4.3 10.71 4.9 18.94 14.28 24.2 24.82a75.46 75.46 0 013.13 7.2c1.88 5.13 3.36 10.4 4.42 15.76 1.03 5.05 2.77 10.1 3.18 15.24.82 10.38-3.22 20.38-8.55 29.07-4.27 6.96-9.09 13.68-15.14 19.16-8.87 8.04-19.88 13.06-29.84 19.5-8.22 5.33-15.8 11.14-25.12 14.62-9.4 3.5-19.45 5.15-29.45 5.52z"
      />
      <path
        fill="#006299"
        stroke="#006299"
        d="M222.8 149.5c6.04-5.5 10.86-12.2 15.13-19.17 5.34-8.69 9.37-18.7 8.55-29.07-.41-5.15-2.16-10.19-3.18-15.24a103.58 103.58 0 00-4.42-15.76 75.92 75.92 0 00-3.12-7.2c-5.26-10.54-13.48-19.92-24.2-24.82-7.72-3.52-16.38-4.58-24.87-4.3-8.48.29-16.85 1.9-25.2 3.48-6.08 1.16-10.97-.18-17.16-.3-5.2-.1-10.49-.18-15.52 1.1-5.04 1.28-9.63 3.88-14.42 5.9-5.15 2.16-10.58 3.68-15.52 6.3-10.9 5.79-18.35 16.38-24.58 27.02-6.56 11.16-18.18 17.83-23.34 29.71-4.35 10.04-1.88 22.37 5.14 30.77 5.25 6.29 12.57 10.41 19.3 15.09 8.5 5.9 16.23 12.82 23.73 19.93 6.7 6.35 15.28 12.51 24.34 14.95 4.85 1.31 9.93 1.41 14.94 1.24 10-.37 20.06-2.02 29.46-5.52 9.32-3.48 16.9-9.3 25.13-14.62 9.93-6.46 20.94-11.46 29.8-19.5z"
      />
    </g>
  </svg>
);

export default ProfileBg;
