import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CenteredSpinner } from "../../Spinner";
import { getDocumentIcon } from "../utils";
import styles from "./CorrespondenceAttachmentsView.module.css";

const DocumentIcon = ({ extension }) => {
  const Icon = getDocumentIcon(extension);
  return <Icon className={styles.documentIcon} width="17" height="17" />;
};

export const CorrespondenceAttachmentsView = ({
  attachments,
  noAttachmentsTitle,
  isWidget,
  onItemClick,
}) => {
  const onClick = useCallback(
    (e, attachment) => {
      if (onItemClick) {
        onItemClick(e, attachment);
      }
    },
    [onItemClick]
  );

  if (!attachments) {
    return (
      <div className={styles.loaderContainer}>
        <CenteredSpinner />
      </div>
    );
  }

  if (!attachments.length) {
    return <div className={styles.noAttachments}>{noAttachmentsTitle}</div>;
  }

  const listClasses = classNames(
    {
      [styles.listWidget]: isWidget,
    },
    styles.list
  );

  return (
    <div className={listClasses}>
      {attachments.map(attachment => (
        <div
          key={attachment.attachmentID}
          className={styles.attachment}
          role="button"
          onClick={e => onClick(e, attachment)}
        >
          <DocumentIcon extension={attachment.extension} />
          <span className={styles.title}>{attachment.title}</span>
        </div>
      ))}
    </div>
  );
};

CorrespondenceAttachmentsView.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      attachmentID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      extension: PropTypes.string,
    })
  ),
  noAttachmentsTitle: PropTypes.string.isRequired,
  isWidget: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func,
};
