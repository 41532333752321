import React from "react";
import { CollapseArrowSvg } from "../styles/icons";

const SeeMoreArrow = props => (
  <CollapseArrowSvg
    {...props}
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.88756 6L0.256282 1.46323C-0.0854268 1.1285 -0.0854268 0.585786 0.256282 0.251051C0.597991 -0.083684 1.15201 -0.083684 1.49372 0.251051L6.74372 5.39391C7.08543 5.72864 7.08543 6.27136 6.74372 6.60609L1.49372 11.7489C1.15201 12.0837 0.597991 12.0837 0.256282 11.7489C-0.0854268 11.4142 -0.0854268 10.8715 0.256282 10.5368L4.88756 6Z"
      fill="#1859A9"
    />
  </CollapseArrowSvg>
);

export default SeeMoreArrow;
