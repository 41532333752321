export const LANGUAGE_FIELD = "languageTxt";
export const READING_LEVEL_FIELD = "readingLevelTxt";
export const WRITING_LEVEL_FIELD = "writingLevelTxt";
export const SPEAKING_LEVEL_FIELD = "speakingLevelTxt";

export const FIELDS_TO_DISPLAY = [
  READING_LEVEL_FIELD,
  WRITING_LEVEL_FIELD,
  SPEAKING_LEVEL_FIELD,
];

export const LABELS = {
  [LANGUAGE_FIELD]: "Language",
  [READING_LEVEL_FIELD]: "Reading Level",
  [WRITING_LEVEL_FIELD]: "Writing Level",
  [SPEAKING_LEVEL_FIELD]: "Speaking Level",
};
