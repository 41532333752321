import React from "react";
import PropTypes from "prop-types";
import { TITLES } from "../../constants";
import { MY_PROFILE_PAGE_SECTION } from "../../auto-test-constants";
import { TitleText } from "../styles/informationCard";
import {
  Card,
  LanguageProficiencyCardTitle,
} from "./LanguageProficiencyLevelsStyles";
import { LanguageProficiencyLevelsSkeleton } from "./LanguageProficiencyLevelsSkeleton";
import { useScrollIntoView } from "../../hooks";
import { LanguageProficiencyTableView } from "./LanguageProficiencyTableView";
import { LanguageProficiencyListView } from "./LanguageProficiencyListView";

LanguageProficiencyLevels.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  languageProficiencyItems: PropTypes.arrayOf(
    PropTypes.shape({
      languageTxt: PropTypes.string.isRequired,
      writingLevelTxt: PropTypes.string.isRequired,
      readingLevelTxt: PropTypes.string.isRequired,
      speakingLevelTxt: PropTypes.string.isRequired,
    })
  ),
};

export function LanguageProficiencyLevels({
  isLoading,
  languageProficiencyItems = [],
  defaultAmountOfEntries,
}) {
  const { nodeRef, onScroll } = useScrollIntoView();

  const dataAttrValue = `${MY_PROFILE_PAGE_SECTION} ${TITLES.LANGUAGE_PROFICIENCY_LEVEL.replace(
    / /g,
    "-"
  )}`;

  if (!isLoading && !languageProficiencyItems?.length) {
    return null;
  }

  return (
    <Card data-attributes={dataAttrValue} ref={nodeRef}>
      {isLoading ? (
        <LanguageProficiencyLevelsSkeleton />
      ) : (
        <React.Fragment>
          <LanguageProficiencyCardTitle>
            <TitleText>{TITLES.LANGUAGE_PROFICIENCY_LEVEL}</TitleText>
          </LanguageProficiencyCardTitle>
          <LanguageProficiencyTableView
            languageProficiencyItems={languageProficiencyItems}
            defaultAmountOfEntries={defaultAmountOfEntries}
            onScroll={onScroll}
          />
          <LanguageProficiencyListView
            languageProficiencyItems={languageProficiencyItems}
            defaultAmountOfEntries={defaultAmountOfEntries}
            onScroll={onScroll}
          />
        </React.Fragment>
      )}
    </Card>
  );
}
