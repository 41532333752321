import React from "react";

export const JSPIcon = props => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="7" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M7 27h30v7c0 1.1046-.8954 2-2 2H9c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M28 27V0l7-3e-7C36.1046-3.6e-7 37 .89543 37 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="27" height="19.5" rx="2" fill="#303030" />
    <path
      d="M5.49 20.45c.02667.22.06667.42.12.6.06.1733.14333.3233.25.45.11333.12.25333.2167.42.29.17333.0667.38667.1.64.1.48667 0 .83333-.14 1.04-.42.20667-.28.31-.68.31-1.2v-4.66h1.21v4.74c0 .8533-.23 1.4967-.69 1.93-.45333.4333-1.07.65-1.85.65-.82 0-1.45333-.2-1.9-.6-.44667-.4-.7-1.0267-.76-1.88h1.21Zm6.2319.12c.0533.4667.22.8067.5 1.02.2866.2133.71.32 1.27.32.2733 0 .5033-.03.69-.09.1866-.06.3366-.14.45-.24.1133-.1.1933-.2167.24-.35.0466-.14.07-.2867.07-.44 0-.1133-.0167-.2233-.05-.33-.0334-.1133-.0934-.2167-.18-.31-.0867-.0933-.2034-.1733-.35-.24-.1467-.0733-.3334-.13-.56-.17l-.91-.17c-.36-.0667-.68-.15-.96-.25-.28-.1-.5167-.23-.71-.39-.1867-.1667-.33-.3667-.43-.6-.0934-.24-.14-.53-.14-.87 0-.34.0666-.6367.2-.89.14-.2533.33-.4633.57-.63.24-.1733.5233-.3.85-.38.3333-.0867.6933-.13 1.08-.13.4933 0 .9066.0567 1.24.17.3333.1133.6033.2633.81.45.2066.18.3633.39.47.63.1066.24.18.49.22.75l-1.14.18c-.0934-.4-.2567-.6967-.49-.89-.2267-.1933-.59-.29-1.09-.29-.2867 0-.5267.0267-.72.08-.1867.0467-.34.1167-.46.21-.1134.0867-.1967.19-.25.31-.0467.1133-.07.2333-.07.36 0 .3133.0833.5467.25.7.1733.1533.4733.2733.9.36l.99.19c.7533.14 1.3066.38 1.66.72.3533.3333.53.8167.53 1.45 0 .3-.0567.58-.17.84-.1067.2533-.2734.4767-.5.67-.2267.1867-.51.3333-.85.44-.3334.1133-.7234.17-1.17.17-.9134 0-1.63-.19-2.15-.57-.52-.3867-.8067-.9833-.86-1.79h1.22Zm5.6455-4.96h2.47c.54 0 .9834.06 1.33.18.3534.12.63.28.83.48.2.2.34.4333.42.7.08.26.12.5333.12.82 0 .3067-.04.6-.12.88-.08.28-.2233.5267-.43.74-.2.2067-.4733.3733-.82.5-.3466.12-.7833.18-1.31.18h-1.3v2.66h-1.19v-7.14Zm2.46 3.47c.3067 0 .5567-.03.75-.09.2-.06.3567-.1433.47-.25.1134-.1133.19-.2467.23-.4.0467-.1533.07-.3267.07-.52 0-.2-.0233-.3733-.07-.52-.0466-.1533-.13-.28-.25-.38-.1133-.1-.27-.1767-.47-.23-.1933-.0533-.44-.08-.74-.08h-1.26v2.47h1.27Z"
      fill="#fff"
    />
  </svg>
);
