import React from "react";
import { createPortal } from "react-dom";

export class Modal extends React.Component {
  modal = document.createElement("div");
  appRoot = document.querySelector(".modal-root") || document.body;

  componentDidMount() {
    if (this.props.className) {
      this.modal.classList.add(this.props.className);
    }

    if (this.appRoot === document.body) {
      const { width, height } = document.body.getBoundingClientRect();

      this.modal.style.position = "absolute";
      this.modal.style.left = "0";
      this.modal.style.top = "0";
      this.modal.style.width = `${width}px`;
      this.modal.style.height = `${height}px`;
    }

    this.appRoot.appendChild(this.modal);
  }

  componentWillUnmount() {
    this.appRoot.removeChild(this.modal);
  }

  render() {
    return createPortal(this.props.children, this.modal);
  }
}
