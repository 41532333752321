import { useContext, useEffect, useState } from "react";
import { PROFILE_ERROR_TYPES } from "../constants";
import { ProfileContext } from "../context/ProfileProvider";

export const useActivity = () => {
  const [isLoading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState(null);
  const { getOnlineActivities, onError, user } = useContext(ProfileContext);
  useEffect(() => {
    setLoading(true);
    getOnlineActivities()
      .then(res => {
        if (res?.rows?.[0]) {
          setActivityData(res.rows[0]);
        } else {
          setActivityData(null);
        }
      })
      .catch(error => {
        setActivityData(null);
        onError(PROFILE_ERROR_TYPES.ActivityError, error);
      })
      .finally(() => setLoading(false));
  }, [setActivityData, setLoading, user, onError]);
  return [activityData, isLoading];
};
