import React from "react";

const DoNotDisturb = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8" fill="#A9343A" stroke="white" strokeWidth="2" />
    <path
      d="M11.2148 8.21484C11.3242 8.21484 11.4258 8.23568 11.5195 8.27734C11.6159 8.31901 11.6992 8.375 11.7695 8.44531C11.8398 8.51562 11.8958 8.59896 11.9375 8.69531C11.9792 8.78906 12 8.89062 12 9C12 9.10938 11.9792 9.21224 11.9375 9.30859C11.8958 9.40234 11.8398 9.48438 11.7695 9.55469C11.6992 9.625 11.6159 9.68099 11.5195 9.72266C11.4258 9.76432 11.3242 9.78516 11.2148 9.78516H6.54297C6.43359 9.78516 6.33073 9.76432 6.23438 9.72266C6.14062 9.68099 6.05859 9.625 5.98828 9.55469C5.91797 9.48438 5.86198 9.40234 5.82031 9.30859C5.78125 9.21224 5.76172 9.10938 5.76172 9C5.76172 8.89062 5.78125 8.78906 5.82031 8.69531C5.86198 8.59896 5.91797 8.51562 5.98828 8.44531C6.05859 8.375 6.14062 8.31901 6.23438 8.27734C6.33073 8.23568 6.43359 8.21484 6.54297 8.21484H11.2148Z"
      fill="white"
    />
  </svg>
);

export default DoNotDisturb;
