import React from "react";

const Office = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.83325 14.8801V3.84008C4.83299 3.66355 4.93394 3.50122 5.09592 3.41768L10.5959 0.576076C10.7506 0.495971 10.9378 0.499754 11.0889 0.58604C11.24 0.672326 11.3328 0.828437 11.3333 0.997196V14.8801"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 14.8799H0.5"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 5.28027H8.33325"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3332 7.2002H7.33325"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33325 14.88H7.33325V12.96H9.33325V14.88Z"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Office;
