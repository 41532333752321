import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  OrgChartWrapper,
  Subtitle,
  SubtitleWrapper,
  OrgChartContainer,
} from "./styles/reportingCard";
import { SkeletonTextElement } from "../utils/Loaders/SkeletonPulse";
import { REPORTING_TO_TITLES } from "../constants";
import { noop } from "../utils/utils";
import { OrgChart, Pdf } from "./icons";
import ReportingToCard from "./ReportingTo";
import MyTeamCard from "./MyTeam";
import { MY_PROFILE_PAGE_SECTION_REPORTINGTO, REPORTINGTO_ORG_CHART_LINK, MY_PROFILE_PAGE_TEAM_BALACE } from "../auto-test-constants";

function ReportingAndTeamCard({
  reportingToMemberData,
  team,
  isLoading,
  isMobile,
  onTeamMemberClick,
  onOrgChartClick,
  onReportingToMemberClick,
  managerStatus,
  teamStatuses,
  teamBalanceDownloadUrl,
  onTeamBalanceDownloadClick,
}) {
  const onOrgChartClickHandler = () => {
    if (onOrgChartClick && reportingToMemberData?.email) {
      onOrgChartClick(reportingToMemberData.email);
    }
  };

  const [showAll, setShowAll] = useState(false);

  const onReportingToMemberClickHandler = member => {
    setShowAll(false);
    onReportingToMemberClick(member);
  };

  const onTeamMemberClickHandler = member => {
    setShowAll(false);
    onTeamMemberClick(member);
  };

  const isTeamListAvailable = !!(Array.isArray(team) && team.length);

  return (
    <Card data-attributes={MY_PROFILE_PAGE_SECTION_REPORTINGTO}>
      <OrgChartWrapper>
        {isLoading ? (
          <SkeletonTextElement width={143} height={16} />
        ) : (
          <>
            {!!teamBalanceDownloadUrl && (
              <SubtitleWrapper onClick={onTeamBalanceDownloadClick}>
                {!isMobile && (
                  <Subtitle data-attributes={MY_PROFILE_PAGE_TEAM_BALACE}>{REPORTING_TO_TITLES.teamBalance}</Subtitle>
                )}
                <Pdf height={20} width={20} />
              </SubtitleWrapper>
            )}
            <OrgChartContainer onClick={onOrgChartClickHandler}>
              {!isMobile && <Subtitle data-attributes={REPORTINGTO_ORG_CHART_LINK}>{REPORTING_TO_TITLES.orgChart}</Subtitle>}
              <OrgChart />
            </OrgChartContainer>
          </>
        )}
      </OrgChartWrapper>
      <ReportingToCard
        reportingToMemberData={reportingToMemberData}
        isLoading={isLoading}
        onReportingToMemberClick={onReportingToMemberClickHandler}
        managerStatus={managerStatus}
      />
      {isTeamListAvailable && (
        <MyTeamCard
          team={team}
          isLoading={isLoading}
          isMobile={isMobile}
          onTeamMemberClick={onTeamMemberClickHandler}
          teamStatuses={teamStatuses}
          showAll={showAll}
          setShowAll={setShowAll}
        />
      )}
    </Card>
  );
}

ReportingAndTeamCard.propTypes = {
  managerFullName: PropTypes.string,
  managerDesignation: PropTypes.string,
  managerUserId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onOrgChartClick: PropTypes.func,
  onReportingToMemberClick: PropTypes.func,
  onTeamMemberClick: PropTypes.func,
  loadAvatarCb: PropTypes.func,
  managerStatus: PropTypes.string.isRequired,
  teamStatuses: PropTypes.object,
  teamBalanceDownloadUrl: PropTypes.string,
  onTeamBalanceDownloadClick: PropTypes.func.isRequired,
};

ReportingAndTeamCard.defaultProps = {
  managerFullName: "",
  managerDesignation: "",
  managerUserId: "",
  onOrgChartClick: noop,
  onReportingToMemberClick: undefined,
  onTeamMemberClick: noop,
  teamStatuses: {},
};

export default ReportingAndTeamCard;
