import React from "react";
import PropTypes from "prop-types";

const AvatarBlue = ({ size }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#F9F9F9" />
    <path
      d="M7 31.0536C7 25.8922 12.3499 22.4692 17.0362 24.6321C18.9168 25.5 21.0832 25.5 22.9638 24.6321C27.6501 22.4692 33 25.8922 33 31.0536V31.3542C33 31.7109 32.7109 32 32.3542 32H7.64576C7.28912 32 7 31.7109 7 31.3542V31.0536Z"
      fill="url(#paint0_linear_2629_50565)"
    />
    <g filter="url(#filter0_f_2629_50565)">
      <rect
        x="17"
        y="18"
        width="6"
        height="5"
        rx="2.5"
        fill="url(#paint1_linear_2629_50565)"
      />
    </g>
    <rect
      x="14"
      y="7"
      width="12"
      height="17"
      rx="6"
      fill="#1859A9"
      fillOpacity="0.15"
    />
    <rect
      x="14.1"
      y="7.1"
      width="11.8"
      height="16.8"
      rx="5.9"
      stroke="white"
      strokeOpacity="0.1"
      strokeWidth="0.2"
    />
    <defs>
      <filter
        id="filter0_f_2629_50565"
        x="9"
        y="10"
        width="22"
        height="21"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="4"
          result="effect1_foregroundBlur_2629_50565"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2629_50565"
        x1="19.4583"
        y1="15"
        x2="24.2579"
        y2="39.5571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1859A9" />
        <stop offset="1" stopColor="#1E3B6B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2629_50565"
        x1="19.875"
        y1="15.9167"
        x2="23.2086"
        y2="25.3633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1859A9" />
        <stop offset="1" stopColor="#1E3B6B" />
      </linearGradient>
    </defs>
  </svg>
);

AvatarBlue.propTypes = {
  size: PropTypes.number,
};

AvatarBlue.defaultProps = {
  size: 72,
};

export default AvatarBlue;
