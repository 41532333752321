import React from "react";
import {
  InfoItem,
  InfoList,
  InfoListTitle,
  InfoListWrapper,
  InfoTitle,
  InfoValue,
} from "../styles/informationCard";
import PropTypes from "prop-types";
import { FIELDS_TO_DISPLAY, LABELS } from "./constants";

LanguageProficiencyCard.propTypes = {
  languageTxt: PropTypes.string.isRequired,
  writingLevelTxt: PropTypes.string.isRequired,
  readingLevelTxt: PropTypes.string.isRequired,
  speakingLevelTxt: PropTypes.string.isRequired,
};

export function LanguageProficiencyCard(props) {
  return (
    <InfoListWrapper>
      <InfoList>
        <InfoItem>
          <InfoListTitle>{props.languageTxt}</InfoListTitle>
        </InfoItem>
        {FIELDS_TO_DISPLAY.map(field => (
          <InfoItem key={field}>
            <InfoTitle>{LABELS[field]}</InfoTitle>
            <InfoValue>{props[field] || "-"}</InfoValue>
          </InfoItem>
        ))}
      </InfoList>
    </InfoListWrapper>
  );
}
