import React, { memo, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import {
  ContactDetails,
  ContactElement,
  ContactText,
  ArrowTip,
  StyledLink,
} from "./styles/contactDetailsTooltip";
import { OutsideClick } from "../utils/OutsideClick";
import { EmptyCard } from "../utils/EmptyCard/index";
import { Location, Office, Mobile, Phone, Email } from "./icons";
import { noop } from "../utils/utils";

function ContactDetailsTooltip({
  location,
  email,
  telephoneExtension,
  mobileNo,
  directorate,
  isOpen,
  updateIsOpenValue,
  hideTooltip,
  isClickedOutside,
  setupRef,
  onMailClick,
}) {
  useEffect(() => updateIsOpenValue(isOpen), [isOpen]);

  if (isClickedOutside && isOpen) {
    hideTooltip();
  }

  const isEmpty = [
    location,
    email,
    mobileNo,
    telephoneExtension,
    directorate,
  ].every(item => !item);

  return (
    <ContactDetails isOpen={isOpen} ref={setupRef}>
      <ArrowTip />
      {isEmpty ? (
        <EmptyCard paddingTop={54} paddingBottom={54} />
      ) : (
        <>
          {!!location && (
            <ContactElement>
              <Location />
              <ContactText>{location}</ContactText>
            </ContactElement>
          )}
          {!!email && (
            <ContactElement>
              <Email />
              <StyledLink onClick={() => onMailClick(email)}>
                {email}
              </StyledLink>
            </ContactElement>
          )}
          {!!telephoneExtension && (
            <ContactElement>
              <Phone />
              <StyledLink as="a" href={`tel:${telephoneExtension}`}>
                {telephoneExtension}
              </StyledLink>
            </ContactElement>
          )}
          {!!mobileNo && (
            <ContactElement>
              <Mobile />
              <StyledLink as="a" href={`tel:${mobileNo}`}>
                {mobileNo}
              </StyledLink>
            </ContactElement>
          )}
          {!!directorate && (
            <ContactElement>
              <Office />
              <ContactText>{directorate}</ContactText>
            </ContactElement>
          )}
        </>
      )}
    </ContactDetails>
  );
}

ContactDetailsTooltip.propTypes = {
  location: PropTypes.string,
  email: PropTypes.string,
  telephoneExtension: PropTypes.string,
  mobileNo: PropTypes.string,
  directorate: PropTypes.string,
  isOpen: PropTypes.bool,
  updateIsOpenValue: PropTypes.func,
  hideTooltip: PropTypes.func.isRequired,
  isClickedOutside: PropTypes.bool,
  setupRef: PropTypes.func,
  onMailClick: PropTypes.func,
};

ContactDetailsTooltip.defaultProps = {
  location: "",
  email: "",
  telephoneExtension: "",
  mobileNo: "",
  directorate: "",
  isOpen: false,
  updateIsOpenValue: noop,
  setupRef: noop,
  onMailClick: noop,
  isClickedOutside: false,
};

export default OutsideClick(memo(ContactDetailsTooltip));
