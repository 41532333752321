import React from "react";
import PropTypes from "prop-types";
import InformationCard from "./InformationCard";
import { TITLES, EMPLOYEE_INFORMATION_TITLES } from "../constants";
import { EmployeeInfo } from "./icons";
import { getYearsMonthsDurationText, getTimeFromNow } from "../utils/utils";

export function EmployeeInformation({ isLoading, title, ...props }) {
  const {
    lastPromotionDate,
    lastSalaryChangeDate,
    personalGrade,
    jobGrade,
    employmentType,
    currentPositionYears,
    currentPositionMonths,
    totalServiceYears,
    totalServiceMonths,
    employmentSubGroup,
  } = props;

  const isEmpty = [
    personalGrade,
    jobGrade,
    lastPromotionDate,
    employmentType,
    currentPositionYears,
    currentPositionMonths,
    totalServiceYears,
    totalServiceMonths,
    lastSalaryChangeDate,
  ].every(item => !item);

  if (isEmpty) return null;

  const lastPromotion = getTimeFromNow(lastPromotionDate);
  const lastSalary = getTimeFromNow(lastSalaryChangeDate);
  const currentPositionTime = getYearsMonthsDurationText(
    currentPositionYears,
    currentPositionMonths
  );
  const totalServiceTime = getYearsMonthsDurationText(
    totalServiceYears,
    totalServiceMonths
  );

  const employeeInfoList = [
    {
      title: EMPLOYEE_INFORMATION_TITLES.grade,
      value: personalGrade,
    },
    {
      title: EMPLOYEE_INFORMATION_TITLES.lastPromotion,
      value: lastPromotion,
    },
    {
      title: EMPLOYEE_INFORMATION_TITLES.employmentType,
      value: employmentType,
    },
    {
      title: EMPLOYEE_INFORMATION_TITLES.employmentSubGroup,
      value: employmentSubGroup,
    },
    {
      title: EMPLOYEE_INFORMATION_TITLES.currentPosition,
      value: currentPositionTime,
    },
    {
      title: EMPLOYEE_INFORMATION_TITLES.totalService,
      value: totalServiceTime,
    },
    {
      title: EMPLOYEE_INFORMATION_TITLES.lastSalary,
      value: lastSalary,
    },
    {
      title: EMPLOYEE_INFORMATION_TITLES.jobGrade,
      value: jobGrade,
    },
  ];
  return (
    <InformationCard
      info={employeeInfoList}
      title={TITLES.EMPLOYEE_INFORMATION}
      icon={EmployeeInfo}
      isLoading={isLoading}
      isEmployeeInfo
    />
  );
}

EmployeeInformation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default EmployeeInformation;
