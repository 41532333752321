import {
  ACCXIcon,
  ASPXIcon,
  CSVIcon,
  DOCXIcon,
  EMLIcon,
  HTMIcon,
  HTMLIcon,
  JPGIcon,
  JSPIcon,
  MHTIcon,
  MSGIcon,
  ODPIcon,
  ODSIcon,
  ONEIcon,
  PDFIcon,
  PHPIcon,
  PNGIcon,
  PPTXIcon,
  PPUBIcon,
  PUBIcon,
  TXTIcon,
  UnknownIcon,
  URLIcon,
  VPXIcon,
  VSDIcon,
  VSSIcon,
  XLSIcon,
  XMLIcon,
  ZIPIcon,
} from "../../icons";

const documentExtension = {
  ACCX: "ACCX",
  ASPX: "ASPX",
  CSV: "CSV",
  DOC: "DOC",
  DOCX: "DOCX",
  DOCM: "DOCM",
  DOT: "DOT",
  DOTX: "DOTX",
  EML: "EML",
  HTM: "HTM",
  HTML: "HTML",
  JPG: "JPG",
  JSP: "JSP",
  MHT: "MHT",
  MSG: "MSG",
  ODS: "ODS",
  ODP: "ODP",
  ONE: "ONE",
  PDF: "PDF",
  PHP: "PHP",
  PNG: "PNG",
  PPT: "PPT",
  PPTS: "PPTS",
  PPTX: "PPTX",
  PPSX: "PPSX",
  PPTM: "PPTM",
  PPUB: "PPUB",
  PUB: "PUB",
  TXT: "TXT",
  VPX: "VPX",
  VSD: "VSD",
  VSDX: "VSDX",
  VSS: "VSS",
  XLS: "XLS",
  XLSX: "XLSX",
  XLSB: "XLSB",
  XLSM: "XLSM",
  XML: "XML",
  ZIP: "ZIP",
  URL: "URL",
};

const getDocumentIcon = extension => {
  const preparedExtension = extension.toUpperCase();
  switch (preparedExtension) {
    case documentExtension.PDF:
      return PDFIcon;
    case documentExtension.DOC:
    case documentExtension.DOCX:
    case documentExtension.DOCM:
    case documentExtension.DOT:
    case documentExtension.DOTX:
      return DOCXIcon;
    case documentExtension.PPT:
    case documentExtension.PPTS:
    case documentExtension.PPTX:
    case documentExtension.PPSX:
    case documentExtension.PPTM:
      return PPTXIcon;
    case documentExtension.XLS:
    case documentExtension.XLSX:
    case documentExtension.XLSB:
    case documentExtension.XLSM:
      return XLSIcon;
    case documentExtension.CSV:
      return CSVIcon;
    case documentExtension.VSD:
    case documentExtension.VSDX:
      return VSDIcon;
    case documentExtension.PPUB:
      return PPUBIcon;
    case documentExtension.PUB:
      return PUBIcon;
    case documentExtension.MSG:
      return MSGIcon;
    case documentExtension.ACCX:
      return ACCXIcon;
    case documentExtension.ASPX:
      return ASPXIcon;
    case documentExtension.EML:
      return EMLIcon;
    case documentExtension.HTM:
      return HTMIcon;
    case documentExtension.HTML:
      return HTMLIcon;
    case documentExtension.JPG:
      return JPGIcon;
    case documentExtension.JSP:
      return JSPIcon;
    case documentExtension.MHT:
      return MHTIcon;
    case documentExtension.ODS:
      return ODSIcon;
    case documentExtension.ODP:
      return ODPIcon;
    case documentExtension.ONE:
      return ONEIcon;
    case documentExtension.PHP:
      return PHPIcon;
    case documentExtension.PNG:
      return PNGIcon;
    case documentExtension.TXT:
      return TXTIcon;
    case documentExtension.VPX:
      return VPXIcon;
    case documentExtension.VSS:
      return VSSIcon;
    case documentExtension.XML:
      return XMLIcon;
    case documentExtension.ZIP:
      return ZIPIcon;
    case documentExtension.URL:
      return URLIcon;
    default:
      return UnknownIcon;
  }
};

export { getDocumentIcon };
