import React from "react";

export default function DotsIcon() {
  return (
    <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5" r="3" fill="#1859A9" />
      <circle cx="5" cy="5" r="4.5" stroke="#1859A9" />
    </svg>
  );
}
