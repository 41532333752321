import React from "react";
import styles from "../DatePicker.module.css";
import { daysOfWeek } from "../DatePicker.constants";
import { DatePickerDayType } from "../DatePicker.constants";
import { getDayNumbersForGrid } from "../DatePicker.helpers";

export const DaysView = ({
  year,
  month,
  selectedDate,
  firstDayOfWeek,
  onSelect,
  maxDate,
  minDate,
}) => {
  const localDaysOfWeek = [
    ...daysOfWeek.slice(firstDayOfWeek),
    ...daysOfWeek.slice(0, firstDayOfWeek),
  ];
  const datesInGrid = getDayNumbersForGrid(
    year,
    month,
    selectedDate,
    firstDayOfWeek
  );
  const isDateDisabled = date =>
    Boolean((maxDate && date > maxDate) || (minDate && date < minDate));

  return (
    <div className={styles.dayView}>
      {localDaysOfWeek.map((day, i) => (
        <span key={i} className={styles.weekDayName}>
          {day}
        </span>
      ))}
      {datesInGrid.map(({ day, classes, type, date }) => (
        <button
          key={date}
          className={classes}
          disabled={type !== DatePickerDayType.Current || isDateDisabled(date)}
          onClick={() => onSelect(day)}
        >
          {day}
        </button>
      ))}
    </div>
  );
};
