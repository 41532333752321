import React from "react";
import { InformationCard } from ".";
import {
  DEFAULT_CURRENCY,
  END_OF_SERVICE_BENEFIT_TITLE,
  TITLES,
} from "../constants";
import { salaryFormatter } from "../utils/utils";

export function SalaryInformation({
  endOfServiceBenefitAmount: benefitAmount,
  salaryElements,
  isLoading,
}) {
  if (!salaryElements.length && !benefitAmount) return null;

  const currentCurrency =
    salaryElements.find(({ currency }) => currency)?.currency ||
    DEFAULT_CURRENCY;

  const salaryInfoList = salaryElements.reduce((acc, item) => {
    if (item.amount) {
      const newItem = {
        title: `${item.name}:`,
        value: salaryFormatter(item.amount),
      };
      return [...acc, newItem];
    }
    return acc;
  }, []);

  const summaryInfoList = benefitAmount
    ? [
        {
          title: `${END_OF_SERVICE_BENEFIT_TITLE}:`,
          value: salaryFormatter(benefitAmount),
        },
      ]
    : [];

  return (
    <InformationCard
      info={salaryInfoList}
      summaryInfo={summaryInfoList}
      title={`${TITLES.SALARY_INFORMATION} (${currentCurrency})`}
      isLoading={isLoading}
    />
  );
}
