import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { CollapsibleList } from "../collapsibleList/CollapsibleList";
import { ListItem } from "../historyListItem/HistoryListItem";
import { HistoryListCard } from "../historyListCard/HistoryListCard";
import { getDateRangeString } from "../../utils/utils";
import { Period } from "../period/Period";
import { TITLES } from "../../constants";
import { useScrollIntoView } from "../../hooks";
import { MY_PROFILE_PAGE_SECTION_GRADEHISTORY } from "../../auto-test-constants";

export default function GraduationHistoryCard({ items, isLoading }) {
  const isGraduationListAvailable =
    Array.isArray(items) && Boolean(items.length);
  const { nodeRef, onScroll } = useScrollIntoView();

  if (!isLoading && !isGraduationListAvailable) return null;

  return (
    <HistoryListCard
      ref={nodeRef}
      isLoading={isLoading}
      title={TITLES.GRADE_HISTORY}
      attrValue={MY_PROFILE_PAGE_SECTION_GRADEHISTORY}
    >
      <CollapsibleList onCollapse={onScroll} items={items}>
        {(listItems, totalLength) =>
          listItems.map(({ grade, startDate, endDate, period }, index) => (
            <ListItem
              key={grade}
              isLast={index === totalLength - 1}
              isActive={index === 0}
              date={
                <>
                  {getDateRangeString(startDate, endDate)}
                  <Period>{period}</Period>
                </>
              }
              title={`Grade ${grade}`}
            />
          ))
        }
      </CollapsibleList>
    </HistoryListCard>
  );
}

GraduationHistoryCard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      grade: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      period: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
};

GraduationHistoryCard.defaultProps = {
  items: [],
};
