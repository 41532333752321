const breakpoints = {
  small: "500px",
  medium: "592px",
  xmedium: "782px",
  standart: "1056px",
  large: "1338px",
  xlarge: "1440px",
};

export const breakpointsNumeric = {
  medium: 592,
  standart: 1056,
  large: 1338,
  xlarge: 1440,
};

export default breakpoints;
