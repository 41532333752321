import React from "react";

export const PHPIcon = props => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8 27h30v7c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29 27V0l7-3e-7C37.1046-3.6e-7 38 .89543 38 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="28" height="19.5" rx="2" fill="#EE7623" />
    <path
      d="M4.76 15.61h2.47c.54 0 .98333.06 1.33.18.35333.12.63.28.83.48.2.2.34.4333.42.7.08.26.12.5333.12.82 0 .3067-.04.6-.12.88-.08.28-.22333.5267-.43.74-.2.2067-.47333.3733-.82.5-.34667.12-.78333.18-1.31.18h-1.3v2.66H4.76v-7.14Zm2.46 3.47c.30667 0 .55667-.03.75-.09.2-.06.35667-.1433.47-.25.11333-.1133.19-.2467.23-.4.04667-.1533.07-.3267.07-.52 0-.2-.02333-.3733-.07-.52-.04667-.1533-.13-.28-.25-.38-.11333-.1-.27-.1767-.47-.23-.19333-.0533-.44-.08-.74-.08H5.95v2.47h1.27Zm3.8681-3.47h1.21v2.89h3.21v-2.89h1.21v7.14h-1.21v-3.13h-3.21v3.13h-1.21v-7.14Zm7.1485 0h2.47c.54 0 .9833.06 1.33.18.3533.12.63.28.83.48.2.2.34.4333.42.7.08.26.12.5333.12.82 0 .3067-.04.6-.12.88-.08.28-.2234.5267-.43.74-.2.2067-.4734.3733-.82.5-.3467.12-.7834.18-1.31.18h-1.3v2.66h-1.19v-7.14Zm2.46 3.47c.3066 0 .5566-.03.75-.09.2-.06.3566-.1433.47-.25.1133-.1133.19-.2467.23-.4.0466-.1533.07-.3267.07-.52 0-.2-.0234-.3733-.07-.52-.0467-.1533-.13-.28-.25-.38-.1134-.1-.27-.1767-.47-.23-.1934-.0533-.44-.08-.74-.08h-1.26v2.47h1.27Z"
      fill="#fff"
    />
  </svg>
);
