import React from "react";

export default function MeetingsMonitor() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1247 11.4998C12.8226 10.9335 12.3723 10.4598 11.8219 10.1296C11.2715 9.7993 10.6416 9.62485 9.99972 9.62485C9.35781 9.62485 8.72797 9.7993 8.17754 10.1296C7.62711 10.4598 7.17679 10.9335 6.87472 11.4998M13.125 1.49973H18.125C18.4565 1.49973 18.7745 1.63142 19.0089 1.86585C19.2433 2.10027 19.375 2.41821 19.375 2.74973V12.7497C19.375 13.0813 19.2433 13.3992 19.0089 13.6336C18.7745 13.868 18.4565 13.9997 18.125 13.9997H1.875C1.54348 13.9997 1.22554 13.868 0.991117 13.6336C0.756696 13.3992 0.625 13.0813 0.625 12.7497V2.74973C0.625 2.41821 0.756696 2.10027 0.991117 1.86585C1.22554 1.63142 1.54348 1.49973 1.875 1.49973H6.875M4.99992 17.75H14.9999M10 0.875C9.93821 0.875 9.87779 0.893328 9.8264 0.927666C9.77501 0.962004 9.73495 1.01081 9.7113 1.06791C9.68765 1.12501 9.68146 1.18785 9.69352 1.24847C9.70558 1.30908 9.73534 1.36477 9.77904 1.40847C9.82275 1.45217 9.87843 1.48194 9.93905 1.494C9.99967 1.50605 10.0625 1.49986 10.1196 1.47621C10.1767 1.45256 10.2255 1.41251 10.2598 1.36112C10.2942 1.30973 10.3125 1.24931 10.3125 1.1875C10.3125 1.10462 10.2796 1.02513 10.221 0.966529C10.1624 0.907924 10.0829 0.875 10 0.875ZM8.12478 7.12492C8.12478 7.6222 8.32232 8.09912 8.67396 8.45075C9.02559 8.80238 9.5025 8.99992 9.99978 8.99992C10.4971 8.99992 10.974 8.80238 11.3256 8.45075C11.6772 8.09912 11.8748 7.6222 11.8748 7.12492C11.8748 6.62764 11.6772 6.15073 11.3256 5.7991C10.974 5.44747 10.4971 5.24992 9.99978 5.24992C9.5025 5.24992 9.02559 5.44747 8.67396 5.7991C8.32232 6.15073 8.12478 6.62764 8.12478 7.12492ZM13.1247 17.7498H6.87472L7.49972 13.9998H12.4997L13.1247 17.7498Z"
        stroke="#1859A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
