import styled, { css } from "styled-components";
import { CardLayout, CardTitle } from "../../styles/index";
import { flexboxMixin, multiLineEllipsis, respondTo } from "../../utils/mixins";

export const Card = styled(CardLayout)`
  flex-direction: column;
  position: relative;
`;

export const Title = styled(CardTitle)`
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 0;
`;

export const Subtitle = styled.div`
  color: #1859a9;
  text-decoration: none;
  margin-right: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  ${respondTo.medium`
    display: none;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${({ isLoading }) => (isLoading ? "47px" : "0")};
  margin-top: 24px;
  cursor: default;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: right;
  flex: 0 0 40px;

  padding-right: 16px;
  cursor: ${({ isLoading, noDataAvailable }) =>
    isLoading || noDataAvailable ? "default" : "pointer"};
  -webkit-tap-highlight-color: transparent;
`;
export const ReporterInfoWrapper = styled.div`
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const UserName = styled.div`
  display: inline-block;
  vertical-align: top;

  font-weight: ${({ theme, isEmpty }) =>
    isEmpty ? theme.fontWeight.regular : theme.fontWeight.medium};
  font-size: 14px;
  line-height: 20px;
  margin-top: ${({ isEmpty }) => (isEmpty ? "10px" : 0)};

  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ theme, isEmpty }) =>
    isEmpty ? theme.colors.grayDark : theme.colors.black};

  overflow: hidden;
  cursor: pointer;
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.grayDark};

  font-size: 12px;
  line-height: 16px;

  ${multiLineEllipsis({ lineClamp: 2 })}
`;

export const TeamListWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
  grid-auto-rows: minmax(20px, auto);
  grid-template-columns: repeat(4, 1fr);

  ${respondTo.standart`
      grid-template-columns: repeat(4,1fr);
  `}
  ${respondTo.xmedium`
    grid-template-columns: repeat(3,1fr);
  `}
  ${respondTo.medium`
    grid-template-columns: repeat(2,1fr);
  `}
`;

export const MemberCardWrapper = styled.div`
  height: 156px;
  padding: 16px;

  cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};

  ${flexboxMixin};
  flex-direction: column;
  background: white;

  border: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-radius: 8px;

  justify-content: flex-start;

  ${({ shouldApplyHoverStyles, theme }) =>
    shouldApplyHoverStyles &&
    `

    &:hover {
      border: 1px solid ${theme.colors.grayLightest};
      box-shadow: 0px 2px 24px rgba(45, 45, 45, 0.17), 0px 4px 44px rgba(45, 45, 45, 0.06);
    }
  `}
`;

export const MemberPosition = styled.div`
  font-size: 12px;
  line-height: 16px;
  ${multiLineEllipsis}
  color: #8C8C8C;
  margin-top: 4px;
  text-align: center;
`;

export const MemberName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;

  ${multiLineEllipsis({ lineClamp: 1 })}
`;

export const MemberIcon = styled.div`
  margin-bottom: 8px;
`;

export const OrgChartWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
  display: flex;
`;

export const OrgChartContainer = styled.div`
  display: flex;
`;

export const TitleWrapper = styled.div`
  ${flexboxMixin({ justifyContent: "flex-start" })}

  margin-top: 32px;
  margin-bottom: 12px;
`;

export const SeeMore = styled.div`
  display: flex;
  align-items: baseline;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1859a9;

  margin-left: auto;

  cursor: pointer;
`;

export const ReportingToWrapper = styled.div`
  ${({ isEmpty }) => isEmpty && "pointer-events: none; cursor: default;"}
`;

export const TeamQuantity = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  top: 1px;
`;

export const SubtitleWrapper = styled.a`
  display: flex;
  align-items: center;
  padding-right: 16px;
  text-decoration: none;
`;
