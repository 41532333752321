import React from "react";

export const ODPIcon = props => (
  <svg
    width="39"
    height="36"
    viewBox="0 0 39 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8.5" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8.5 27h30v7c0 1.1046-.8954 2-2 2h-26c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29.5 27V0l7-3e-7C37.6046-3.6e-7 38.5.89543 38.5 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="30" height="19.5" rx="2" fill="#00754A" />
    <path
      d="M7.87 22.93c-.51333 0-.97667-.0833-1.39-.25-.40667-.1733-.75667-.42-1.05-.74-.29333-.32-.52-.71-.68-1.17-.15333-.4667-.23-.9933-.23-1.58 0-.5867.08-1.1133.24-1.58.16-.4667.38667-.86.68-1.18.29333-.3267.64667-.5733 1.06-.74.41333-.1733.87-.26 1.37-.26.50667 0 .96667.0867 1.38.26.41333.1667.7633.41 1.05.73.2867.32.5067.7133.66 1.18.16.46.24.9833.24 1.57 0 .5933-.0767 1.1233-.23 1.59-.1533.46-.3733.8533-.66 1.18-.2867.32-.63667.5667-1.05.74-.40667.1667-.87.25-1.39.25Zm-.01-1.04c.68667 0 1.20667-.24 1.56-.72.35333-.4867.53-1.15.53-1.99 0-.42-.04667-.7967-.14-1.13-.08667-.3333-.21667-.6167-.39-.85-.17333-.2333-.39-.4133-.65-.54-.26-.1267-.56333-.19-.91-.19-.34667 0-.65.0633-.91.19s-.48.3067-.66.54c-.17333.2333-.30333.5167-.39.85-.08667.3333-.13.71-.13 1.13 0 .84.17333 1.5033.52 1.99.35333.48.87667.72 1.57.72Zm7.0046-.17c.42 0 .7767-.06 1.07-.18.3-.12.5433-.2933.73-.52.1933-.2267.3333-.4967.42-.81.0867-.3133.13-.6667.13-1.06 0-.7867-.1667-1.4-.5-1.84-.3333-.4467-.88-.67-1.64-.67h-1.38v5.08h1.17Zm.2-6.11c.5267 0 1 .0733 1.42.22.42.14.7767.3567 1.07.65.2933.2933.5167.6633.67 1.11.16.44.24.9567.24 1.55s-.0833 1.1167-.25 1.57c-.1667.4467-.4067.8233-.72 1.13-.3133.3-.6967.5267-1.15.68-.4467.1533-.9533.23-1.52.23h-2.34v-7.14h2.58Zm4.6759 0h2.47c.54 0 .9833.06 1.33.18.3533.12.63.28.83.48.2.2.34.4333.42.7.08.26.12.5333.12.82 0 .3067-.04.6-.12.88-.08.28-.2234.5267-.43.74-.2.2067-.4734.3733-.82.5-.3467.12-.7834.18-1.31.18h-1.3v2.66h-1.19v-7.14Zm2.46 3.47c.3066 0 .5566-.03.75-.09.2-.06.3566-.1433.47-.25.1133-.1133.19-.2467.23-.4.0466-.1533.07-.3267.07-.52 0-.2-.0234-.3733-.07-.52-.0467-.1533-.13-.28-.25-.38-.1134-.1-.27-.1767-.47-.23-.1934-.0533-.44-.08-.74-.08h-1.26v2.47h1.27Z"
      fill="#fff"
    />
  </svg>
);
