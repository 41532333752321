import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useResizeDetector } from "react-resize-detector";

export const ShowMore = ({
  children,
  linesLimit = 1,
  isCollapsedByDefault = true,
  renderToggler,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const targetRef = useRef(null);

  const minHeightLimit = useMemo(() => {
    if (targetRef?.current) {
      return (
        parseInt(
          window
            ?.getComputedStyle(targetRef?.current)
            .getPropertyValue("line-height")
        ) * linesLimit
      );
    }
    return 0;
  }, [targetRef?.current]);

  const { height, ref: hiddenRef } = useResizeDetector();

  const onResize = useCallback(() => {
    const isLessOrEqualToMinHeight =
      targetRef.current.clientHeight - minHeightLimit > 0;

    const isEllipsisActive = targetRef.current.offsetHeight < height;
    setIsOverflowing(isEllipsisActive);

    if (!isCollapsed && !isLessOrEqualToMinHeight) {
      setIsCollapsed(true);
    }
  }, [targetRef, setIsOverflowing, isCollapsed, setIsCollapsed, height]);

  useEffect(() => {
    onResize();
  }, [height]);

  const toggle = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [setIsCollapsed, isCollapsed]);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={
          isCollapsed
            ? {
                display: "-webkit-box",
                WebkitLineClamp: linesLimit,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordBreak: "break-word",
              }
            : {}
        }
        ref={targetRef}
      >
        {children}
      </div>
      <div
        style={{ visibility: "hidden", position: "absolute", width: "100%" }}
        ref={hiddenRef}
      >
        {children}
      </div>
      {(isOverflowing || !isCollapsed) && renderToggler(isCollapsed, toggle)}
    </div>
  );
};
