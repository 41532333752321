import React from "react";
import { SvgBlueHover } from "../styles/icons";

const Email = ({ shouldApplyHoverStyles, isActive }) => (
  <SvgBlueHover
    isActive={isActive}
    shouldApplyHoverStyles={shouldApplyHoverStyles}
    width="18"
    height="18"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 2.66699C1.5 1.83856 2.17157 1.16699 3 1.16699H15C15.8284 1.16699 16.5 1.83857 16.5 2.66699V10.3813C16.5 11.2097 15.8284 11.8813 15 11.8813H3C2.17157 11.8813 1.5 11.2097 1.5 10.3813V2.66699Z"
      stroke="#9D9FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4287 2.06055L10.2762 5.92321C9.5239 6.48728 8.47631 6.48728 7.72401 5.92321L2.57153 2.06055"
      stroke="#9D9FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgBlueHover>
);

export default Email;
