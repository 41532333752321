import {
  Date,
  Details,
  Info,
  ItemWrapper,
  Line,
  Marker,
  Title,
} from "./HistoryListItemStyles";
import React from "react";
import PropTypes from "prop-types";

export const ListItem = ({ isActive, details, date, title, isLast }) => (
  <ItemWrapper $isLast={isLast}>
    <div>
      {!isLast && <Line />}
      <Marker $isActive={isActive} />
    </div>
    <Info>
      <Date>{date}</Date>
      <Title>{title}</Title>
      {details && <Details>{details}</Details>}
    </Info>
  </ItemWrapper>
);

ListItem.defaultProps = {
  isActive: false,
  details: undefined,
  isLast: false,
};

ListItem.propTypes = {
  isActive: PropTypes.bool,
  isLast: PropTypes.bool,
  details: PropTypes.node,
  date: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};
