import { isWebView } from "../lib/utils";
import { X_MOBILE_HEADER } from "../lib/constants";
import { getToken, handleBlobResponse } from "./utils";

class HTTPClient {
  constructor(getAccessTokenCallback, proxyUrl, lightAppsUrl) {
    this.getAccessTokenCallback = getAccessTokenCallback;
    this.proxyUrl = proxyUrl;
    this.lightAppsUrl = lightAppsUrl;
  }

  async _get(url, options = { responseType: "" }) {
    const token = await getToken(this.getAccessTokenCallback);

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      if (isWebView()) {
        headers[X_MOBILE_HEADER] = true;
      }

      const response = await fetch(url, {
        method: "GET",
        headers,
        ...options,
      });
      const { responseType } = options;
      return responseType === "blob"
        ? handleBlobResponse(response)
        : await response.json();
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  }

  getOnlineActivities = () => {
    const url = `${this.proxyUrl}/meera/blackbox/intranet-widget-bot`;
    return this._get(url);
  };

  getProfile = user => {
    const url = `${this.lightAppsUrl}/profile/${user}`;
    return this._get(url);
  };

  getUserAvatar = email => {
    const url = `${this.proxyUrl}/profile/onehub/profile/photo/${email}`;
    return this._get(url, { responseType: "blob" });
  };

  getUserPresenceStatus = emails => {
    if (!emails?.length) {
      return Promise.resolve([]);
    }

    const emailsQuery = emails.reduce((acc, item) => `${acc}&user=${item}`, "");
    return this._get(
      `${this.proxyUrl}/graph/presence?${encodeURI(emailsQuery.slice(1))}`
    );
  };
}

export default HTTPClient;
