import React from "react";
import moment from "moment";

import PropTypes from "prop-types";
import { CenteredSpinner } from "../../Spinner";
import { ShowMore } from "../components/ShowMore/index";
import styles from "./CorrespondenceTrackingView.module.css";
import ArrowRight from "../images/arrow_right";
import { TRACKING_DATE_FORMAT } from "../constants";

export const CorrespondenceTrackingView = ({ tracking }) => {
  if (!tracking) {
    return (
      <div className={styles.loaderContainer}>
        <CenteredSpinner />
      </div>
    );
  }

  const getFormattedDate = date => {
    return moment(date).isValid()
      ? moment(date).format(TRACKING_DATE_FORMAT)
      : date;
  };

  const renderShowMoreButton = (isCollapsed, toggle) => {
    return (
      <div className={styles.showMoreToggler} onClick={toggle}>{`Show ${
        isCollapsed ? "more" : "less"
      }`}</div>
    );
  };

  return (
    <div className={styles.trackingList}>
      {tracking.map(
        ({
          id,
          fromUser,
          fromOffice,
          toUser,
          toOffice,
          receivedDate,
          purpose,
          notes,
        }) => (
          <div className={styles.trackingCard} key={id}>
            <div className={styles.trackingIcon} />
            <div className={styles.mainInfo}>
              <span className={styles.fromUser} title={fromUser}>
                {fromUser || fromOffice}
              </span>
              <span className={styles.toUser}>
                <ArrowRight
                  className={styles.toUserIcon}
                  width="8"
                  height="8"
                />
                <span className={styles.toUserLabel} title={toUser}>
                  {toUser || toOffice}
                </span>
              </span>
              <span
                className={styles.receivedDate}
                title={getFormattedDate(receivedDate)}
              >
                {getFormattedDate(receivedDate)}
              </span>
              <span className={styles.purpose} title={purpose}>
                {purpose}
              </span>
            </div>
            <div className={styles.notes} title={notes}>
              <ShowMore linesLimit={2} renderToggler={renderShowMoreButton}>
                {notes}
              </ShowMore>
            </div>
          </div>
        )
      )}
    </div>
  );
};

CorrespondenceTrackingView.propTypes = {
  tracking: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fromUser: PropTypes.string,
      fromOffice: PropTypes.string,
      toUser: PropTypes.string,
      toOffice: PropTypes.string,
      notes: PropTypes.string,
      purpose: PropTypes.string,
      receivedDate: PropTypes.string,
    })
  ),
};
