import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  IconWrapper,
  Text,
  Title,
  Wrapper,
  UserName,
  ReportingToWrapper,
  ReporterInfoWrapper,
} from "./styles/reportingCard";
import { Avatar } from "../utils/Avatar";
import {
  SkeletonRound,
  SkeletonTextElement,
} from "../utils/Loaders/SkeletonPulse";
import { TITLES } from "../constants";
import { convertReportingTo } from "../utils/utils";
import { useUserAvatar } from "../hooks/useUserAvatar";

function ReportingTo({
  reportingToMemberData,
  isLoading,
  onReportingToMemberClick,
  managerStatus,
}) {
  const { managerFullName, managerDesignation, managerEmailId } =
    reportingToMemberData;
  const convertedManagerData = convertReportingTo(reportingToMemberData);
  const [managerAvatar] = useUserAvatar(convertedManagerData?.email);
  const onReportingToMemberClickHandler = () => {
    if (onReportingToMemberClick && managerEmailId) {
      onReportingToMemberClick(convertedManagerData);
    }
  };

  return (
    <>
      {isLoading ? (
        <SkeletonTextElement width={143} height={16} margin={15} />
      ) : (
        <Title>
          {managerFullName ? TITLES.REPORTING_TO : TITLES.REPORTING}
        </Title>
      )}
      <Wrapper isLoading={isLoading}>
        <IconWrapper
          isLoading={isLoading}
          noDataAvailable={!managerEmailId}
          onClick={onReportingToMemberClickHandler}
        >
          {isLoading ? (
            <SkeletonRound size={40} />
          ) : (
            <Avatar
              src={managerAvatar}
              name={managerFullName}
              status={managerStatus}
              size={40}
              statusSize={12}
              isClickable
              noData={!managerFullName}
            />
          )}
        </IconWrapper>
        <ReportingToWrapper isLoading={isLoading} isEmpty={!managerFullName}>
          {isLoading ? (
            <>
              <SkeletonTextElement width={110} height={16} margin={8} />
              <SkeletonTextElement width={225} height={16} />
            </>
          ) : (
            <ReporterInfoWrapper>
              <UserName
                onClick={onReportingToMemberClickHandler}
                isEmpty={!managerFullName}
              >
                {managerFullName || "No Manager"}
              </UserName>
              {!!managerDesignation && managerFullName && (
                <Text>{managerDesignation}</Text>
              )}
            </ReporterInfoWrapper>
          )}
        </ReportingToWrapper>
      </Wrapper>
    </>
  );
}

ReportingTo.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onReportingToMemberClick: PropTypes.func,
  reportingToMemberData: PropTypes.shape({}),
};

ReportingTo.defaultProps = {
  reportingToMemberData: {},
  onReportingToMemberClick: undefined,
};

export default ReportingTo;
