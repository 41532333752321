import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MeetingsMonitor from "./icons/MeetingsMonitor";
import PhoneClock from "./icons/PhoneClock";
import {
  Divider,
  IconWrapper,
  Indicator,
  IndicatorBlock,
  IndicatorTitle,
  InfoBlockWrapper,
  Link,
  OnlineActivitiesDate,
  OnlineActivitiesHeader,
  OnlineActivitiesSubtitle,
  OnlineActivitiesTime,
  OnlineActivitiesTitle,
  OnlineActivitiesWrapper,
} from "./styles/onlineActivities";
import { INDICATOR_BLOCKS } from "../constants";
import { getFormattedSpendTime, getPeriod } from "../utils/utils";
import { SkeletonTextElement } from "../utils/Loaders/SkeletonPulse";
import { useActivity } from "../hooks";
import { MY_PROFILE_PAGE_SECTION_ACTIVITYREPORT } from "../auto-test-constants";

const SKELETON_CSS_SMALL = { marginTop: "16px" };
const SKELETON_CSS_MEDIUM = { marginTop: "24px" };

export function OnlineActivities({ isLoading }) {
  const [activityData, isActivityLoading] = useActivity();

  if (isActivityLoading || isLoading) {
    return (
      <OnlineActivitiesWrapper isLoading>
        <SkeletonTextElement width={112} height={24} />
        <SkeletonTextElement height={48} css={SKELETON_CSS_MEDIUM} />
        <SkeletonTextElement height={30} margin={24} css={SKELETON_CSS_SMALL} />
        <Divider />
        <SkeletonTextElement height={48} />
        <SkeletonTextElement height={30} css={SKELETON_CSS_SMALL} />
        <SkeletonTextElement
          width={100}
          height={20}
          css={SKELETON_CSS_MEDIUM}
        />
      </OnlineActivitiesWrapper>
    );
  }

  if (!activityData) {
    return null;
  }

  const {
    CollaborationQuartile,
    CollaborationSeconds,
    UserConnectedQuartile,
    UserConnectedSeconds,
    FromDate,
    ToDate,
    dailyAvgConnectedScoreDescription,
    navigationURL,
    weeklyCollaborationScoreDescription,
  } = activityData;

  return (
    <OnlineActivitiesWrapper data-attributes={MY_PROFILE_PAGE_SECTION_ACTIVITYREPORT}>
      <OnlineActivitiesHeader>
        <OnlineActivitiesTitle>Activity Report</OnlineActivitiesTitle>
        <OnlineActivitiesDate>
          {getPeriod(FromDate, ToDate)}
        </OnlineActivitiesDate>
      </OnlineActivitiesHeader>
      <InfoBlock
        title="Weekly Collaboration Time"
        indicatorTitle={weeklyCollaborationScoreDescription}
        time={CollaborationSeconds}
        quartile={Number(CollaborationQuartile)}
      >
        <PhoneClock />
      </InfoBlock>
      <Divider />
      <InfoBlock
        title="Weekly Connected Time on PС"
        indicatorTitle={dailyAvgConnectedScoreDescription}
        time={UserConnectedSeconds}
        quartile={Number(UserConnectedQuartile)}
      >
        <MeetingsMonitor />
      </InfoBlock>
      <Link target="_blank" href={navigationURL} rel="noopener noreferrer">
        View my report
      </Link>
    </OnlineActivitiesWrapper>
  );
}

OnlineActivities.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

function InfoBlock({ title, time, indicatorTitle, quartile, children }) {
  return (
    <>
      <InfoBlockWrapper>
        <IconWrapper>{children}</IconWrapper>
        <div>
          <OnlineActivitiesSubtitle>{title}</OnlineActivitiesSubtitle>
          <OnlineActivitiesTime>
            {getFormattedSpendTime(time)}
          </OnlineActivitiesTime>
        </div>
      </InfoBlockWrapper>
      <IndicatorTitle>{indicatorTitle}</IndicatorTitle>
      <Indicator>
        {INDICATOR_BLOCKS.map(key => (
          <IndicatorBlock key={key} isActive={quartile >= key} />
        ))}
      </Indicator>
    </>
  );
}

InfoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  indicatorTitle: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  quartile: PropTypes.number.isRequired,
};
