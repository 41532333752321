import React from "react";

export const ACCXIcon = props => (
  <svg
    width="42"
    height="36"
    viewBox="0 0 42 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="12" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M12 27h30v7c0 1.1046-.8954 2-2 2H14c-1.1046 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M33 27V0l7-3e-7C41.1046-3.6e-7 42 .89543 42 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="36" height="19.5" rx="2" fill="#005871" />
    <path
      d="M6.83 15.61h1.2l2.72 7.14H9.46l-.67-1.82H6.02l-.66 1.82H4.11l2.72-7.14Zm1.63 4.31L7.4 16.98l-1.05 2.94h2.11Zm8.7351.42c-.04.3733-.13.7167-.27 1.03s-.3334.5867-.58.82c-.2467.2333-.5467.4167-.9.55-.3534.1267-.7634.19-1.23.19-.5134 0-.9734-.0867-1.38-.26-.4-.1733-.74-.42-1.02-.74-.2734-.3267-.4834-.72-.63-1.18-.14-.4667-.21-.9867-.21-1.56 0-.5867.0766-1.1133.23-1.58.16-.4667.3833-.86.67-1.18.2866-.3267.63-.5733 1.03-.74.4-.1733.84-.26 1.32-.26.4466 0 .8333.0567 1.16.17.3266.1133.6033.2767.83.49.2333.2067.4166.4567.55.75.14.2933.2433.62.31.98l-1.19.17c-.0534-.24-.12-.4533-.2-.64-.08-.1867-.1867-.3433-.32-.47-.1267-.1333-.2834-.2333-.47-.3-.1867-.0733-.41-.11-.67-.11-.32 0-.6034.0633-.85.19-.2467.1267-.4567.31-.63.55-.1667.2333-.2934.5167-.38.85-.0867.3333-.13.7067-.13 1.12 0 .42.0433.7967.13 1.13.0933.3333.2266.62.4.86.1733.2333.38.4133.62.54.2466.12.5266.18.84.18.28 0 .5166-.0367.71-.11.2-.0733.3666-.1767.5-.31.1333-.1333.2366-.2933.31-.48.08-.1933.1366-.41.17-.65h1.28Zm7.1191 0c-.04.3733-.13.7167-.27 1.03s-.3333.5867-.58.82c-.2466.2333-.5466.4167-.9.55-.3533.1267-.7633.19-1.23.19-.5133 0-.9733-.0867-1.38-.26-.4-.1733-.74-.42-1.02-.74-.2733-.3267-.4833-.72-.63-1.18-.14-.4667-.21-.9867-.21-1.56 0-.5867.0767-1.1133.23-1.58.16-.4667.3834-.86.67-1.18.2867-.3267.63-.5733 1.03-.74.4-.1733.84-.26 1.32-.26.4467 0 .8334.0567 1.16.17.3267.1133.6034.2767.83.49.2334.2067.4167.4567.55.75.14.2933.2434.62.31.98l-1.19.17c-.0533-.24-.12-.4533-.2-.64-.08-.1867-.1866-.3433-.32-.47-.1266-.1333-.2833-.2333-.47-.3-.1866-.0733-.41-.11-.67-.11-.32 0-.6033.0633-.85.19-.2466.1267-.4566.31-.63.55-.1666.2333-.2933.5167-.38.85-.0866.3333-.13.7067-.13 1.12 0 .42.0434.7967.13 1.13.0934.3333.2267.62.4.86.1734.2333.38.4133.62.54.2467.12.5267.18.84.18.28 0 .5167-.0367.71-.11.2-.0733.3667-.1767.5-.31.1334-.1333.2367-.2933.31-.48.08-.1933.1367-.41.17-.65h1.28Zm2.7738-1.36-2.32-3.37h1.38l1.64 2.5 1.66-2.5h1.3l-2.3 3.42 2.58 3.72h-1.39l-1.9-2.82-1.84 2.82h-1.31l2.5-3.77Z"
      fill="#fff"
    />
  </svg>
);
