import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getInitials, isIOS } from "../utils";
import { flexboxMixin } from "../mixins";

const StyledAvatar = styled.div`
  width: ${({ width }) => (width ? `${width}px` : "40px")};
  height: ${({ height }) => (height ? `${height}px` : "40px")};
  border-radius: 48%;
  flex-shrink: 0;
  cursor: pointer;
  font-size: ${({ width }) => (width && width === 72 ? `32px` : `18px`)};
  text-transform: uppercase;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  line-height: ${isIOS() ? "16px" : 0};
  -webkit-tap-highlight-color: transparent;
  ${flexboxMixin}

  &:hover {
    box-shadow: 0 7px 17px rgba(0, 0, 0, 0.17);
  }

  &:hover ~ * {
    box-shadow: none;
  }
`;

const getAvatarBackground = (name, avatarsBackground) => {
  const firstLetter = name.slice(0, 1).toLowerCase().charCodeAt(0);

  if (firstLetter > 96 && firstLetter < 102) {
    return avatarsBackground[0];
  }
  if (firstLetter > 101 && firstLetter < 107) {
    return avatarsBackground[1];
  }
  if (firstLetter > 106 && firstLetter < 112) {
    return avatarsBackground[2];
  }
  if (firstLetter > 111 && firstLetter < 117) {
    return avatarsBackground[3];
  }
  return avatarsBackground[4];
};

const avatarsBackground = [
  { background: "#D5F5E3", color: "#39CF78" },
  { background: "#E5ECFF", color: "#4F7CFF" },
  { background: "#FADBD8", color: "#E74D3E" },
  { background: "#FCF3CF", color: "#DBB20E" },
  { background: "#E2DCFB", color: "#7850E9" },
];

const InitialsAvatar = React.memo(({ name, surname, ...props }) => {
  let initials = "";

  if (surname) {
    initials = getInitials(name, surname);
  } else {
    const temp = name.split(" ");
    initials =
      temp.length === 1 ? temp[0].slice(0, 1) : getInitials(temp[0], temp[1]);
  }
  const { background, color } = getAvatarBackground(name, avatarsBackground);

  return (
    <StyledAvatar {...props} background={background} color={color}>
      {initials}
    </StyledAvatar>
  );
});

InitialsAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  surname: PropTypes.string,
};

InitialsAvatar.defaultProps = {
  surname: "",
};

export default InitialsAvatar;
