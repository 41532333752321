import React, { Component, createRef } from "react";
import styled from "styled-components";
import { List } from "react-virtualized";

import Page from "./PDFViewerPage";

const StyledList = styled(List)`
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9d9fa0;
    border-radius: 100px;
  }

  & > div {
    max-width: initial !important;
  }
`;
export default class PDFViewer extends Component {
  constructor(props) {
    super(props);
    this.pdfContainerRef = createRef();
  }

  state = {
    pdf: null,
    numPages: 0,
    pageWidth: 0,
    pageHeight: 0,
    scale: 0,
  };

  style = {
    minHeight: "auto",
    width: "100%",
  };

  async recalcPDFSizes() {
    const pageSizes = await this.getSize(this.props.file);

    if (pageSizes) {
      this.setState({
        pageHeight: pageSizes.pageHeight,
        pageWidth: pageSizes.pageHeight,
      });
    }
  }

  async componentDidMount() {
    try {
      const { file } = this.props;
      const { numPages } = file;
      const { pageWidth, pageHeight, scale } = await this.getSize(file);
      this.setState({ pdf: file, numPages, pageWidth, pageHeight, scale });

      window.addEventListener("resize", this.recalcPDFSizes.bind(this));
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFullscreen !== this.props.isFullscreen) {
      this.recalcPDFSizes();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.recalcPDFSizes);
  }

  getSize = async pdf => {
    const page = await pdf.getPage(1);

    const scale = 1.5;
    const { width, height } = page.getViewport({ scale });
    const pdfdiv = this.pdfContainerRef.current;
    let pageWidth, pageHeight;

    if (pdfdiv) {
      pageWidth = pdfdiv.clientWidth || this.props.tabsContainerWidth || 0;
      pageHeight = (pageWidth * height) / width;

      return {
        pageWidth,
        pageHeight,
        scale,
      };
    }
  };

  render() {
    const { pdf, numPages, pageWidth, pageHeight, scale, className } =
      this.state;
    return (
      <div ref={this.pdfContainerRef} className={className} style={this.style}>
        {pdf ? (
          <StyledList
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden auto",
              outline: "none",
            }}
            ref={this.list}
            width={pageWidth}
            height={pageHeight}
            rowHeight={pageHeight}
            rowCount={numPages}
            rowRenderer={({ index, style }) => {
              return (
                <Page
                  key={index}
                  index={index}
                  style={style}
                  pdf={pdf}
                  pageNumber={index + 1}
                  pageWidth={pageWidth}
                  pageHeight={pageHeight}
                  scale={scale}
                />
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}
