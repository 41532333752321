import React from "react";

export const PDFIcon = props => (
  <svg
    width="41"
    height="36"
    viewBox="0 0 41 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="11" width="30" height="36" rx="2" fill="#C0321C" />
    <path d="M33 36v-9h8v7c0 1.1046-.8954 2-2 2h-6Z" fill="#6B2115" />
    <path d="M33 27v-9h8v9h-8Z" fill="#931D0B" />
    <path d="M33 18V9h8v9h-8Z" fill="#B12711" />
    <path
      d="M33 9V0l6-2e-7C40.1046-2.3e-7 41 .89543 41 2v7h-8Z"
      fill="#C0321C"
    />
    <rect y="9" width="28" height="19.5" rx="2" fill="#6B2115" />
    <path
      d="M4.76 15.61h2.47c.54 0 .98333.06 1.33.18.35333.12.63.28.83.48.2.2.34.4333.42.7.08.26.12.5333.12.82 0 .3067-.04.6-.12.88-.08.28-.22333.5267-.43.74-.2.2067-.47333.3733-.82.5-.34667.12-.78333.18-1.31.18h-1.3v2.66H4.76v-7.14Zm2.46 3.47c.30667 0 .55667-.03.75-.09.2-.06.35667-.1433.47-.25.11333-.1133.19-.2467.23-.4.04667-.1533.07-.3267.07-.52 0-.2-.02333-.3733-.07-.52-.04667-.1533-.13-.28-.25-.38-.11333-.1-.27-.1767-.47-.23-.19333-.0533-.44-.08-.74-.08H5.95v2.47h1.27Zm6.2481 2.64c.42 0 .7767-.06 1.07-.18.3-.12.5434-.2933.73-.52.1934-.2267.3334-.4967.42-.81.0867-.3133.13-.6667.13-1.06 0-.7867-.1666-1.4-.5-1.84-.3333-.4467-.88-.67-1.64-.67h-1.38v5.08h1.17Zm.2-6.11c.5267 0 1 .0733 1.42.22.42.14.7767.3567 1.07.65.2934.2933.5167.6633.67 1.11.16.44.24.9567.24 1.55s-.0833 1.1167-.25 1.57c-.1666.4467-.4066.8233-.72 1.13-.3133.3-.6966.5267-1.15.68-.4466.1533-.9533.23-1.52.23h-2.34v-7.14h2.58Zm9.2359 0v1.04h-3.35v2.02h3.15v1.05h-3.15v3.03h-1.21v-7.14h4.56Z"
      fill="#fff"
    />
  </svg>
);
