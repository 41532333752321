const colors = {
  // neutral
  white: "#ffffff",
  black: "#000000",

  grayDarker: "#303030",
  grayDark: "#8c8c8c",
  gray: "#9d9fa0",
  grayLight: "#eeeeee",
  grayLighter: "#f6f7fa",
  grayLightest: "#f9f9f9",

  // primary
  navyBlue: "#1E3B6B",
  royalBlue: "#1859A9",
  coolBlue: "#D4D2CF",
  skyBlue: "#089BD7",
  deepBlue: "#0074F8",
  lochmara: "#0075c9",

  skyGray: "#D4D2CF",
  steelGray: "#4D5156",
  coolGray: "#9D9FA0",

  // secondary
  alertGreen: "#109647",
  green: "#00754A",
  darkTurquoise: "#005871",
  purple: "#291446",
  purpleLight: "#5D64AA",
  brightOrange: "#FAA22C",
  orange: "#EE7623",
  brightYellow: "#FCE300",
  yellow: "#FED10A",
  brightRed: "#E32726",
  red: "#A9343A",
  activeBlue: "#51A3D6",
  cobalt: "#0047ba",

  // rgb
  skyGrayLightRGB: "rgb(81, 163, 214, .15)",
  grayDarkRGB: "rgb(140, 140, 140, 0.2)",
  alertGreenRGB: "rgb(16, 150, 71, 0.2)",
  whiteWithOpacity_8: "rgb(255, 255, 255, 0.8)",
  lightBlueRGB: "rgb(0,117, 201, 0.1)",

  bitterSweet: "#FF7272",
  tallPoppy: "#B72A2A",
};

export default colors;
