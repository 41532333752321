import React from "react";

const BusyIdle = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9"
      r="6.75"
      fill="white"
      stroke="#A9343A"
      strokeWidth="2.5"
    />
    <circle cx="9" cy="9" r="8" stroke="white" strokeWidth="2" />
  </svg>
);

export default BusyIdle;
