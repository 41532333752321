import styled from "styled-components";

export const SvgBlueHover = styled.svg`
  & path {
    stroke: ${({ isActive, theme }) =>
      isActive ? theme.colors.activeBlue : theme.colors.gray};
  }

  ${({ shouldApplyHoverStyles, theme }) =>
    shouldApplyHoverStyles &&
    `
    &:hover {
      path {
        stroke: ${theme.colors.activeBlue};
      }
    }
  `}
`;

export const CollapseArrowSvg = styled.svg`
  ${({ shouldRotate }) =>
    shouldRotate &&
    `
    transform: rotate(90deg);
  `}
`;
