import React from "react";

export const VPXIcon = props => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8 27h30v7c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29 27V0l7-3e-7C37.1046-3.6e-7 38 .89543 38 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="28" height="19.5" rx="2" fill="#291446" />
    <path
      d="m5.46 15.61 1.96 5.5 1.99-5.5h1.29l-2.71 7.14H6.83l-2.72-7.14h1.35Zm6.1164 0h2.47c.54 0 .9833.06 1.33.18.3533.12.63.28.83.48.2.2.34.4333.42.7.08.26.12.5333.12.82 0 .3067-.04.6-.12.88-.08.28-.2233.5267-.43.74-.2.2067-.4733.3733-.82.5-.3467.12-.7833.18-1.31.18h-1.3v2.66h-1.19v-7.14Zm2.46 3.47c.3067 0 .5567-.03.75-.09.2-.06.3567-.1433.47-.25.1133-.1133.19-.2467.23-.4.0467-.1533.07-.3267.07-.52 0-.2-.0233-.3733-.07-.52-.0467-.1533-.13-.28-.25-.38-.1133-.1-.27-.1767-.47-.23-.1933-.0533-.44-.08-.74-.08h-1.26v2.47h1.27Zm5.2977-.1-2.32-3.37h1.38l1.64 2.5 1.66-2.5h1.3l-2.3 3.42 2.58 3.72h-1.39l-1.9-2.82-1.84 2.82h-1.31l2.5-3.77Z"
      fill="#fff"
    />
  </svg>
);
