import React from "react";

const OrgChart = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12.5C1 11.6716 1.67157 11 2.5 11H4.5C5.32843 11 6 11.6716 6 12.5V14.5C6 15.3284 5.32843 16 4.5 16H2.5C1.67157 16 1 15.3284 1 14.5V12.5Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2.5C6 1.67157 6.67157 1 7.5 1H9.5C10.3284 1 11 1.67157 11 2.5V4.5C11 5.32843 10.3284 6 9.5 6H7.5C6.67157 6 6 5.32843 6 4.5V2.5Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 12.5C11 11.6716 11.6716 11 12.5 11H14.5C15.3284 11 16 11.6716 16 12.5V14.5C16 15.3284 15.3284 16 14.5 16H12.5C11.6716 16 11 15.3284 11 14.5V12.5Z"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 11V10C3.5 9.44772 3.94772 9 4.5 9H12.5C13.0523 9 13.5 9.44772 13.5 10V11"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 6V9"
      stroke="#1859A9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OrgChart;
