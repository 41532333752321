import React from "react";

const Mobile = () => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.500732 0.5H9.50073V15.5H0.500732V0.5Z"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.500732 12.5H9.50073"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Mobile;
