import styled from "styled-components";
import { CardLayout } from "../../styles/index";

export const ProfileCompletenessWrapper = styled(CardLayout)`
  width: 100%;
  flex-direction: column;
`;

export const ProfileCompletenessHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
`;

export const ProfileCompletenessTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const ProfileCompletenessTitleTwo = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

export const ProfileCompletenessDate = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${props => props.theme.colors.gray};
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  background-color: rgba(24, 89, 169, 0.08);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const ProfileCompletenessIndicatorBox = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const ProfileCompletenessIndicator = styled.div`
  width: 100%;
  margin: 5px 0;
  flex-grow: 1;
  height: 6px;
  border-radius: 4px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.lochmara : theme.colors.grayLight};
`;

export const ProfileCompletenessIndicatorLoading = styled.div`
  height: 6px;
  width: ${({ loadingPercentage }) => loadingPercentage || 0}%;
  background-color: ${({ theme }) => theme.colors.lochmara};
  border-radius: ${({ loadingPercentage }) =>
    loadingPercentage >= 98 ? "4px" : "4px 0px 0px 4px"};
`;

export const ProfileCompletenessIndicatorTitle = styled.div`
  color: ${props => props.theme.colors.grayDark};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  ${({ isManagerView }) => {
    if (isManagerView) {
      return `
        display: flex; 
        align-items:center;
        & > svg {
          margin-right: 13.25px
        }
        `;
    }

    return "padding-bottom: 8px;";
  }}
  & span {
    color: #1859a9;
    font-weight: 700;
  }

  & p {
    margin: 0;
  }
`;

export const ProfileCompletenessSubtitle = styled.div`
  color: ${props => props.theme.colors.grayDark};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;
`;

export const ProfileCompletenessTime = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

export const ProfileCompletenessSectionBox = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export const ProfileCompletenessSectionList = styled.div`
  width: 100%;
  & > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export const ProfileCompletenessSectionListItem = styled.li`
  width: 100%;
  position: relative;
  margin-bottom: 4px;
  border-radius: 0px;
  background-color: #f6f7fa;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      ${({ completenessPercentageColor }) =>
          completenessPercentageColor || "#f6f7fa"}
        0%,
      ${({ completenessPercentageColor }) =>
          completenessPercentageColor || "#f6f7fa"}
        50%,
      #f6f7fa 50%,
      #f6f7fa 100%
    );
    width: 4px;
  }
  &:last-child {
    border-radius: 0px 0px 4px 4px;
    &::after {
      border-radius: 0px 0px 0px 4px;
    }
  }
  &:first-child {
    border-radius: 4px 4px 0px 0px;
    &::after {
      border-radius: 4px 0px 0px 0px;
    }
  }
  & > a {
    position: relative;
    user-select: none;
    color: ${({ blur }) => (blur ? "#8C8C8C" : "#303030")};
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 0 38px 0 14px;
    min-height: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px 0px 0px 0px;
    & > span {
      position: absolute;
      display: flex;
      right: 19px;
      ${({ isActive }) => (isActive ? " transform: rotate(180deg);" : "")}
    }

    &.tooltip:hover {
      & > [title="tooltip"] {
        display: flex;
      }
    }
  }
`;

export const ProfileCompletenessSectionListSubsection = styled.div`
  width: 100%;
  & > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-bottom: 4px;
  }
`;

export const ProfileCompletenessSectionListSubsectionItem = styled.li`
  width: 100%;
  position: relative;
  margin-bottom: 4px;
  border-radius: 0px;
  &:last-child {
    border-radius: 0px 0px 4px 4px;
    &::after {
      border-radius: 0px 0px 0px 4px;
    }
  }
  &:first-child {
    border-radius: 4px 4px 0px 0px;
    &::after {
      border-radius: 4px 0px 0px 0px;
    }
  }
  & > a {
    position: relative;
    user-select: none;
    color: ${({ blur }) => (blur ? "#8C8C8C" : "#303030")};
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 0 38px 0 14px;
    min-height: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    & > span {
      display: flex;
      margin: 10px;
      ${({ blur }) => (blur ? "opacity: 0.6;" : "")}
    }
  }
`;

export const Link = styled.a`
  cursor: pointer;
  user-select: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1859a9;
  text-decoration: none;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grayLight};
  margin-bottom: 24px;
`;

export const ProfileCompletenessTooltip = styled.div`
  display: none;
  position: absolute;
  top: -50px;
  left: 5px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 6px 14px rgba(0, 0, 0, 0.13));
`;

export const ProfileCompletenessTooltipBox = styled.div`
  position: relative;
  z-index: 999;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #303030;
  &::after {
    position: absolute;
    background: #ffffff;
    left: calc(50% - 8px);
    text-align: center;
    bottom: -8px;
    z-index: 99;
    content: "";
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
  }
`;
