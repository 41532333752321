import React from "react";

const Pdf = props => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect
      x="9.83"
      y="1.33"
      width="22.67"
      height="29.33"
      rx="1"
      fill="#C0321C"
    />
    <path
      d="M25.83 8.67V1.33h5.5c.65 0 1.17.53 1.17 1.17v6.17h-6.67Z"
      fill="#C0321C"
    />
    <path d="M25.83 16V8.67h6.67V16h-6.67Z" fill="#B12711" />
    <path d="M25.83 23.33V16h6.67v7.33h-6.67Z" fill="#931D0B" />
    <path
      d="M25.83 30.67v-7.34h6.67v6.17a1.17 1.17 0 0 1-1.17 1.17h-5.5Z"
      fill="#6B2115"
    />
    <rect x=".5" y="9.33" width="21.33" height="13.33" rx="1" fill="#6B2115" />
    <path
      d="M4.5 13.33h1.81a3 3 0 0 1 .98.14c.26.09.46.2.61.36.15.15.25.32.31.52s.09.4.09.61c0 .23-.03.45-.09.66a1.3 1.3 0 0 1-.32.55 1.57 1.57 0 0 1-.6.37c-.25.1-.57.14-.96.14h-.96v1.99H4.5v-5.34Zm1.8 2.6c.23 0 .42-.03.56-.07a.85.85 0 0 0 .34-.19.65.65 0 0 0 .17-.3c.04-.11.05-.24.05-.39 0-.15-.01-.28-.05-.38a.58.58 0 0 0-.18-.29.84.84 0 0 0-.35-.17 2.06 2.06 0 0 0-.54-.06h-.93v1.85h.94Zm4.6 1.97c.3 0 .57-.05.79-.14.22-.09.4-.22.53-.39.14-.17.25-.37.31-.6.06-.24.1-.5.1-.8 0-.58-.13-1.04-.37-1.37-.25-.33-.65-.5-1.2-.5h-1.02v3.8h.86Zm.15-4.57c.38 0 .73.06 1.04.17a1.99 1.99 0 0 1 1.28 1.31c.12.33.18.72.18 1.16 0 .44-.07.83-.19 1.17a2.28 2.28 0 0 1-.53.85c-.23.22-.51.39-.84.5-.33.12-.7.18-1.12.18H9.15v-5.34h1.9Zm6.78 0v.78h-2.46v1.5h2.32v.8h-2.32v2.26h-.89v-5.34h3.35Z"
      fill="#fff"
    />
  </svg>
);

export default Pdf;
