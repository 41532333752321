import React from "react";
import { ProfileProvider } from "./context/ProfileProvider";
import Profile from "./Profile";

const App = props => {
  const {
    getAccessTokenCallback,
    proxyUrl,
    user,
    onError,
    lightAppsUrl,
    ...profileProps
  } = props;
  return (
    <ProfileProvider
      getAccessTokenCallback={getAccessTokenCallback}
      lightAppsUrl={lightAppsUrl}
      proxyUrl={proxyUrl}
      user={user}
      onError={onError}
    >
      <Profile {...profileProps} />
    </ProfileProvider>
  );
};

export default App;
