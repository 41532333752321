import React, { Fragment, forwardRef } from "react";
import { Card, Title, Wrapper } from "./HistoryListCardStyles";
import PropTypes from "prop-types";
import { ListCardSkeleton } from "../listCardSkeleton/ListCardSkeleton";

export const HistoryListCard = forwardRef(
  ({ isLoading, title, attrValue, children }, ref) => (
    <Card ref={ref} data-attributes={attrValue}>
      {isLoading ? (
        <ListCardSkeleton />
      ) : (
        <>
          <Title>{title}</Title>
          <Wrapper>{children}</Wrapper>
        </>
      )}
    </Card>
  )
);

HistoryListCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  attrValue: PropTypes.string,
};
