import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import {
  Card,
  ImageWrapper,
  UserTitleWrapper,
  UserName,
  AvatarWrapper,
  ButtonsGroup,
  ContactActions,
  Line,
  UserCompany,
  UserInfoWrapper,
  UserJobTitle,
  OneErpButtonLink,
  TeamsButtonLink,
  ContactActionButton,
  BgImage,
  JobTitleTooltip,
  JobTitleFullText,
  JobTitleWrapper,
  ExportPdfButton,
  EmployeeNumber,
} from "./styles/userCard";

import { ArrowTip } from "./styles/contactDetailsTooltip";

import { Avatar } from "../utils/Avatar";
import {
  SkeletonRect,
  SkeletonRound,
  SkeletonTextElement,
} from "../utils/Loaders/SkeletonPulse";
import ContactDetailsTooltip from "./ContactDetailsTooltip";
import { MSTeams, Phone, Email, Info, Pdf } from "./icons";
import { noop } from "../utils/utils";
import { EmptyCard } from "../utils/EmptyCard/index";
import { useUserAvatar } from "../hooks/useUserAvatar";
import {
  MY_PROFILE_PAGE_SECTION_PROFILE_CARD,
  EMPLOYEEPROFILE_PAGE_PROFILEDOC,
} from "../auto-test-constants";

const contactActions = [Phone, Email, Info];

function UserCard({
  position,
  department,
  division,
  company,
  location,
  employeeNumber,
  email,
  telephoneExtension,
  mobileNo,
  directorate,
  isLoading,
  isMobile,
  firstName,
  lastName,
  title,
  onTeamsClick,
  onOneErpClick,
  onUserProfileClick,
  profileHeader,
  externalUrls,
  userPresenceStatus,
  onMailClick,
  onExportPdfButtonClick,
  profileDownloadUrl,
}) {
  const jobTitle = [position, department, division]
    .filter(item => Boolean(item))
    .join(", ");
  const [areContactsShown, setShowContacts] = useState(false);
  const [userAvatar] = useUserAvatar(profileHeader?.email);

  const tooltipEl = useRef(null);
  const jobTitleEl = useRef(null);

  const [visible, setVisible] = useState(false);

  const { msTeamsUrl, oneErpUrl } = externalUrls;

  const toggleContactsTooltip = () => {
    setShowContacts(!areContactsShown);
  };

  const handleResize = () => {
    const tooltipElHeight = tooltipEl.current && tooltipEl.current.clientHeight;
    const jobTitleHeight =
      jobTitleEl.current && jobTitleEl.current.clientHeight;
    setVisible(tooltipElHeight > jobTitleHeight);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const onTeamsClickHandler = () => {
    if (onTeamsClick) onTeamsClick(msTeamsUrl);
  };

  const onOneErpClickHandler = () => {
    if (onOneErpClick) onOneErpClick(oneErpUrl);
  };

  const onUserProfileClickHandler = () => {
    if (onUserProfileClick) onUserProfileClick(profileHeader);
  };

  const skeleton = (
    <>
      <SkeletonTextElement width={143} height={16} margin={16} />
      <SkeletonTextElement width={207} height={16} margin={13} />
      <SkeletonTextElement width={157} height={16} margin={51} />
      <SkeletonTextElement width={157} height={16} margin={20} />
    </>
  );

  const isEmpty = [firstName, lastName, jobTitle, company].every(item => !item);
  const hasName = firstName || lastName;
  const shouldShowExportPdfButton = Boolean(profileDownloadUrl);

  return (
    <Card data-attributes={MY_PROFILE_PAGE_SECTION_PROFILE_CARD}>
      <ImageWrapper>
        {isLoading ? <SkeletonRect height={96} /> : <BgImage />}
      </ImageWrapper>
      <UserInfoWrapper>
        <AvatarWrapper>
          {isLoading ? (
            <SkeletonRound size={72} />
          ) : (
            <Avatar
              src={userAvatar}
              name={firstName}
              surname={lastName}
              size={72}
              status={userPresenceStatus}
              statusSize={20}
              isClickable={!!onUserProfileClick}
              onClick={onUserProfileClickHandler}
              noData={isEmpty}
            />
          )}
        </AvatarWrapper>
        {isLoading ? (
          skeleton
        ) : isEmpty ? (
          <EmptyCard paddingBottom={44} paddingTop={16} />
        ) : (
          <>
            {(hasName || !!employeeNumber) && (
              <UserTitleWrapper>
                {hasName && (
                  <UserName>
                    {title ? `${title} ` : ""} {firstName} {lastName}
                  </UserName>
                )}
                {!!employeeNumber && (
                  <EmployeeNumber>ID: {employeeNumber}</EmployeeNumber>
                )}
              </UserTitleWrapper>
            )}
            {jobTitle && (
              <JobTitleWrapper>
                <UserJobTitle ref={jobTitleEl} visible={visible}>
                  {jobTitle}
                </UserJobTitle>
                <JobTitleTooltip>
                  <ArrowTip />
                  <JobTitleFullText ref={tooltipEl}>
                    {jobTitle}
                  </JobTitleFullText>
                </JobTitleTooltip>
              </JobTitleWrapper>
            )}
            <UserCompany>{company}</UserCompany>
          </>
        )}
        {!isLoading && (
          <ContactActions>
            {contactActions.map(Action => (
              <ContactActionButton
                key={`${Action.toString()}`}
                onClick={toggleContactsTooltip}
              >
                <Action
                  shouldApplyHoverStyles={!isMobile}
                  isActive={areContactsShown}
                />
              </ContactActionButton>
            ))}
            <ContactDetailsTooltip
              isOpen={areContactsShown}
              location={location}
              email={email}
              telephoneExtension={telephoneExtension}
              mobileNo={mobileNo}
              directorate={directorate}
              hideTooltip={toggleContactsTooltip}
              onMailClick={onMailClick}
            />
          </ContactActions>
        )}
        <Line />
        <ButtonsGroup>
          {isLoading ? (
            <SkeletonTextElement
              height={16}
              width={157}
              css={{ marginTop: "10px" }}
            />
          ) : (
            <>
              {msTeamsUrl && (
                <TeamsButtonLink onClick={onTeamsClickHandler}>
                  <MSTeams />
                </TeamsButtonLink>
              )}
              {oneErpUrl && (
                <OneErpButtonLink onClick={onOneErpClickHandler}>
                  ONE ERP
                </OneErpButtonLink>
              )}
              {shouldShowExportPdfButton && (
                <ExportPdfButton
                  onClick={onExportPdfButtonClick}
                  data-attributes={EMPLOYEEPROFILE_PAGE_PROFILEDOC}
                >
                  <Pdf />
                </ExportPdfButton>
              )}
            </>
          )}
        </ButtonsGroup>
      </UserInfoWrapper>
    </Card>
  );
}

UserCard.propTypes = {
  managerFullName: PropTypes.string,
  userPresenceStatus: PropTypes.string.isRequired,
  position: PropTypes.string,
  department: PropTypes.string,
  division: PropTypes.string,
  company: PropTypes.string,
  userAvatar: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  employeeNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  email: PropTypes.string,
  telephoneExtension: PropTypes.string,
  mobileNo: PropTypes.string,
  directorate: PropTypes.string,
  profileDownloadUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  onTeamsClick: PropTypes.func,
  onOneErpClick: PropTypes.func,
  onUserProfileClick: PropTypes.func,
  externalUrls: PropTypes.shape({
    msTeamsUrl: PropTypes.string,
    oneErpUrl: PropTypes.string,
  }),
  onMailClick: PropTypes.func.isRequired,
  shouldShowExportPdfButton: PropTypes.bool.isRequired,
  onExportPdfButtonClick: PropTypes.func.isRequired,
};

UserCard.defaultProps = {
  managerFullName: "",
  position: "",
  department: "",
  division: "",
  company: "",
  userAvatar: "",
  location: "",
  firstName: "",
  lastName: "",
  title: "",
  employeeNumber: "",
  email: "",
  telephoneExtension: "",
  mobileNo: "",
  directorate: "",
  profileDownloadUrl: "",
  isLoading: false,
  isMobile: false,
  onTeamsClick: noop,
  onOneErpClick: noop,
  onUserProfileClick: undefined,
  externalUrls: {},
};

export default UserCard;
