import React from "react";

export const PPUBIcon = props => (
  <svg
    width="41"
    height="36"
    viewBox="0 0 41 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="11" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M11 27h30v7c0 1.1046-.8954 2-2 2H13c-1.1046 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M32 27V0l7-3e-7C40.1046-3.6e-7 41 .89543 41 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="34" height="19.5" rx="2" fill="#005871" />
    <path
      d="M4.76 15.61h2.47c.54 0 .98333.06 1.33.18.35333.12.63.28.83.48.2.2.34.4333.42.7.08.26.12.5333.12.82 0 .3067-.04.6-.12.88-.08.28-.22333.5267-.43.74-.2.2067-.47333.3733-.82.5-.34667.12-.78333.18-1.31.18h-1.3v2.66H4.76v-7.14Zm2.46 3.47c.30667 0 .55667-.03.75-.09.2-.06.35667-.1433.47-.25.11333-.1133.19-.2467.23-.4.04667-.1533.07-.3267.07-.52 0-.2-.02333-.3733-.07-.52-.04667-.1533-.13-.28-.25-.38-.11333-.1-.27-.1767-.47-.23-.19333-.0533-.44-.08-.74-.08H5.95v2.47h1.27Zm3.8681-3.47h2.47c.54 0 .9834.06 1.33.18.3534.12.63.28.83.48.2.2.34.4333.42.7.08.26.12.5333.12.82 0 .3067-.04.6-.12.88-.08.28-.2233.5267-.43.74-.2.2067-.4733.3733-.82.5-.3466.12-.7833.18-1.31.18h-1.3v2.66h-1.19v-7.14Zm2.46 3.47c.3067 0 .5567-.03.75-.09.2-.06.3567-.1433.47-.25.1134-.1133.19-.2467.23-.4.0467-.1533.07-.3267.07-.52 0-.2-.0233-.3733-.07-.52-.0466-.1533-.13-.28-.25-.38-.1133-.1-.27-.1767-.47-.23-.1933-.0533-.44-.08-.74-.08h-1.26v2.47h1.27Zm4.9582-3.47v4.28c0 .6733.12 1.1767.36 1.51.2466.3267.66.49 1.24.49.5733 0 .9766-.1633 1.21-.49.24-.3333.36-.8367.36-1.51v-4.28h1.21v4.28c0 .5533-.0667 1.0233-.2 1.41-.1334.38-.3234.6933-.57.94-.2467.24-.54.4133-.88.52-.34.1133-.72.17-1.14.17-.9134 0-1.6067-.2433-2.08-.73-.4734-.4933-.71-1.2733-.71-2.34v-4.25h1.2Zm5.7947 0h2.47c.52 0 .9467.05 1.28.15.3333.0933.5967.2267.79.4.2.1667.3367.3667.41.6.0733.2267.11.47.11.73 0 .3-.0667.58-.2.84-.1333.2533-.3767.4633-.73.63.2267.1.4133.2133.56.34.1467.1267.2633.2633.35.41.0867.14.1467.29.18.45.0333.1533.05.31.05.47 0 .2933-.0467.57-.14.83-.0867.26-.2333.4867-.44.68-.2067.1867-.4767.3367-.81.45-.3333.1067-.7433.16-1.23.16h-2.65v-7.14Zm2.61 2.93c.4733 0 .8033-.0867.99-.26.1867-.1733.28-.4233.28-.75 0-.34-.11-.5833-.33-.73-.2133-.1533-.5867-.23-1.12-.23h-1.26v1.97h1.44Zm0 3.24c.2867 0 .5233-.0267.71-.08.1933-.0533.3433-.13.45-.23.1133-.1.1933-.22.24-.36.0467-.14.07-.2967.07-.47 0-.36-.1033-.6333-.31-.82-.2-.1933-.55-.29-1.05-.29h-1.55v2.25h1.44Z"
      fill="#fff"
    />
  </svg>
);
