import React from "react";
import { SvgBlueHover } from "../styles/icons";

const Info = ({ shouldApplyHoverStyles, isActive }) => (
  <SvgBlueHover
    isActive={isActive}
    shouldApplyHoverStyles={shouldApplyHoverStyles}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12.5V8.5"
      stroke="#9D9FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.95996 5.5C8.82189 5.5 8.70996 5.61193 8.70996 5.75C8.70996 5.88807 8.82189 6 8.95996 6C9.09803 6 9.20996 5.88807 9.20996 5.75C9.20996 5.61193 9.09803 5.5 8.95996 5.5V5.5"
      stroke="#9D9FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      stroke="#9D9FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgBlueHover>
);

export default Info;
