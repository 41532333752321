import React from "react";

export const ONEIcon = props => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8 27h30v7c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29 27V0l7-3e-7C37.1046-3.6e-7 38 .895429 38 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="29" height="19.5" rx="2" fill="#1859A9" />
    <path
      d="M7.87 22.93c-.51333 0-.97667-.0833-1.39-.25-.40667-.1733-.75667-.42-1.05-.74-.29333-.32-.52-.71-.68-1.17-.15333-.4667-.23-.9933-.23-1.58 0-.5867.08-1.1133.24-1.58.16-.4667.38667-.86.68-1.18.29333-.3267.64667-.5733 1.06-.74.41333-.1733.87-.26 1.37-.26.50667 0 .96667.0867 1.38.26.41333.1667.7633.41 1.05.73.2867.32.5067.7133.66 1.18.16.46.24.9833.24 1.57 0 .5933-.0767 1.1233-.23 1.59-.1533.46-.3733.8533-.66 1.18-.2867.32-.63667.5667-1.05.74-.40667.1667-.87.25-1.39.25Zm-.01-1.04c.68667 0 1.20667-.24 1.56-.72.35333-.4867.53-1.15.53-1.99 0-.42-.04667-.7967-.14-1.13-.08667-.3333-.21667-.6167-.39-.85-.17333-.2333-.39-.4133-.65-.54-.26-.1267-.56333-.19-.91-.19-.34667 0-.65.0633-.91.19s-.48.3067-.66.54c-.17333.2333-.30333.5167-.39.85-.08667.3333-.13.71-.13 1.13 0 .84.17333 1.5033.52 1.99.35333.48.87667.72 1.57.72Zm4.6246-6.28h1.12l3.28 5.16v-5.16h1.18v7.14h-1.12l-3.29-5.14v5.14h-1.17v-7.14Zm7.0996 0h4.75v1.04h-3.54v1.9h3.34v1.05h-3.34v2.11h3.73l-.14 1.04h-4.8v-7.14Z"
      fill="#fff"
    />
  </svg>
);
