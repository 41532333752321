import styled from "styled-components";
import { absoluteCentered, multiLineEllipsis } from "../../utils/mixins";

export const ArrowTip = styled.div`
  width: 0;
  height: 0;

  position: absolute;
  left: calc(50% - 8px);
  top: -8px;

  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid ${({ theme }) => theme.colors.white};
`;

export const ContactDetails = styled.ul`
  position: absolute;
  top: 30px;
  ${absoluteCentered}

  display: flex;
  flex-direction: column;

  width: 280px;
  padding: 22px 24px;

  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};

  font-weight: ${({ theme }) => theme.fontWeight.regular};

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.13), 0px 2px 20px rgba(0, 0, 0, 0.1) !important;

  -webkit-appearance: none;
  z-index: ${({ theme }) => theme.variables.profileTooltipZIndex};

  & svg {
    width: 16px;
    text-align: center;
    flex-shrink: 0;
  }
`;

export const ContactElement = styled.li`
  list-style: none;
  display: flex;

  margin-bottom: 12px;

  font-size: 14px;
  line-height: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ContactText = styled.div`
  margin-left: 8px;
  text-align: left;
  word-break: break-all;

  ${multiLineEllipsis({ lineClamp: 1 })}
`;

export const StyledLink = styled(ContactText)`
  cursor: pointer;
  text-decoration: auto;
  color: ${({ theme }) => theme.colors.royalBlue};
`;
