export const DATE_FORMAT_TYPE = "DD MMM, YYYY";
export const WORK_DATE_FORMAT_TYPE = "MMM YYYY";

export const WEB_VIEW_MODE = "web-view";
export const WIDGET_MODE = "widget";

export const END_OF_SERVICE_BENEFIT_TITLE = "End Of Service Benefit";
export const DEFAULT_CURRENCY = "AED";

export const ORG_CHART_LINK =
  "https://www.theofficialboard.com/org-chart/adnoc-1";

export const TITLES = {
  PERSONAL_INFORMATION: "Personal information",
  EMPLOYEE_INFORMATION: "Employee information",
  SALARY_INFORMATION: "Salary information",
  REPORTING_TO: "Reporting to",
  REPORTING: "Reporting",
  JOB_HISTORY: "Job History",
  TEAM: "Team",
  PERFORMANCE_RATING_HISTORY: "Performance Rating History",
  POSITION_HISTORY: "Position History",
  EDUCATION_AND_QUALIFICATION: "Education & Qualification History",
  GRADE_HISTORY: "Grade History",
  TRAINING_HISTORY: "Training History",
  LANGUAGE_PROFICIENCY_LEVEL: "Language Proficiency Levels",
};

export const PERSONAL_INFORMATION_TITLES = {
  fullName: "Full name",
  birth: "Birth Date",
  nationality: "Nationality",
  gender: "Gender",
  maritalStatus: "Marital Status",
  age: "Age",

  // fallback personal information
  firstName: "Name",
  lastName: "Surname",
  location: "Office Location",
  company: "Company Name",
  position: "Job Title",
  department: "Department",
  email: "E-Mail",
  mobileNo: "Phone",
  bloodGroup: "Blood Group",
};

export const EMPLOYEE_INFORMATION_TITLES = {
  grade: "Personal Grade",
  jobGrade: "Job Grade",
  lastPromotion: "Last Promotion",
  employmentType: "Employment Type",
  employmentSubGroup: "Employment Subgroup",
  currentPosition: "Current Position",
  totalService: "Total Service",
  lastSalary: "Last Salary",
};

export const REPORTING_TO_TITLES = {
  download: "Download",
  orgChart: "Org chart",
  seeMore: "See more",
  seeLess: "See less",
  teamBalance: "Team balance",
};

export const USER_PRESENCE_STATUSES = {
  Available: "Available",
  AvailableIdle: "AvailableIdle",
  Away: "Away",
  BeRightBack: "BeRightBack",
  Busy: "Busy",
  BusyIdle: "BusyIdle",
  DoNotDisturb: "DoNotDisturb",
  Offline: "Offline",
  PresenceUnknown: "PresenceUnknown",
};

export const PROFILE_ERROR_TYPES = {
  ProfileError: "ProfileError",
  ActivityError: "ActivityError",
  UserPresenceError: "UserPresenceError",
  UserAvatarError: "UserAvatarError",
};

export const DEFAULT_USER_PRESENCE_STATUS =
  USER_PRESENCE_STATUSES.PresenceUnknown;

export const INDICATOR_BLOCKS = [1, 2, 3, 4];

export const NUMBER_OF_SECONDS_IN_HOUR = 3600;
export const NUMBER_OF_SECONDS_IN_MINUTE = 60;

export const PAGE_LAYOUT_GAP = 24;

export const PARSE_DATE_FORMAT = "YYYY-MM-DD";
export const OUTPUT_GET_PERIOD_DATE_FORMAT = "D MMM";
export const FULL_DATE_FORMAT = "DD MMM, YYYY";

export const MY_PROFILE_SUB_URL = "me";

export const NO_CONTENT_STATUS_CODE = 204;
export const NO_CONTENT_ERROR = "No content";
