import React from "react";
import { SvgBlueHover } from "../styles/icons";

const Phone = ({ shouldApplyHoverStyles, isActive }) => (
  <SvgBlueHover
    isActive={isActive}
    shouldApplyHoverStyles={shouldApplyHoverStyles}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0255 15.9182L11.0335 15.9229C12.4919 16.8515 14.3997 16.6422 15.6223 15.4196L16.1382 14.9037C16.618 14.4233 16.618 13.645 16.1382 13.1646L13.9645 10.9921C13.484 10.5123 12.7057 10.5123 12.2253 10.9921V10.9921C11.9949 11.2229 11.6822 11.3525 11.3561 11.3525C11.03 11.3525 10.7173 11.2229 10.4868 10.9921L7.00858 7.51321C6.52876 7.03279 6.52876 6.2545 7.00858 5.77408V5.77408C7.2393 5.54364 7.36894 5.23093 7.36894 4.90485C7.36894 4.57876 7.2393 4.26605 7.00858 4.03561L4.83549 1.85987C4.35507 1.38004 3.57678 1.38004 3.09636 1.85987L2.58042 2.37581C1.35808 3.59843 1.14861 5.50585 2.07648 6.96461L2.08181 6.97261C4.46381 10.4987 7.49984 13.5354 11.0255 15.9182V15.9182Z"
      stroke="#9D9FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgBlueHover>
);

export default Phone;
