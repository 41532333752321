import React from "react";

export const EMLIcon = props => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8 27h30v7c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29 27V0l7-3e-7C37.1046-3.6e-7 38 .895429 38 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="29" height="19.5" rx="2" fill="#303030" />
    <path
      d="M4.76 15.61h4.75v1.04H5.97v1.9h3.34v1.05H5.97v2.11H9.7l-.14 1.04h-4.8v-7.14Zm6.084 0h1.47l2.03 5.68 2.09-5.68h1.42v7.14h-1.11v-5.19l-1.96 5.19h-.96l-1.89-5.18v5.18h-1.09v-7.14Zm9.7354 0v6.09h3.54l-.15 1.05h-4.6v-7.14h1.21Z"
      fill="#fff"
    />
  </svg>
);
