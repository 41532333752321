import React from "react";

export default function PhoneClock() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 3.125V5H16.875M10.3083 18.7833L10.3166 18.7892C11.0367 19.2476 11.8916 19.4467 12.7401 19.3535C13.5886 19.2604 14.3799 18.8805 14.9833 18.2767L15.5075 17.7525C15.6236 17.6364 15.7158 17.4986 15.7786 17.3469C15.8415 17.1952 15.8739 17.0326 15.8739 16.8683C15.8739 16.7041 15.8415 16.5415 15.7786 16.3898C15.7158 16.2381 15.6236 16.1002 15.5075 15.9842L13.2966 13.775C13.1805 13.6588 13.0427 13.5667 12.891 13.5038C12.7393 13.441 12.5767 13.4086 12.4125 13.4086C12.2482 13.4086 12.0856 13.441 11.9339 13.5038C11.7822 13.5667 11.6444 13.6588 11.5283 13.775C11.2939 14.0093 10.976 14.141 10.6445 14.141C10.3131 14.141 9.9952 14.0093 9.76079 13.775L6.22579 10.2392C5.99145 10.0048 5.85981 9.68687 5.85981 9.35542C5.85981 9.02396 5.99145 8.70608 6.22579 8.47167C6.34195 8.35558 6.43409 8.21775 6.49696 8.06604C6.55983 7.91433 6.59219 7.75172 6.59219 7.5875C6.59219 7.42328 6.55983 7.26067 6.49696 7.10896C6.43409 6.95725 6.34195 6.81942 6.22579 6.70333L4.01579 4.49417C3.78138 4.25983 3.46349 4.12818 3.13204 4.12818C2.80058 4.12818 2.4827 4.25983 2.24829 4.49417L1.72329 5.01833C1.11961 5.62182 0.739889 6.41319 0.646857 7.26169C0.553824 8.1102 0.753053 8.96505 1.21162 9.685L1.21662 9.69333C3.63844 13.2765 6.72469 16.3622 10.3083 18.7833ZM10.625 5C10.625 5.57453 10.7381 6.14344 10.958 6.67424C11.1778 7.20504 11.5001 7.68734 11.9064 8.09359C12.3126 8.49985 12.7949 8.82211 13.3257 9.04197C13.8565 9.26184 14.4254 9.375 15 9.375C15.5745 9.375 16.1434 9.26184 16.6742 9.04197C17.205 8.82211 17.6873 8.49985 18.0935 8.09359C18.4998 7.68734 18.8221 7.20504 19.0419 6.67424C19.2618 6.14344 19.375 5.57453 19.375 5C19.375 4.42547 19.2618 3.85656 19.0419 3.32576C18.8221 2.79496 18.4998 2.31266 18.0935 1.90641C17.6873 1.50015 17.205 1.17789 16.6742 0.958027C16.1434 0.738163 15.5745 0.625 15 0.625C14.4254 0.625 13.8565 0.738163 13.3257 0.958027C12.7949 1.17789 12.3126 1.50015 11.9064 1.90641C11.5001 2.31266 11.1778 2.79496 10.958 3.32576C10.7381 3.85656 10.625 4.42547 10.625 5Z"
        stroke="#1859A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
