import styled, { css } from "styled-components";
import { CardLayout, CardTitle } from "../../styles";
import { TableCell } from "./table";

export const Card = styled(CardLayout)`
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => spacing.xm} 0;
`;

export const Title = styled(CardTitle)`
  margin-left: ${({ theme: { spacing } }) => spacing.xm};
  margin-bottom: ${({ theme: { spacing } }) => spacing.xm};
`;

export const YearCell = styled(TableCell)`
  width: 96px;
`;

export const ShowMoreButton = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  background-color: transparent;
  cursor: pointer;
  align-self: flex-start;

  ${({
    theme: {
      fontWeight: { medium },
      colors: { royalBlue },
      spacing: { xm },
      fontFamily: { regularAdnocFontFamily },
    },
  }) =>
    css`
      font-weight: ${medium};
      color: ${royalBlue};
      margin-left: ${xm};
      font-family: ${regularAdnocFontFamily};
    `};
`;
