import React from "react";
import PropTypes from "prop-types";
import styles from "./Spinner.module.css";
import SpinnerIcon from "./icons/spinner";

const DEFAULT_SPINNER_SIZE = 40;

export const Spinner = ({ size }) => {
  return (
    <div className={styles.loader}>
      <SpinnerIcon size={size} />
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.number,
};
Spinner.defaultProps = {
  size: DEFAULT_SPINNER_SIZE,
};

export const CenteredSpinner = ({ size }) => {
  return (
    <div className={styles.container}>
      <Spinner size={size} />
    </div>
  );
};

CenteredSpinner.propTypes = {
  size: PropTypes.number,
};
CenteredSpinner.defaultProps = {
  size: DEFAULT_SPINNER_SIZE,
};
