import React from "react";
import styled from "styled-components";

const EmptyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : "32px")};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px` : 0};
  padding-right: ${({ paddingRight }) =>
    paddingRight ? `${paddingRight}px` : 0};
`;

const EmptyCardHeader = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const EmptyCardContent = styled.div`
  font-size: 14px;
  line-height: 20px;
  width: 230px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayDark};
`;

function EmptyCard(props) {
  return (
    <EmptyCardWrapper {...props}>
      <EmptyCardHeader>No data available</EmptyCardHeader>
      <EmptyCardContent>
        There seems to be no data available to show
      </EmptyCardContent>
    </EmptyCardWrapper>
  );
}

export default EmptyCard;
