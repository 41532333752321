import React from "react";

const Away = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8" fill="#FAA22C" stroke="white" strokeWidth="2" />
    <path
      d="M11.5898 11.6602C11.6628 11.7331 11.7188 11.819 11.7578 11.918C11.7969 12.0143 11.8164 12.1133 11.8164 12.2148C11.8164 12.3242 11.7956 12.4271 11.7539 12.5234C11.7148 12.6172 11.6602 12.6992 11.5898 12.7695C11.5195 12.8398 11.4362 12.8958 11.3398 12.9375C11.2461 12.9792 11.1445 13 11.0352 13C10.819 13 10.6341 12.9232 10.4805 12.7695L8.16016 10.4531C8.08724 10.3802 8.03125 10.2956 7.99219 10.1992C7.95312 10.1003 7.93359 10 7.93359 9.89844V5.78516C7.93359 5.67578 7.95312 5.57422 7.99219 5.48047C8.03385 5.38411 8.08984 5.30078 8.16016 5.23047C8.23047 5.16016 8.3125 5.10417 8.40625 5.0625C8.5026 5.02083 8.60547 5 8.71484 5C8.82422 5 8.92578 5.02083 9.01953 5.0625C9.11589 5.10417 9.19922 5.16016 9.26953 5.23047C9.33984 5.30078 9.39583 5.38411 9.4375 5.48047C9.47917 5.57422 9.5 5.67578 9.5 5.78516V9.57422L11.5898 11.6602Z"
      fill="white"
    />
  </svg>
);

export default Away;
