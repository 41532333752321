import React from "react";
import { monthsAbbreviated } from "../DatePicker.constants";
import styles from "../DatePicker.module.css";

export const MonthsView = ({ onSelect }) => {
  return (
    <div className={styles.monthView}>
      {monthsAbbreviated.map((month, i) => (
        <button
          key={month}
          className={styles.monthSelector}
          onClick={() => onSelect(i)}
        >
          {month}
        </button>
      ))}
    </div>
  );
};
