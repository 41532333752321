import React from "react";
import PropTypes from "prop-types";
import InformationCard from "./InformationCard";
import { PERSONAL_INFORMATION_TITLES, TITLES } from "../constants";
import { PersonalInfo } from "./icons";
import { Link } from "./styles/fallbackPersonalInformation";

function FallbackPersonalInformation({ isLoading, ...props }) {
  const {
    firstName,
    lastName,
    location,
    company,
    position,
    department,
    email,
    mobileNo,
  } = props;

  const isEmpty = [
    firstName,
    lastName,
    location,
    company,
    position,
    department,
    email,
    mobileNo,
  ].every(item => !item);

  if (isEmpty) return null;

  const personalInfoList = [
    {
      title: PERSONAL_INFORMATION_TITLES.firstName,
      value: firstName,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.lastName,
      value: lastName,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.position,
      value: position,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.company,
      value: company,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.department,
      value: department,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.location,
      value: location,
    },
    {
      title: PERSONAL_INFORMATION_TITLES.email,
      value: (
        <Link href={`mailto:${email}`} target="_blank">
          {email}
        </Link>
      ),
    },
    {
      title: PERSONAL_INFORMATION_TITLES.mobileNo,
      value: <Link href={`tel:${mobileNo}`}>{mobileNo}</Link>,
    },
  ];

  return (
    <InformationCard
      info={personalInfoList}
      title={TITLES.PERSONAL_INFORMATION}
      icon={PersonalInfo}
      isLoading={isLoading}
    />
  );
}

FallbackPersonalInformation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default FallbackPersonalInformation;
