import React from "react";
import { CollapsibleList } from "../collapsibleList/CollapsibleList";
import {
  LanguageProficiencyCardWrapper,
  LanguageProficiencyLevelsWrapper,
} from "./LanguageProficiencyLevelsStyles";
import { LanguageProficiencyCard } from "./LanguageProficiencyCard";

export const LanguageProficiencyListView = ({
  languageProficiencyItems = [],
  defaultAmountOfEntries,
  onScroll,
}) => {
  return (
    <LanguageProficiencyLevelsWrapper>
      <CollapsibleList
        items={languageProficiencyItems}
        defaultAmountOfEntries={defaultAmountOfEntries}
        onCollapse={onScroll}
      >
        {listItems =>
          listItems.map(
            ({
              languageTxt,
              writingLevelTxt,
              readingLevelTxt,
              speakingLevelTxt,
            }) => (
              <LanguageProficiencyCardWrapper key={languageTxt}>
                <LanguageProficiencyCard
                  languageTxt={languageTxt}
                  writingLevelTxt={writingLevelTxt}
                  readingLevelTxt={readingLevelTxt}
                  speakingLevelTxt={speakingLevelTxt}
                />
              </LanguageProficiencyCardWrapper>
            )
          )
        }
      </CollapsibleList>
    </LanguageProficiencyLevelsWrapper>
  );
};
