import React, { useMemo, useState, Fragment } from "react";
import { ButtonWrapper, ShowMoreButton } from "./CollapsibleListStyles";
import PropTypes from "prop-types";
import { noop } from "../../utils/utils";

export const CollapsibleList = ({
  defaultAmountOfEntries,
  items,
  children,
  onCollapse,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const itemsToShow = useMemo(
    () =>
      items.length > defaultAmountOfEntries && !isCollapsed
        ? items
        : items.slice(0, defaultAmountOfEntries),
    [items, defaultAmountOfEntries, isCollapsed]
  );

  const handleClick = () => {
    setIsCollapsed(previous => !previous);
    if (!isCollapsed) onCollapse();
  };

  return (
    <>
      {children(itemsToShow, items.length)}
      {items.length > defaultAmountOfEntries && (
        <ButtonWrapper>
          <ShowMoreButton onClick={handleClick}>
            Show {isCollapsed ? "more" : "less"}
          </ShowMoreButton>
        </ButtonWrapper>
      )}
    </>
  );
};

CollapsibleList.propTypes = {
  defaultAmountOfEntries: PropTypes.number,
  children: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCollapse: PropTypes.func,
};

CollapsibleList.defaultProps = {
  defaultAmountOfEntries: 3,
  onCollapse: noop,
};
