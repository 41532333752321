export const getToken = fetchTokenCallback => {
  return new Promise((resolve, reject) => {
    try {
      fetchTokenCallback()
        .then(token => resolve(token))
        .catch(error => reject(error));
    } catch (error) {
      reject(error);
    }
  });
};
