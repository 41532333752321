import React from "react";

export const UnknownIcon = props => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8 27h30v7c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29 27V0l7-3e-7C37.1046-3.6e-7 38 .895429 38 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="22" height="19.5" rx="2" fill="#303030" />
    <path
      d="M11.03 21.38v1.37H9.86v-1.37h1.17Zm-1.14-.98c0-.18.02333-.3433.07-.49.0533-.1533.12-.29.2-.41.0867-.1267.18-.2467.28-.36.1067-.1133.2167-.2267.33-.34l.29-.29c.1333-.1333.2467-.28.34-.44.0933-.16.14-.3633.14-.61 0-.32-.09-.5667-.27-.74-.1733-.18-.42-.27-.74-.27-.3933 0-.69.09-.89.27-.2.1733-.34667.46-.44.86l-1.05-.14c.04667-.26.12667-.5067.24-.74.11333-.24.26333-.45.45-.63.19333-.18.42667-.32.7-.42.28-.1067.6133-.16 1-.16.6933 0 1.23.17 1.61.51.38.3333.57.7733.57 1.32 0 .32-.05.59-.15.81-.0933.22-.21.4067-.35.56l-.61.61c-.1733.1733-.3167.35-.43.53-.1133.18-.17.3867-.17.62v.15H9.89v-.2Z"
      fill="#fff"
    />
  </svg>
);
