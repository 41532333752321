import React from "react";

const Location = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8C9.38071 8 10.5 6.88071 10.5 5.5C10.5 4.11929 9.38071 3 8 3C6.61929 3 5.5 4.11929 5.5 5.5C5.5 6.88071 6.61929 8 8 8Z"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0.5C10.7614 0.5 13 2.73858 13 5.5C13 7.77133 9.61733 13.4667 8.41667 15.2767C8.32403 15.4163 8.16759 15.5003 8 15.5003C7.83241 15.5003 7.67597 15.4163 7.58333 15.2767C6.38267 13.4667 3 7.77133 3 5.5C3 2.73858 5.23858 0.5 8 0.5Z"
      stroke="#9D9FA0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Location;
