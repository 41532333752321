import { useContext, useEffect, useState } from "react";
import { MY_PROFILE_SUB_URL, PROFILE_ERROR_TYPES } from "../constants";
import { ProfileContext } from "../context/ProfileProvider";

export const useProfile = () => {
  const [isLoading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const { getProfile, onError, user } = useContext(ProfileContext);
  useEffect(() => {
    if (!user) return;
    setLoading(true);
    const query = user === MY_PROFILE_SUB_URL ? "" : user;
    getProfile(query)
      .then(res => setProfileData(res))
      .catch(error => {
        setProfileData({});
        onError(PROFILE_ERROR_TYPES.ProfileError, error);
      })
      .finally(() => setLoading(false));
  }, [setProfileData, setLoading, user, onError]);
  return [profileData, isLoading];
};
