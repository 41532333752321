import styled from "styled-components";
import { CardTitle, CardLayout } from "../../styles/index";

export const Card = styled(CardLayout)`
  flex-direction: column;
  min-width: 352px;
`;

export const Title = styled(CardTitle)`
  display: flex;
  align-items: center;
`;

export const TitleText = styled.div`
  margin-left: ${({ withIcon }) => (withIcon ? "10px" : "0")};
`;

export const InfoListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 8px 0;
`;

export const InfoListTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const InfoList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

export const InfoItem = styled.li`
  list-style: none;
  display: flex;

  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const InfoTitle = styled.div`
  flex: 0 0 135px;
  line-height: 20px;
  margin-right: 25px;
  word-break: break-word;
  color: ${props => props.theme.colors.grayDark};
`;

export const InfoValue = styled.div``;
