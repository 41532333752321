import React from "react";

export default function iInfoIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14.0832V9.4165"
        stroke="#1859A9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.95573 5.9165C9.79465 5.9165 9.66406 6.04709 9.66406 6.20817C9.66406 6.36925 9.79465 6.49984 9.95573 6.49984C10.1168 6.49984 10.2474 6.36925 10.2474 6.20817C10.2474 6.04709 10.1168 5.9165 9.95573 5.9165V5.9165"
        stroke="#1859A9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75Z"
        stroke="#1859A9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
