import React from "react";
import { SkeletonTextElement } from "../../utils/Loaders/SkeletonPulse";
import PropTypes from "prop-types";
import { Wrapper } from "./ListCardSkeletonStyles";

export const ListCardSkeleton = ({ widths }) => (
  <Wrapper>
    <SkeletonTextElement width={143} height={16} margin={24} />
    {widths.map((width, i) => (
      <SkeletonTextElement
        key={`${width}_${i}`}
        width={width}
        height={16}
        margin={16}
      />
    ))}
  </Wrapper>
);

ListCardSkeleton.defaultProps = {
  widths: [185, 219, 185, 143, 216],
};

ListCardSkeleton.propTypes = {
  widths: PropTypes.arrayOf(PropTypes.number),
};
