import {
  NO_CONTENT_ERROR,
  NO_CONTENT_STATUS_CODE,
} from "../../Profile/constants";

export const handleBlobResponse = response => {
  if (response?.status === NO_CONTENT_STATUS_CODE) {
    throw new Error(NO_CONTENT_ERROR);
  }
  return response.blob().then(blob => URL.createObjectURL(blob));
};
