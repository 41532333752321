import React from "react";

export const TXTIcon = props => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" width="30" height="36" rx="2" fill="#EBEBEB" />
    <path
      d="M8 27h30v7c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2v-7Z"
      fill="#C8C8C8"
    />
    <path
      d="M29 27V0l7-3e-7C37.1046-3.6e-7 38 .895429 38 2v25h-9Z"
      fill="#DDD"
    />
    <rect y="9" width="28" height="19.5" rx="2" fill="#303030" />
    <path
      d="M6.45 16.66H4.19v-1.05h5.72v1.05H7.66v6.09H6.45v-6.09Zm6.4388 2.32-2.32-3.37h1.38l1.64 2.5 1.66-2.5h1.3l-2.3 3.42 2.58 3.72h-1.39l-1.9-2.82-1.84 2.82h-1.31l2.5-3.77Zm6.4714-2.32h-2.26v-1.05h5.72v1.05h-2.25v6.09h-1.21v-6.09Z"
      fill="#fff"
    />
  </svg>
);
