import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Title,
  MemberCardWrapper,
  TeamListWrapper,
  MemberName,
  MemberPosition,
  MemberIcon,
  TitleWrapper,
  SeeMore,
  TeamQuantity,
} from "./styles/reportingCard";
import { Avatar } from "../utils/Avatar";
import {
  SkeletonRound,
  SkeletonTextElement,
} from "../utils/Loaders/SkeletonPulse";
import {
  DEFAULT_USER_PRESENCE_STATUS,
  REPORTING_TO_TITLES,
  TITLES,
} from "../constants";
import { useMediaForCardsCount, noop, pluralize } from "../utils/utils";
import breakpoints from "../theme/breakpoints";
import { SeeMoreArrow } from "./icons";
import { EmptyCard } from "../utils/EmptyCard/index";
import { useUserAvatar } from "../hooks";

function MemberCard({
  member,
  isLoading,
  onTeamMemberClick,
  status,
  isMobile,
}) {
  const { employeeName, position } = member;

  const firstName = `${employeeName}`.split(" ")[0];
  const lastName = `${employeeName}`.substr(employeeName.indexOf(" ") + 1);

  const onTeamMemberClickHandler = () => {
    if (onTeamMemberClick) onTeamMemberClick(member);
  };

  const [userAvatar] = useUserAvatar(member?.email);

  const skeleton = (
    <>
      <MemberIcon>
        <SkeletonRound size={40} />
      </MemberIcon>
      <SkeletonTextElement width={79} height={16} css={{ marginTop: "6px" }} />
      <SkeletonTextElement width={57} height={16} />
    </>
  );

  return (
    <MemberCardWrapper
      shouldApplyHoverStyles={!isMobile}
      isLoading={isLoading}
      onClick={onTeamMemberClickHandler}
    >
      {isLoading ? (
        skeleton
      ) : (
        <>
          <MemberIcon>
            <Avatar
              src={userAvatar}
              size={40}
              name={employeeName}
              isClickable
              status={status}
              statusSize={12}
            />
          </MemberIcon>
          <MemberName>{firstName}</MemberName>
          <MemberName>{lastName}</MemberName>
          <MemberPosition>{position}</MemberPosition>
        </>
      )}
    </MemberCardWrapper>
  );
}
function MyTeam({
  team,
  isLoading,
  isMobile,
  onTeamMemberClick,
  teamStatuses,
  showAll,
  setShowAll,
}) {
  const { count } = useMediaForCardsCount(breakpoints, [2, 2, 3, 4, 4, 4]);
  const isTeamListAvailable = !!(Array.isArray(team) && team.length);
  const showViewAll = team.length > count;
  const seeMoreHandler = () => setShowAll(!showAll);

  const findUserStatus = member =>
    teamStatuses?.[member.email] || DEFAULT_USER_PRESENCE_STATUS;

  return (
    <>
      <TitleWrapper>
        {isLoading ? (
          <SkeletonTextElement width={143} height={16} margin={16} />
        ) : (
          <>
            <Title>{TITLES.TEAM}</Title>
            <TeamQuantity>{pluralize(team.length, "member")}</TeamQuantity>
          </>
        )}
        {showViewAll && !isLoading && (
          <SeeMore onClick={seeMoreHandler}>
            <div style={{ marginRight: "8px" }}>
              {showAll
                ? REPORTING_TO_TITLES.seeLess
                : REPORTING_TO_TITLES.seeMore}
            </div>
            <SeeMoreArrow shouldRotate={showAll} />
          </SeeMore>
        )}
        {isLoading && (
          <SkeletonTextElement
            width={143}
            height={16}
            margin={15}
            css={{ marginLeft: "auto" }}
          />
        )}
      </TitleWrapper>
      {!isTeamListAvailable ? (
        <EmptyCard paddingTop={20} />
      ) : (
        <TeamListWrapper>
          {team
            .map(member => (
              <MemberCard
                key={member.employeeId}
                member={member}
                isLoading={isLoading}
                isMobile={isMobile}
                onTeamMemberClick={onTeamMemberClick}
                status={findUserStatus(member)}
              />
            ))
            .slice(0, showAll ? team.length : count)}
        </TeamListWrapper>
      )}
    </>
  );
}

MyTeam.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onTeamMemberClick: PropTypes.func,
  team: PropTypes.array,
  teamStatuses: PropTypes.array.isRequired,
};

MyTeam.defaultProps = {
  onTeamMemberClick: noop,
};

export default MyTeam;
