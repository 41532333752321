import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  ShowMoreButton,
  Title,
  YearCell,
} from "./styles/performanceHistoryCard";
import { TITLES } from "../constants";
import { useScrollIntoView } from "../hooks";
import { MY_PROFILE_PAGE_SECTION_PERFORMANCERATINGHISTORY } from "../auto-test-constants";
import {
  RatingTableCell,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "./styles/table";

export default function PerformanceHistoryCard({
  history,
  defaultAmountOfEntries,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { nodeRef } = useScrollIntoView(isCollapsed);

  const entries = useMemo(
    () => (isCollapsed ? history.slice(0, defaultAmountOfEntries) : history),
    [isCollapsed, history, defaultAmountOfEntries]
  );

  const handleShowMoreClick = useCallback(
    () => setIsCollapsed(prev => !prev),
    []
  );

  return (
    <Card
      ref={nodeRef}
      data-attributes={MY_PROFILE_PAGE_SECTION_PERFORMANCERATINGHISTORY}
    >
      <Title>{TITLES.PERFORMANCE_RATING_HISTORY}</Title>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Performance</TableHeaderCell>
            <TableHeaderCell>Year</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {entries.map(({ rating, year }, index) => (
            <TableRow key={`${year}-${index}`}>
              <RatingTableCell>{rating}</RatingTableCell>
              <YearCell>{year}</YearCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {history.length > defaultAmountOfEntries && (
        <ShowMoreButton type="button" onClick={handleShowMoreClick}>
          {isCollapsed
            ? `Show more (${history.length - defaultAmountOfEntries})`
            : "Show less"}
        </ShowMoreButton>
      )}
    </Card>
  );
}

PerformanceHistoryCard.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      rating: PropTypes.string,
      year: PropTypes.string,
    })
  ).isRequired,
  defaultAmountOfEntries: PropTypes.number.isRequired,
};
