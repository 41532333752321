import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { Column, PageLayout, PageWrapper } from "./styles/index";
import { ThemeProvider } from "styled-components";
import theme from "./theme/index";

import {
  EmployeeInformation,
  PersonalInformation,
  ReportingAndTeamCard,
  UserCard,
  FallbackPersonalInformation,
  PerformanceHistoryCard,
  EducationAndQualificationCard,
  EmploymentCard,
  GraduationHistoryCard,
  PositionHistoryCard,
} from "./components";
import {
  DEFAULT_USER_PRESENCE_STATUS,
  MY_PROFILE_SUB_URL,
  WEB_VIEW_MODE,
  WIDGET_MODE,
} from "./constants";
import { noop } from "./utils/utils";
import { ProfileCompleteness } from "./components/ProfileCompleteness";
import { OnlineActivities } from "./components/OnlineActivities";
import { SalaryInformation } from "./components/SalaryInformation";
import { useProfile, useUserPresenceStatus } from "./hooks";
import { ProfileContext } from "./context/ProfileProvider";
import TrainingHistory from "./components/TrainingHistory";
import { LanguageProficiencyLevels } from "./components/languageProficiencyLevels/LanguageProficiencyLevels";

function Profile({
  mode,
  onOneErpClick,
  onUserProfileClick,
  onTeamsClick,
  onReportingToMemberClick,
  onTeamMemberClick,
  onOrgChartClick,
  onMailClick,
  onExportPdfButtonClick,
  onTeamBalanceDownloadClick,
  myProfileMail,
  onGoToEServiceClick,
}) {
  const { user } = useContext(ProfileContext);
  const [profileData, isProfileLoading] = useProfile();

  const isMobile = mode === WEB_VIEW_MODE;

  const {
    educationQualification,
    employeeInformation,
    employeeNumber,
    salaryInformation,
    externalUrls,
    gradeHistory,
    myTeam,
    performanceHistory,
    personalInformation,
    positionHistory,
    previousEmployment,
    profileHeader,
    teamBalanceDownloadUrl,
    profileDownloadUrl,
    completeness,
    trainingHistory,
    languageProficiency: languageProficiencyUnsorted,
  } = profileData;

  const languageProficiency = useMemo(() => {
    if (Array.isArray(languageProficiencyUnsorted)) {
      return languageProficiencyUnsorted.sort(function compare(a, b) {
        if (a.languageTxt < b.languageTxt) {
          return -1;
        }
        if (a.languageTxt > b.languageTxt) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }, [languageProficiencyUnsorted]);

  const emails = useMemo(
    () => [profileHeader?.email, profileHeader?.managerEmailId].filter(Boolean),
    [profileHeader]
  );
  const [presenceStatuses] = useUserPresenceStatus(emails);
  const userPresenceStatus =
    presenceStatuses?.[profileHeader?.email] || DEFAULT_USER_PRESENCE_STATUS;
  const managerPresenceStatus =
    presenceStatuses?.[profileHeader?.managerEmailId] ||
    DEFAULT_USER_PRESENCE_STATUS;

  const isMyProfile = user === MY_PROFILE_SUB_URL || user === myProfileMail;

  const enableFallbackProfile = useMemo(() => {
    if (!personalInformation || typeof personalInformation !== "object") {
      return true;
    }

    const {
      birthDate,
      nationality,
      gender,
      maritalStatus,
      ageYears,
      ageMonths,
      fullName,
    } = personalInformation;

    return [
      birthDate,
      nationality,
      gender,
      maritalStatus,
      ageYears,
      ageMonths,
      fullName,
    ].every(item => !item);
  }, [personalInformation]);

  return (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <PageLayout>
          <Column>
            <UserCard
              userPresenceStatus={userPresenceStatus}
              isLoading={isProfileLoading}
              isMobile={isMobile}
              {...profileHeader}
              externalUrls={externalUrls}
              employeeNumber={employeeNumber}
              onOneErpClick={onOneErpClick}
              onUserProfileClick={onUserProfileClick}
              onTeamsClick={onTeamsClick}
              profileHeader={profileHeader}
              onMailClick={onMailClick}
              onExportPdfButtonClick={onExportPdfButtonClick}
              profileDownloadUrl={profileDownloadUrl}
            />
            <ProfileCompleteness
              completeness={completeness}
              isLoading={isProfileLoading}
              onGoToEServiceClick={onGoToEServiceClick}
              isMobile={isMobile}
            />
            {isMyProfile ? (
              <OnlineActivities isLoading={isProfileLoading} />
            ) : null}
          </Column>
          {enableFallbackProfile ? (
            <FallbackPersonalInformation
              {...profileHeader}
              isLoading={isProfileLoading}
            />
          ) : (
            <PersonalInformation
              {...personalInformation}
              isLoading={isProfileLoading}
            />
          )}
          {!!employeeInformation && (
            <EmployeeInformation
              {...employeeInformation}
              isLoading={isProfileLoading}
            />
          )}
          {!!salaryInformation && (
            <SalaryInformation
              {...salaryInformation}
              isLoading={isProfileLoading}
            />
          )}
          <ReportingAndTeamCard
            isLoading={isProfileLoading}
            reportingToMemberData={profileHeader}
            isMobile={isMobile}
            team={myTeam}
            onReportingToMemberClick={onReportingToMemberClick}
            onTeamMemberClick={onTeamMemberClick}
            onOrgChartClick={onOrgChartClick}
            managerStatus={managerPresenceStatus}
            teamStatuses={presenceStatuses}
            teamBalanceDownloadUrl={teamBalanceDownloadUrl}
            onTeamBalanceDownloadClick={onTeamBalanceDownloadClick}
          />
          <LanguageProficiencyLevels
            languageProficiencyItems={languageProficiency}
            isLoading={isProfileLoading}
            defaultAmountOfEntries={3}
          />
          <EmploymentCard
            employments={previousEmployment}
            isLoading={isProfileLoading}
          />
          {performanceHistory && performanceHistory.length && (
            <PerformanceHistoryCard
              history={performanceHistory}
              defaultAmountOfEntries={3}
            />
          )}
          <PositionHistoryCard
            isLoading={isProfileLoading}
            items={positionHistory}
          />
          <GraduationHistoryCard
            isLoading={isProfileLoading}
            items={gradeHistory}
          />
          <EducationAndQualificationCard
            isLoading={isProfileLoading}
            items={educationQualification}
          />
          <TrainingHistory
            isLoading={isProfileLoading}
            items={trainingHistory}
          />
        </PageLayout>
      </PageWrapper>
    </ThemeProvider>
  );
}

Profile.propTypes = {
  mode: PropTypes.oneOf([WEB_VIEW_MODE, WIDGET_MODE]),
  teamBalanceDownloadUrl: PropTypes.string,
  onReportingToMemberClick: PropTypes.func,
  onTeamsClick: PropTypes.func,
  onOneErpClick: PropTypes.func,
  onUserProfileClick: PropTypes.func,
  onOrgChartClick: PropTypes.func,
  onTeamMemberClick: PropTypes.func,
  onMailClick: PropTypes.func.isRequired,
  onExportPdfButtonClick: PropTypes.func.isRequired,
  onTeamBalanceDownloadClick: PropTypes.func.isRequired,
  getAccessTokenCallback: PropTypes.func.isRequired,
  proxyUrl: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  onGoToEServiceClick: PropTypes.func,
};

Profile.defaultProps = {
  getAccessTokenCallback: noop,
  onGoToEServiceClick: noop,
  proxyUrl: "",
  user: "",
};

export default React.memo(Profile);
